@mixin transition {
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
}

@mixin make-my-col-ready($gutter-width) {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  min-height: 1px; // Prevent collapsing
  padding-right: ($gutter-width / 2);
  padding-left: ($gutter-width / 2);
}