#pv-header{
	background-color: transparent;
}

#registrationTable {
	margin-top: 58px;
}

/* ========== HEADER TOP ========== */
.header-top{
	background-image: url('../img/header-top.png');
	height: 200px;
	background-position: center;
	background-size: cover;
}

nav.navbar{
	background-color: #fafdff !important;
	transition: 0.3s;
}



/* ========== HEADER PAGES ========== */
.pages{
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	.content-left{
		h1{
			margin-bottom: 20px;
		}
		img{
			margin-bottom: 20px;
		}
		.h-paragraph{
			display: block;
			max-width: 500px;
			#text{
				display: none;
			}
		}
		.m-paragraph{
			display: none;
			position: relative;
			#m-text {
				display: none;
			}
			a{
				display: inline-block;
				color: $black;
				font-size: 20px;
				margin-left: 5px;
				@include transition;

				i{
					&:hover{
						text-decoration: none;
						color: $blue;
					}
				}
			}
		}
		.content-btn{
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 30px;
			.dr-jack{
				font-style: italic;
			}
		}
	}
	.content-right{
		border-left: 1px solid rgba(0,0,0, .2);

		h1{
			margin-bottom: 20px;
		}
		p{
			margin-bottom: 10px;
		}
		h5{
			font-size: 20px !important;
			font-weight: 600;
			margin-bottom: 30px;
			line-height: 20px;
		}

		.survey{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.survey-heading{
				display: block;
				width: 240px;
				font-size: 24px!important;
			}

			.task-survey{
				ul{
					list-style: none;
					li{
						display: flex;
						align-items: center;
						justify-content: space-between;
						position: relative;
						width: 220px;
						height: 40px;
						line-height: 40px;
						border-radius: 100px;
						-webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.2);
						-moz-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.2);
						box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.2);
						&:not(:last-child){
							margin-bottom: 10px;
						}

						input[type=radio]{
							position: absolute;
							visibility: hidden;
						}
						label{
							display: block;
							position: relative;
							margin-left: 20px;
							height: 40px;
							width: 100%;
							line-height: 50px;
							cursor: pointer;
							z-index: 9;
							-webkit-transition: all 0.25s linear;
							-moz-transition: all 0.25s linear;
							transition: all 0.25s linear;
						}
						&:hover label{
							color: $blue;
						}

						.check{
							display: block;
							position: absolute;
							border: 2px solid #7f8c8d;
							border-radius: 100%;
							height: 20px;
							width: 20px;
							right: 20px;
							-webkit-transition: border 0.25s linear;
							-moz-transition: border 0.25s linear;
							transition: border 0.25s linear;
							&::before{
								display: block;
								position: absolute;
								content: '';
								border-radius: 100%;
								height: 10px;
								width: 10px;
								margin: auto;
								top: 2px;
								left: 2px;
								-webkit-transition: background 0.25s linear;
								-moz-transition: background 0.25s linear;
								transition: background 0.25s linear;
							}
						}
						&:hover .check{
							border: 3px solid $blue;
						}
						input[type=radio]:checked ~ .check {
							border: 3px solid $blue;
						}
						input[type=radio]:checked ~ label {
							color: $blue;
						}
						input[type=radio]:checked ~ .check::before{
							background: $blue;
						}
					}
				}

				.survey-btn{
					outline: none;
					cursor: pointer;
					float: right;
					border: none;
					color: $blue;
					transition: all .3s;
					font-size: 18px;
					margin-top: 10px;
					i{
						font-size: 20px;
						@include transition;
						&:hover{
							margin-left: 5px;
						}
					}
				}
			}
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px){
	.pages{

		.content-left{
			img{
				width: 100%;
			}
			.h-paragraph{
				max-width: 100%;
			}
		}

		.content-right{
		}
	}
}


/* Small Devices, Tablets */
@media only screen and (max-width : 768px){
	.header-top{
		background-image: url('../img/t-head-small.png');
		height: 90px;
		background-position: center;
		background-size: cover;
	}

	.pages{
		.content-left,
		.content-right{
			margin-bottom: 15px;
			margin-top: 15px;
		}

		.content-left{
			h1{
				margin-bottom: 10px;
			}
			img{
				display: none;
			}
			.h-paragraph{
				display: none;
			}
			.m-paragraph{
				display: block;
			}
			.primary-btn{
				display: none;
			}

			.content-btn{
				justify-content: flex-end;
			}
		}

		.content-right{
			.survey-heading{
				width: 100%;
				margin-bottom: 10px;
			}
			.survey{
				align-items: flex-start;
				flex-direction: column;
				.task-survey{
					ul{
						li{
							display: inline-block;
							width: 100%;
							height: 40px;
							label{
								line-height: 40px
							}
							.check{
								line-height: 40px;
								top: 10px;
							}
						}
					}
					.survey-btn{
						display: block;
						float: none;
						margin: 0 auto;
					}
				}
			}
		}
	}
}
@media only screen and (max-width : 575px){
	
	.pages{
		.content-left{
			.content-btn{
				margin-top: 10px;
			}
		}
		.content-right{
			padding: 20px 15px;
			h5{
				font-size: 16px !important;
				font-weight: 600 !important;
				margin-bottom: 20px;
				line-height: 16px;

			}
			.survey-heading{
				font-size: 20px !important;
				font-weight: bold;
				margin-bottom: 20px;
			}
			label{
				font-size: 14px!important;
			}
		}
	}

}
/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px){

}

.navbar-nav{
	.nav-item{
		span,
		a{
			@extend .nav-link,.hover-float,.hover-primary;
		}
		&.active a{
			color: $primary;
		}
	}
}


