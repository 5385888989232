/*
    Created on : 9-Feb-2018, 3:17:52 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
/*
    Created on : 12-Feb-2018, 10:46:40 AM
    Author     : James Hansen <jhansen@publivate.com>

OVERIDE Bootstrap & Publivate variables only here
NOTE: No css goes here
*/
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url(../../../media/alertify.js/themes/alertify.core.css);
@import url(../../../media/alertify.js/themes/alertify.default.css);
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,600i");
@import url(../../../media/jquery/select2/css/select2.css);
:root {
  --blue: #006fCf;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #006fCf;
  --secondary: #EB7D66;
  --success: #4B96AD;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #F4728F;
  --light: #FAFDFF;
  --dark: #707070;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "benton-sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #006fCf;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #004683;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .normal-list, .h5, .h6 {
  margin-bottom: 1.5rem;
  font-family: "benton-sans", sans-serif;
  font-weight: 400;
  line-height: 1; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4, .normal-list {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container, .view-story .pv-three-column,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .view-story .pv-three-column, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .view-story .pv-three-column, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .view-story .pv-three-column, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .view-story .pv-three-column, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1440px) {
  .container, .view-story .pv-three-column, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1380px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters, .mod-challenge-countdown .countdown_row {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col, .mod-challenge-countdown .countdown_row > .col,
  .no-gutters > [class*="col-"],
  .mod-challenge-countdown .countdown_row > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .view-contact body .control-group:not(.field-spacer), .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4, .view-contact body .control-group:not(.field-spacer) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d7f2; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ab4e6; }

.table-hover .table-primary:hover {
  background-color: #a2cbee; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a2cbee; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f9dbd4; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f5bbaf; }

.table-hover .table-secondary:hover {
  background-color: #f6c8be; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f6c8be; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde2e8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a1c8d4; }

.table-hover .table-success:hover {
  background-color: #bcd8e0; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bcd8e0; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fcd8e0; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f9b6c5; }

.table-hover .table-danger:hover {
  background-color: #fac0cd; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fac0cd; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefeff; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfeff; }

.table-hover .table-light:hover {
  background-color: #e5e5ff; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e5e5ff; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #d7d7d7; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #b5b5b5; }

.table-hover .table-dark:hover {
  background-color: #cacaca; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #cacaca; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .story-form2 .editor {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .story-form2 .editor {
      transition: none; } }
  .form-control::-ms-expand, .story-form2 .editor::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring, .story-form2 .editor:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus, .story-form2 .editor:focus {
    color: #495057;
    background-color: #fff;
    border-color: #50aeff;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 111, 207, 0.25); }
  .form-control::placeholder, .story-form2 .editor::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .story-form2 .editor:disabled, .form-control[readonly], .story-form2 .editor[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control, .story-form2 input.editor[type="date"],
input[type="time"].form-control,
.story-form2 input.editor[type="time"],
input[type="datetime-local"].form-control,
.story-form2 input.editor[type="datetime-local"],
input[type="month"].form-control,
.story-form2 input.editor[type="month"] {
  appearance: none; }

select.form-control:focus::-ms-value, .story-form2 select.editor:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], .story-form2 select.editor[size], select.form-control[multiple], .story-form2 select.editor[multiple] {
  height: auto; }

textarea.form-control, .story-form2 textarea.editor {
  height: auto; }

.form-group, .view-contact body .control-group:not(.field-spacer) {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4B96AD; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(75, 150, 173, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .story-form2 .editor:valid, .story-form2 .was-validated .editor:valid, .form-control.is-valid, .story-form2 .is-valid.editor {
  border-color: #4B96AD;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%234B96AD' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .story-form2 .editor:valid:focus, .story-form2 .was-validated .editor:valid:focus, .form-control.is-valid:focus, .story-form2 .is-valid.editor:focus {
    border-color: #4B96AD;
    box-shadow: 0 0 0 0.2rem rgba(75, 150, 173, 0.25); }

.was-validated textarea.form-control:valid, .was-validated .story-form2 textarea.editor:valid, .story-form2 .was-validated textarea.editor:valid, textarea.form-control.is-valid, .story-form2 textarea.is-valid.editor {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #4B96AD;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%234B96AD' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #4B96AD;
    box-shadow: 0 0 0 0.2rem rgba(75, 150, 173, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4B96AD; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4B96AD; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #4B96AD; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #6cacbf;
  background-color: #6cacbf; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(75, 150, 173, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4B96AD; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4B96AD; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #4B96AD;
  box-shadow: 0 0 0 0.2rem rgba(75, 150, 173, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F4728F; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(244, 114, 143, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .story-form2 .editor:invalid, .story-form2 .was-validated .editor:invalid, .form-control.is-invalid, .story-form2 .is-invalid.editor {
  border-color: #F4728F;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F4728F' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F4728F' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .story-form2 .editor:invalid:focus, .story-form2 .was-validated .editor:invalid:focus, .form-control.is-invalid:focus, .story-form2 .is-invalid.editor:focus {
    border-color: #F4728F;
    box-shadow: 0 0 0 0.2rem rgba(244, 114, 143, 0.25); }

.was-validated textarea.form-control:invalid, .was-validated .story-form2 textarea.editor:invalid, .story-form2 .was-validated textarea.editor:invalid, textarea.form-control.is-invalid, .story-form2 textarea.is-invalid.editor {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #F4728F;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F4728F' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F4728F' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #F4728F;
    box-shadow: 0 0 0 0.2rem rgba(244, 114, 143, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F4728F; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #F4728F; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #F4728F; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f8a1b5;
  background-color: #f8a1b5; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(244, 114, 143, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #F4728F; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #F4728F; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #F4728F;
  box-shadow: 0 0 0 0.2rem rgba(244, 114, 143, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group, .form-inline .view-contact body .control-group:not(.field-spacer), .view-contact body .form-inline .control-group:not(.field-spacer) {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .story-form2 .editor, .story-form2 .form-inline .editor {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-family: "benton-sans", sans-serif;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 207, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 111, 207, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #006fCf;
  border-color: #006fCf;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background-color: #005aa9;
    border-color: #00549c; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #005aa9;
    border-color: #00549c;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(38, 133, 214, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #006fCf;
    border-color: #006fCf; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #00549c;
    border-color: #004d8f; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(38, 133, 214, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #EB7D66;
  border-color: #EB7D66;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #fff;
    background-color: #e76044;
    border-color: #e55739; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #e76044;
    border-color: #e55739;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(205, 112, 93, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #EB7D66;
    border-color: #EB7D66; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #e55739;
    border-color: #e44d2e; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(205, 112, 93, 0.5); }

.btn-success {
  color: #fff;
  background-color: #4B96AD;
  border-color: #4B96AD;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #fff;
    background-color: #3f7f92;
    border-color: #3c7789; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #3f7f92;
    border-color: #3c7789;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(102, 166, 185, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #4B96AD;
    border-color: #4B96AD; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3c7789;
    border-color: #386f81; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(102, 166, 185, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #212529;
  background-color: #F4728F;
  border-color: #F4728F;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background-color: #f14f73;
    border-color: #f04369; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #f14f73;
    border-color: #f04369;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(212, 102, 128, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #212529;
    background-color: #F4728F;
    border-color: #F4728F; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f04369;
    border-color: #ef3760; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(212, 102, 128, 0.5); }

.btn-light {
  color: #212529;
  background-color: #FAFDFF;
  border-color: #FAFDFF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #212529;
    background-color: #d4eeff;
    border-color: #c7e9ff; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #d4eeff;
    border-color: #c7e9ff;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(217, 221, 223, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #FAFDFF;
    border-color: #FAFDFF; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c7e9ff;
    border-color: #bae4ff; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(217, 221, 223, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background-color: #5d5d5d;
    border-color: #575757; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #5d5d5d;
    border-color: #575757;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(133, 133, 133, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #707070;
    border-color: #707070; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #575757;
    border-color: #505050; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(133, 133, 133, 0.5); }

.btn-outline-primary {
  color: #006fCf;
  border-color: #006fCf; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #006fCf;
    border-color: #006fCf; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 207, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #006fCf;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #006fCf;
    border-color: #006fCf; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 111, 207, 0.5); }

.btn-outline-secondary {
  color: #EB7D66;
  border-color: #EB7D66; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #EB7D66;
    border-color: #EB7D66; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 125, 102, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #EB7D66;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #EB7D66;
    border-color: #EB7D66; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(235, 125, 102, 0.5); }

.btn-outline-success {
  color: #4B96AD;
  border-color: #4B96AD; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #4B96AD;
    border-color: #4B96AD; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(75, 150, 173, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4B96AD;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4B96AD;
    border-color: #4B96AD; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(75, 150, 173, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #F4728F;
  border-color: #F4728F; }
  .btn-outline-danger:hover {
    color: #212529;
    background-color: #F4728F;
    border-color: #F4728F; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 114, 143, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #F4728F;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #212529;
    background-color: #F4728F;
    border-color: #F4728F; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 114, 143, 0.5); }

.btn-outline-light {
  color: #FAFDFF;
  border-color: #FAFDFF; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #FAFDFF;
    border-color: #FAFDFF; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 253, 255, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FAFDFF;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #FAFDFF;
    border-color: #FAFDFF; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(250, 253, 255, 0.5); }

.btn-outline-dark {
  color: #707070;
  border-color: #707070; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #707070;
    border-color: #707070; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #707070;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #707070;
    border-color: #707070; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(112, 112, 112, 0.5); }

.btn-link {
  font-weight: 400;
  color: #006fCf;
  text-decoration: none; }
  .btn-link:hover {
    color: #004683;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.75rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #006fCf; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .story-form2 .input-group > .editor,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .story-form2 .input-group > .editor + .form-control, .story-form2 .input-group > .form-control + .editor, .story-form2 .input-group > .editor + .editor,
    .input-group > .form-control + .custom-select,
    .story-form2 .input-group > .editor + .custom-select,
    .input-group > .form-control + .custom-file,
    .story-form2 .input-group > .editor + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .story-form2 .input-group > .form-control-plaintext + .editor,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .story-form2 .input-group > .custom-select + .editor,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .story-form2 .input-group > .custom-file + .editor,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .story-form2 .input-group > .editor:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child), .story-form2 .input-group > .editor:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child), .story-form2 .input-group:not(.has-validation) > .editor:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3), .story-form2 .input-group.has-validation > .editor:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .story-form2 .input-group-lg > .editor:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .story-form2 .input-group-lg > .editor,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .story-form2 .input-group-sm > .editor:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control, .story-form2 .input-group-sm > .editor,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #006fCf;
    background-color: #006fCf;
    box-shadow:; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 111, 207, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #50aeff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #83c5ff;
    border-color: #83c5ff;
    box-shadow:; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #006fCf;
  background-color: #006fCf;
  box-shadow:; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 111, 207, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 111, 207, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 111, 207, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 111, 207, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none; }
  .custom-select:focus {
    border-color: #50aeff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 111, 207, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #50aeff;
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 207, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 111, 207, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 111, 207, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 111, 207, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #006fCf;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #83c5ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #006fCf;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #83c5ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #006fCf;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #83c5ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link, .navbar-nav .nav-item span,
.navbar-nav .nav-item a {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .navbar-nav .nav-item span:hover,
  .navbar-nav .nav-item a:hover, .nav-link:focus, .navbar-nav .nav-item span:focus,
  .navbar-nav .nav-item a:focus {
    text-decoration: none; }
  .nav-link.disabled, .navbar-nav .nav-item span.disabled,
  .navbar-nav .nav-item a.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link, .nav-tabs .navbar-nav .nav-item span, .navbar-nav .nav-item .nav-tabs span,
  .nav-tabs .navbar-nav .nav-item a, .navbar-nav .nav-item .nav-tabs a {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .navbar-nav .nav-item span:hover, .navbar-nav .nav-item .nav-tabs span:hover,
    .nav-tabs .navbar-nav .nav-item a:hover, .navbar-nav .nav-item .nav-tabs a:hover, .nav-tabs .nav-link:focus, .nav-tabs .navbar-nav .nav-item span:focus, .navbar-nav .nav-item .nav-tabs span:focus,
    .nav-tabs .navbar-nav .nav-item a:focus, .navbar-nav .nav-item .nav-tabs a:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled, .nav-tabs .navbar-nav .nav-item span.disabled, .navbar-nav .nav-item .nav-tabs span.disabled,
    .nav-tabs .navbar-nav .nav-item a.disabled, .navbar-nav .nav-item .nav-tabs a.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active, .nav-tabs .navbar-nav .nav-item span.active, .navbar-nav .nav-item .nav-tabs span.active,
  .nav-tabs .navbar-nav .nav-item a.active, .navbar-nav .nav-item .nav-tabs a.active,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .navbar-nav .nav-item.show span,
  .navbar-nav .nav-tabs .nav-item.show span,
  .nav-tabs .navbar-nav .nav-item.show a,
  .navbar-nav .nav-tabs .nav-item.show a {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link, .nav-pills .navbar-nav .nav-item span, .navbar-nav .nav-item .nav-pills span,
.nav-pills .navbar-nav .nav-item a, .navbar-nav .nav-item .nav-pills a {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active, .nav-pills .navbar-nav .nav-item span.active, .navbar-nav .nav-item .nav-pills span.active,
.nav-pills .navbar-nav .nav-item a.active, .navbar-nav .nav-item .nav-pills a.active,
.nav-pills .show > .nav-link,
.nav-pills .navbar-nav .nav-item .show > span,
.navbar-nav .nav-item .nav-pills .show > span,
.nav-pills .navbar-nav .nav-item .show > a,
.navbar-nav .nav-item .nav-pills .show > a {
  color: #fff;
  background-color: #006fCf; }

.nav-fill > .nav-link, .navbar-nav .nav-item .nav-fill > span,
.navbar-nav .nav-item .nav-fill > a,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link, .navbar-nav .nav-item .nav-justified > span,
.navbar-nav .nav-item .nav-justified > a,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container, .navbar .view-story .pv-three-column, .view-story .navbar .pv-three-column,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link, .navbar-nav .nav-item span,
  .navbar-nav .nav-item a {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .view-story .navbar-expand-sm > .pv-three-column,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link, .navbar-expand-sm .navbar-nav .nav-item span,
      .navbar-expand-sm .navbar-nav .nav-item a {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-sm > .container, .view-story .navbar-expand-sm > .pv-three-column,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .view-story .navbar-expand-md > .pv-three-column,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link, .navbar-expand-md .navbar-nav .nav-item span,
      .navbar-expand-md .navbar-nav .nav-item a {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-md > .container, .view-story .navbar-expand-md > .pv-three-column,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .view-story .navbar-expand-lg > .pv-three-column,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link, .navbar-expand-lg .navbar-nav .nav-item span,
      .navbar-expand-lg .navbar-nav .nav-item a {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-lg > .container, .view-story .navbar-expand-lg > .pv-three-column,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .view-story .navbar-expand-xl > .pv-three-column,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link, .navbar-expand-xl .navbar-nav .nav-item span,
      .navbar-expand-xl .navbar-nav .nav-item a {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-xl > .container, .view-story .navbar-expand-xl > .pv-three-column,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xxl > .container, .view-story .navbar-expand-xxl > .pv-three-column,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link, .navbar-expand-xxl .navbar-nav .nav-item span,
      .navbar-expand-xxl .navbar-nav .nav-item a {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-xxl > .container, .view-story .navbar-expand-xxl > .pv-three-column,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container, .view-story .navbar-expand > .pv-three-column,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link, .navbar-expand .navbar-nav .nav-item span,
    .navbar-expand .navbar-nav .nav-item a {
      padding-right: 1rem;
      padding-left: 1rem; }
  .navbar-expand > .container, .view-story .navbar-expand > .pv-three-column,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-item span,
.navbar-light .navbar-nav .nav-item a {
  color: #006fCf; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-item span:hover,
  .navbar-light .navbar-nav .nav-item a:hover, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-item span:focus,
  .navbar-light .navbar-nav .nav-item a:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled, .navbar-light .navbar-nav .nav-item span.disabled,
  .navbar-light .navbar-nav .nav-item a.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-item .show > span,
.navbar-light .navbar-nav .nav-item .show > a,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-item .active > span,
.navbar-light .navbar-nav .nav-item .active > a,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-item span.show,
.navbar-light .navbar-nav .nav-item a.show,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-item span.active,
.navbar-light .navbar-nav .nav-item a.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: #006fCf;
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23006fCf' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #006fCf; }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-nav .nav-item span,
.navbar-dark .navbar-nav .nav-item a {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-item span:hover,
  .navbar-dark .navbar-nav .nav-item a:hover, .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-item span:focus,
  .navbar-dark .navbar-nav .nav-item a:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled, .navbar-dark .navbar-nav .nav-item span.disabled,
  .navbar-dark .navbar-nav .nav-item a.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .nav-item .show > span,
.navbar-dark .navbar-nav .nav-item .show > a,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-item .active > span,
.navbar-dark .navbar-nav .nav-item .active > a,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-item span.show,
.navbar-dark .navbar-nav .nav-item a.show,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-item span.active,
.navbar-dark .navbar-nav .nav-item a.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top,
.pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top, .pv-stories-wrapper .section-card #my-stories .row .inner .card-group > .card:not(:last-child) a.vimeo-popup img,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top, .pv-stories-wrapper .section-card #my-stories .row .inner .card-group > .card:not(:first-child) a.vimeo-popup img,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #006fCf;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #004683;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 207, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #006fCf;
  border-color: #006fCf; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #006fCf; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #00549c; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 111, 207, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #EB7D66; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #e55739; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(235, 125, 102, 0.5); }

.badge-success {
  color: #fff;
  background-color: #4B96AD; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #3c7789; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(75, 150, 173, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #212529;
  background-color: #F4728F; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #212529;
    background-color: #f04369; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 114, 143, 0.5); }

.badge-light {
  color: #212529;
  background-color: #FAFDFF; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #c7e9ff; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 253, 255, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #707070; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #575757; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #003a6c;
  background-color: #cce2f5;
  border-color: #b8d7f2; }
  .alert-primary hr {
    border-top-color: #a2cbee; }
  .alert-primary .alert-link {
    color: #001f39; }

.alert-secondary {
  color: #7a4135;
  background-color: #fbe5e0;
  border-color: #f9dbd4; }
  .alert-secondary hr {
    border-top-color: #f6c8be; }
  .alert-secondary .alert-link {
    color: #562e26; }

.alert-success {
  color: #274e5a;
  background-color: #dbeaef;
  border-color: #cde2e8; }
  .alert-success hr {
    border-top-color: #bcd8e0; }
  .alert-success .alert-link {
    color: #182f36; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #7f3b4a;
  background-color: #fde3e9;
  border-color: #fcd8e0; }
  .alert-danger hr {
    border-top-color: #fac0cd; }
  .alert-danger .alert-link {
    color: #5c2b36; }

.alert-light {
  color: #828485;
  background-color: #feffff;
  border-color: #fefeff; }
  .alert-light hr {
    border-top-color: #e5e5ff; }
  .alert-light .alert-link {
    color: #696a6b; }

.alert-dark {
  color: #3a3a3a;
  background-color: #e2e2e2;
  border-color: #d7d7d7; }
  .alert-dark hr {
    border-top-color: #cacaca; }
  .alert-dark .alert-link {
    color: #212121; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #006fCf;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #006fCf;
    border-color: #006fCf; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #003a6c;
  background-color: #b8d7f2; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #003a6c;
    background-color: #a2cbee; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #003a6c;
    border-color: #003a6c; }

.list-group-item-secondary {
  color: #7a4135;
  background-color: #f9dbd4; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7a4135;
    background-color: #f6c8be; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7a4135;
    border-color: #7a4135; }

.list-group-item-success {
  color: #274e5a;
  background-color: #cde2e8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #274e5a;
    background-color: #bcd8e0; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #274e5a;
    border-color: #274e5a; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #7f3b4a;
  background-color: #fcd8e0; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f3b4a;
    background-color: #fac0cd; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f3b4a;
    border-color: #7f3b4a; }

.list-group-item-light {
  color: #828485;
  background-color: #fefeff; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #828485;
    background-color: #e5e5ff; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #828485;
    border-color: #828485; }

.list-group-item-dark {
  color: #3a3a3a;
  background-color: #d7d7d7; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #3a3a3a;
    background-color: #cacaca; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #3a3a3a;
    border-color: #3a3a3a; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "benton-sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "benton-sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle, .countdown_row .countdown_amount {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #006fCf !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #00549c !important; }

.bg-secondary {
  background-color: #EB7D66 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e55739 !important; }

.bg-success {
  background-color: #4B96AD !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3c7789 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #F4728F !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f04369 !important; }

.bg-light {
  background-color: #FAFDFF !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c7e9ff !important; }

.bg-dark {
  background-color: #707070 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #575757 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #006fCf !important; }

.border-secondary {
  border-color: #EB7D66 !important; }

.border-success {
  border-color: #4B96AD !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #F4728F !important; }

.border-light {
  border-color: #FAFDFF !important; }

.border-dark {
  border-color: #707070 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after, .panel-body::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1,
.story-tags,
.story .card-title small {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1,
.pv-stories-page .sort-by-links a.sort-by {
  margin-right: 0.25rem !important; }

.mb-1, .story header,
.my-1,
.story-tags,
.story .card-title small {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1,
.pv-stories-page .sort-by-links a.sort-by {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2,
form .missing-fields,
.story .row {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2, .story-form2,
.my-2,
form .missing-fields,
.story .row {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3, .pv-stories-page #pv-stories,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3, .story-form2 .row, .story-form2 .story-media-col, .moduletable-rnd,
.module,
.moduletable,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3, .pagination-toolbar .pagination,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0, .no-padding {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2,
.moduletable-rnd,
.module,
.moduletable {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2,
.moduletable-rnd,
.module,
.moduletable {
  padding-left: 0.5rem !important; }

.p-3, .pv-stories-page #add-story-form-btn, .component.window body {
  padding: 1rem !important; }

.pt-3, .skip-navigation,
.py-3 {
  padding-top: 1rem !important; }

.pr-3, #my-tools .angular-view-container,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4,
.moduletable-rnd,
.module,
.moduletable {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4,
.moduletable-rnd,
.module,
.moduletable {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #006fCf !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #004683 !important; }

.text-secondary {
  color: #EB7D66 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #e24422 !important; }

.text-success {
  color: #4B96AD !important; }

a.text-success:hover, a.text-success:focus {
  color: #346878 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #F4728F !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ee2b57 !important; }

.text-light, .view-contact body {
  color: #FAFDFF !important; }

a.text-light:hover, a.text-light:focus {
  color: #aedeff !important; }

.text-dark {
  color: #707070 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #4a4a4a !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container, .view-story .pv-three-column {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*
    Created on : 12-Feb-2018, 10:49:26 AM
    Author     : James Hansen <jhansen@publivate.com>
*/
/*

 Fade element bottom, add an element to the bottom of the parent element ie: <div>sdfsdfs<div class="fade-bottom"></div></div>

 */
.fade-bottom {
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
  bottom: 0;
  left: 0;
  height: 1rem;
  text-align: center;
  margin: 0;
  padding: 30px 0;
  position: absolute;
  width: 100%; }

.hover-float, .navbar-nav .nav-item span,
.navbar-nav .nav-item a {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.2s !important;
  transition-property: all !important;
  transition-timing-function: ease-out; }

.hover-float:hover, .navbar-nav .nav-item span:hover,
.navbar-nav .nav-item a:hover, .hover-float:focus, .navbar-nav .nav-item span:focus,
.navbar-nav .nav-item a:focus, .hover-float:active, .navbar-nav .nav-item span:active,
.navbar-nav .nav-item a:active {
  transform: translateY(-2px); }

.hover-primary:hover, .navbar-nav .nav-item span:hover,
.navbar-nav .nav-item a:hover, .hover-primary:focus, .navbar-nav .nav-item span:focus,
.navbar-nav .nav-item a:focus, .hover-primary:active, .navbar-nav .nav-item span:active,
.navbar-nav .nav-item a:active {
  color: #006fCf !important; }

.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.bottom-100 {
  bottom: 100%; }

.opacity0 {
  opacity: 0 !important; }

.opacity20 {
  opacity: 0.2; }

.opacity1 {
  opacity: 1 !important; }

.to-top {
  position: fixed;
  bottom: 2rem;
  right: 0;
  background-color: black;
  opacity: 0.5;
  padding: 1.5rem 2rem; }
  .to-top a span {
    color: white; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  background-color: #FAFDFF !important;
  font-size: 1rem;
  font-weight: 400;
  color: #000 !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .normal-list, .h5, .h6 {
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.5; }

h1, .h1 {
  font-size: 2.5rem;
  letter-spacing: 0.1rem; }
  @media (max-width: 766px) {
    h1.site-title, .h1.site-title {
      font-size: 1.9rem; } }

h2, .h2 {
  font-size: 2rem;
  letter-spacing: 0.05rem; }

h3, .h3 {
  font-size: 1.75rem;
  font-weight: 300 !important;
  letter-spacing: 0.05rem; }

h4, .h4, .normal-list {
  font-size: 1.5rem;
  font-weight: 300 !important;
  letter-spacing: 0.05rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child {
  margin-bottom: 0; }

@media (max-width: 575.98px) {
  h1, .h1 {
    font-size: 2.5rem; }
  h2, .h2 {
    font-size: 2rem; }
  h3, .h3 {
    font-size: 1.5rem; }
  h4, .h4, .normal-list {
    font-size: 1rem; } }

a {
  color: #006fCf;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    text-decoration: none !important; }
  a.action {
    font-size: 1.4em; }

img {
  object-fit: cover; }

.text-success {
  color: #4B96AD !important; }

.text-dark {
  color: #707070 !important; }

.text-secondary {
  color: #EB7D66 !important; }

.text-light, .view-contact body {
  color: #FAFDFF !important; }

.text-primary {
  color: #006fCf !important; }

a.text-light:hover {
  color: #FAFDFF !important; }

.badge-secondary {
  color: #707070;
  background-color: #EB7D66; }

.hub-text {
  margin: 2rem 0; }

.mce-container p, .mce-container label {
  font-size: 16px; }

.video-container .play-btn {
  position: absolute;
  height: 80px;
  top: 40%;
  margin-top: -40px;
  left: 50%;
  margin-left: -40px;
  cursor: pointer;
  opacity: 0.5; }
  .video-container .play-btn:hover {
    opacity: 0.8; }

.no-box-shadow {
  box-shadow: none !important; }

.text-black {
  color: black; }

#pv-content .container-fluid:first-of-type, #pv-content .container-sm:first-of-type, #pv-content .container-md:first-of-type, #pv-content .container-lg:first-of-type, #pv-content .container-xl:first-of-type, #pv-content .container-xxl:first-of-type {
  margin-top: 5rem; }

#pv-content .container, #pv-content .view-story .pv-three-column, .view-story #pv-content .pv-three-column {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.view-login #pv-content {
  margin-top: 5rem; }

.btn-primary {
  border-color: #006fCf;
  background-color: #006fCf;
  color: #fff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #006fCf;
    border-color: #006fCf; }

.btn-outline-primary {
  color: #006fCf;
  border-color: #006fCf; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #006fCf;
    border-color: #006fCf; }

.btn-outline-light:hover {
  border-color: #53CEFC;
  color: #53CEFC !important; }

.btn-lg, .btn-group-lg > .btn {
  font-size: 1.75rem !important;
  font-weight: 300 !important; }
  .btn-lg.action, .btn-group-lg > .action.btn {
    height: 2em;
    line-height: 1em; }

.btn-danger {
  background-color: #F4728F;
  border-color: #F4728F; }
  .btn-danger:hover {
    background-color: #f04369;
    border-color: #f04369;
    color: #fff !important; }

.btn-success {
  background-color: #4B96AD !important;
  border-color: #4B96AD !important; }

.btn-block {
  width: 100%; }

.btn-secondary {
  background-color: #EB7D66;
  border-color: #EB7D66; }
  .btn-secondary:hover {
    background-color: #e76044;
    border-color: #e76044; }

.btn-link, .btn-link:focus {
  color: #006fCf; }

.navbar .nav-header,
.navbar .nav-link,
.navbar .navbar-nav .nav-item span,
.navbar-nav .nav-item .navbar span,
.navbar .navbar-nav .nav-item a,
.navbar-nav .nav-item .navbar a {
  font-size: 1rem;
  font-weight: 300; }

.h-32px {
  height: 32px; }

.h-48px {
  height: 48px; }

.h-60px {
  height: 60px; }

.h-64px {
  height: 64px; }

.h-128px {
  height: 128px; }

.h-256px {
  height: 256px; }

.h-500px {
  height: 500px; }

.h-700px {
  height: 700px; }

@media (min-width: 767px) {
  .h-md-100, .idea-like .special-idea card {
    height: 100%; } }

.w-128px {
  width: 128px; }

.card-header p {
  margin-bottom: 0; }

.pt-8 {
  padding-top: 8rem !important; }

.mb-down {
  margin-bottom: 2rem !important; }

.max-vh-100 {
  max-height: 100vh; }

.pv-container-header h3 {
  line-height: 1.5em !important; }

.pv-pagination .paging a,
.pv-pagination .paging span {
  padding: 0.8rem; }

.pv-collab .no-rows {
  margin-bottom: 1rem; }

@media (max-width: 767px) {
  #pv-content .container-fluid:first-of-type, #pv-content .container-sm:first-of-type, #pv-content .container-md:first-of-type, #pv-content .container-lg:first-of-type, #pv-content .container-xl:first-of-type, #pv-content .container-xxl:first-of-type {
    margin-top: 0 !important; } }

.hidden-mod {
  display: none; }

.normal-list {
  margin-left: 1em; }

.normal-list li {
  margin-bottom: 0.8em; }

.bg-temp-img-1 {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/images/patient-hub/placeholder1.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed; }

.bg-temp-img-2 {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/images/patient-hub/placeholder7.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed; }

/*
    Created on : 16-Apr-2018, 3:07:22 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
.pagination-toolbar {
  display: flex; }
  .pagination-toolbar .limit {
    padding: 0.5rem 0.75rem; }

form .missing-fields {
  display: none; }

form.invalid-form .missing-fields {
  display: block; }

form .placeholder {
  color: #777; }

form .form-group .select2-container--default .select2-selection--multiple .select2-selection__choice, form .view-contact body .control-group:not(.field-spacer) .select2-container--default .select2-selection--multiple .select2-selection__choice, .view-contact body form .control-group:not(.field-spacer) .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #00529c;
  color: #fff;
  font-size: 13px !important;
  padding: 5px 15px 5px 10px;
  margin-right: 10px; }

form .form-group .select2-container--default .select2-selection--multiple .select2-selection__choice__remove, form .view-contact body .control-group:not(.field-spacer) .select2-container--default .select2-selection--multiple .select2-selection__choice__remove, .view-contact body form .control-group:not(.field-spacer) .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
  margin-right: 5px; }

form .form-group .select2-container--default .select2-selection--multiple .select2-search__field, form .view-contact body .control-group:not(.field-spacer) .select2-container--default .select2-selection--multiple .select2-search__field, .view-contact body form .control-group:not(.field-spacer) .select2-container--default .select2-selection--multiple .select2-search__field {
  border: none !important;
  color: #707070 !important;
  margin-top: 0px; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 16-Feb-2018, 2:34:57 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
/*
	Polyfily for older Bootstrap 3 styles
    Created on : 16-Feb-2018, 12:53:03 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
/*
	Bootstrap 3 variables
    Created on : 16-Feb-2018, 1:28:32 PM
    Author     : James Hansen <jhansen$publivate.com>
*/
.panel {
  margin-bottom: 1.5;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.panel-body {
  padding: 0.9375rem; }

.panel-heading {
  padding: 0.625rem 0.9375rem;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .panel-heading > .dropdown .dropdown-toggle {
    color: inherit; }

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2rem;
  color: inherit; }
  .panel-title > a,
  .panel-title > small,
  .panel-title > .small,
  .panel-title > small > a,
  .panel-title > .small > a {
    color: inherit; }

.panel-footer {
  padding: 0.625rem 0.9375rem;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0; }
  .panel > .list-group .list-group-item,
  .panel > .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0; }
  .panel > .list-group:first-child .list-group-item:first-child,
  .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .panel > .list-group:last-child .list-group-item:last-child,
  .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0; }

.list-group + .panel-footer {
  border-top-width: 0; }

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0; }
  .panel > .table caption,
  .panel > .table-responsive > .table caption,
  .panel > .panel-collapse > .table caption {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .panel > .table:first-child > thead:first-child > tr:first-child,
  .panel > .table:first-child > tbody:first-child > tr:first-child,
  .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
  .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
    border-top-left-radius: -0.75rem;
    border-top-right-radius: -0.75rem; }
    .panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
    .panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
      border-top-left-radius: -0.75rem; }
    .panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
    .panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
      border-top-right-radius: -0.75rem; }

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .panel > .table:last-child > tbody:last-child > tr:last-child,
  .panel > .table:last-child > tfoot:last-child > tr:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
    border-bottom-left-radius: -0.75rem;
    border-bottom-right-radius: -0.75rem; }
    .panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
    .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
      border-bottom-left-radius: -0.75rem; }
    .panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
    .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
      border-bottom-right-radius: -0.75rem; }

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #dee2e6; }

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0; }

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0; }
  .panel > .table-bordered > thead > tr > th:first-child,
  .panel > .table-bordered > thead > tr > td:first-child,
  .panel > .table-bordered > tbody > tr > th:first-child,
  .panel > .table-bordered > tbody > tr > td:first-child,
  .panel > .table-bordered > tfoot > tr > th:first-child,
  .panel > .table-bordered > tfoot > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .panel > .table-bordered > thead > tr > th:last-child,
  .panel > .table-bordered > thead > tr > td:last-child,
  .panel > .table-bordered > tbody > tr > th:last-child,
  .panel > .table-bordered > tbody > tr > td:last-child,
  .panel > .table-bordered > tfoot > tr > th:last-child,
  .panel > .table-bordered > tfoot > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .panel > .table-bordered > thead > tr:first-child > td,
  .panel > .table-bordered > thead > tr:first-child > th,
  .panel > .table-bordered > tbody > tr:first-child > td,
  .panel > .table-bordered > tbody > tr:first-child > th,
  .panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
  .panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
  .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
  .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
    border-bottom: 0; }
  .panel > .table-bordered > tbody > tr:last-child > td,
  .panel > .table-bordered > tbody > tr:last-child > th,
  .panel > .table-bordered > tfoot > tr:last-child > td,
  .panel > .table-bordered > tfoot > tr:last-child > th,
  .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
  .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0; }

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0; }

.panel-group {
  margin-bottom: 1.5; }
  .panel-group .panel {
    margin-bottom: 0;
    border-radius: 0.25rem; }
    .panel-group .panel + .panel {
      margin-top: 5px; }
  .panel-group .panel-heading {
    border-bottom: 0; }
    .panel-group .panel-heading + .panel-collapse > .panel-body,
    .panel-group .panel-heading + .panel-collapse > .list-group {
      border-top: 1px solid #ddd; }
  .panel-group .panel-footer {
    border-top: 0; }
    .panel-group .panel-footer + .panel-collapse .panel-body {
      border-bottom: 1px solid #ddd; }

.panel-default {
  border-color: #ddd; }
  .panel-default > .panel-heading {
    color: #707070;
    background-color: #f5f5f5;
    border-color: #ddd; }
    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #ddd; }
    .panel-default > .panel-heading .badge {
      color: #f5f5f5;
      background-color: #707070; }
  .panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ddd; }

.panel-primary {
  border-color: #006fCf; }
  .panel-primary > .panel-heading {
    color: #fff;
    background-color: #006fCf;
    border-color: #006fCf; }
    .panel-primary > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #006fCf; }
    .panel-primary > .panel-heading .badge {
      color: #006fCf;
      background-color: #fff; }
  .panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #006fCf; }

.panel-success {
  border-color: #707070; }
  .panel-success > .panel-heading {
    color: #4B96AD;
    background-color: #4B96AD;
    border-color: #707070; }
    .panel-success > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #707070; }
    .panel-success > .panel-heading .badge {
      color: #4B96AD;
      background-color: #4B96AD; }
  .panel-success > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #707070; }

.panel-info {
  border-color: #707070; }
  .panel-info > .panel-heading {
    color: #17a2b8;
    background-color: #fff;
    border-color: #707070; }
    .panel-info > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #707070; }
    .panel-info > .panel-heading .badge {
      color: #fff;
      background-color: #17a2b8; }
  .panel-info > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #707070; }

.panel-warning {
  border-color: #707070; }
  .panel-warning > .panel-heading {
    color: #ffc107;
    background-color: #fff;
    border-color: #707070; }
    .panel-warning > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #707070; }
    .panel-warning > .panel-heading .badge {
      color: #fff;
      background-color: #ffc107; }
  .panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #707070; }

.panel-danger {
  border-color: #707070; }
  .panel-danger > .panel-heading {
    color: #ffc107;
    background-color: #fff;
    border-color: #707070; }
    .panel-danger > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #707070; }
    .panel-danger > .panel-heading .badge {
      color: #fff;
      background-color: #ffc107; }
  .panel-danger > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #707070; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 20-Feb-2018, 9:31:24 AM
    Author     : James Hansen <jhansen@publivate.com>
*/
.hide {
  display: none !important; }

/*
    Created on : 22-Feb-2018, 3:10:36 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
@media (max-width: 767.98px) {
  .pv-help {
    width: 100%; }
    .pv-help a {
      float: right; }
    .pv-help .pv-help-inner {
      font-size: 85%; } }

.pv-help.pv-help-wide .pv-help-inner {
  width: 600px; }
  @media (max-width: 767.98px) {
    .pv-help.pv-help-wide .pv-help-inner {
      min-width: 100%;
      width: 100% !important; } }

/** HELP **/
.pv-help {
  position: relative; }

.pv-help.inline {
  display: inline-block;
  padding: 0 1em; }

.no-touch .pv-help a:focus .pv-help-inner,
.no-touch .pv-help a:hover .pv-help-inner {
  display: block;
  width: inherit; }

.pv-help-inner {
  background: none repeat scroll 0 0 black;
  /* Fallback for IE8 */
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0 0 8px #888888;
  color: #FFFFFF;
  display: none;
  min-width: 320px;
  opacity: 1;
  padding: 10px !important;
  position: absolute;
  text-align: left;
  text-shadow: 0 0px 1px #ffffff;
  width: inherit;
  z-index: 20; }

.pv-help-inner * {
  color: #EEE; }

.pv-help-inner ul {
  margin: 0;
  padding: 0 0 0 1em; }

/* PLACE OTHER HELP ICON CONTENT POSITIONS HERE */
/** For Help Icons on the right side of the page **/
.pv-help.pull-right,
.pv-help.pv-help-right {
  float: right; }

.pv-help.pull-right .pv-help-inner,
.pv-help.pv-help-right .pv-help-inner {
  right: 0; }

/** For Help Icons stacked left **/
.pv-help.pv-help-left {
  float: left; }

label .pv-help {
  display: inline-block; }

/*
    Created on : 20-Feb-2018, 1:49:36 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
.countdown_row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  .countdown_row .countdown_section {
    margin-left: 0;
    margin-right: 0;
    padding: 0 0.25rem;
    font-size: 85%;
    font-family: "benton-sans", sans-serif;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    width: 33%; }
  .countdown_row .countdown_amount {
    border: 2px;
    display: inline-block;
    font-size: 200% !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #006fCf;
    font-family: "benton-sans", sans-serif;
    width: 100%; }
    @media (max-width: 767.98px) {
      .countdown_row .countdown_amount {
        border-width: 1px; } }

.contributions-page .contributions .ui-icon {
  display: inline-block; }

.contributions-page .contributions-content {
  display: none;
  padding-left: 20px; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 1-May-2018, 10:45:30 AM
    Author     : James Hansen <jhansen@publivate.com>
*/
.scoreboard-row {
  border-bottom: 2px solid #EEE;
  height: 40px;
  line-height: 38px;
  width: 100%; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 24-Apr-2018, 1:45:06 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
#idea-saved-success #share-icons ul {
  display: inline-block;
  padding-left: 5px; }

#idea-saved-success #share-icons ul li {
  display: inline-block; }

#idea-saved-success #idea-saved-success {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 10px 0; }

#idea-saved-success span {
  font-size: 8em; }

#idea-saved-success #share-icons a::before {
  content: " ";
  display: inline-block;
  height: 32px;
  margin-right: .6em;
  vertical-align: middle;
  width: 32px;
  background: url(/media/wet-boew/assets/sprites_share.png) no-repeat; }

#idea-saved-success #share-icons a.facebook::before {
  background-position: 0 -160px !important; }

#idea-saved-success #share-icons a.twitter::before {
  background-position: 0 -480px !important; }

#idea-saved-success #share-icons a.linkedin::before {
  background-position: 0 -288px !important; }

#idea-saved-success #share-icons a.email::before {
  content: "\2709";
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-size: 32px;
  margin-right: .3em;
  background: none;
  margin-top: -12px; }

#idea-saved-success #success-msg {
  padding: 20px 0px;
  font-weight: 700;
  font-size: 20px; }

#idea-saved-success #share-icons {
  font-weight: 600;
  padding: 20px 0; }

#idea-saved-success .fa-check-circle {
  color: #43AF5D; }

#idea-saved-success .close-pupup {
  float: right;
  padding-right: 20px; }

#idea-saved-success .close-pupup button.idea-success-msg-close-btn {
  border: 1px solid #B9B9B9;
  border-radius: 5px;
  background: #FFFFFF;
  padding: 2px 10px;
  font-size: 15px; }

#idea-saved-success #share-icons a {
  text-decoration: none !important; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 10-Apr-2018, 3:50:23 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
#story-form {
  margin-top: 50px; }
  #story-form .form-group label, #story-form .view-contact body .control-group:not(.field-spacer) label, .view-contact body #story-form .control-group:not(.field-spacer) label {
    color: #414141;
    font-size: 20px;
    line-height: 20px !important; }
    #story-form .form-group label span, #story-form .view-contact body .control-group:not(.field-spacer) label span, .view-contact body #story-form .control-group:not(.field-spacer) label span {
      color: #1592e6; }
      #story-form .form-group label span.filepond--label-action, #story-form .view-contact body .control-group:not(.field-spacer) label span.filepond--label-action, .view-contact body #story-form .control-group:not(.field-spacer) label span.filepond--label-action {
        color: #4169a4; }
  #story-form .form-group custom-file, #story-form .view-contact body .control-group:not(.field-spacer) custom-file, .view-contact body #story-form .control-group:not(.field-spacer) custom-file,
  #story-form .form-group input:not(.form-check-input),
  #story-form .view-contact body .control-group:not(.field-spacer) input:not(.form-check-input),
  .view-contact body #story-form .control-group:not(.field-spacer) input:not(.form-check-input) {
    border: 1px solid #808080;
    border-radius: 3px;
    color: #808080;
    font-weight: 400 !important;
    height: 50px;
    width: 100%;
    font-size: 20px; }
    #story-form .form-group custom-file::-webkit-input-placeholder, #story-form .view-contact body .control-group:not(.field-spacer) custom-file::-webkit-input-placeholder, .view-contact body #story-form .control-group:not(.field-spacer) custom-file::-webkit-input-placeholder,
    #story-form .form-group input:not(.form-check-input)::-webkit-input-placeholder,
    #story-form .view-contact body .control-group:not(.field-spacer) input:not(.form-check-input)::-webkit-input-placeholder,
    .view-contact body #story-form .control-group:not(.field-spacer) input:not(.form-check-input)::-webkit-input-placeholder {
      color: #808080; }
    #story-form .form-group custom-file:-ms-input-placeholder, #story-form .view-contact body .control-group:not(.field-spacer) custom-file:-ms-input-placeholder, .view-contact body #story-form .control-group:not(.field-spacer) custom-file:-ms-input-placeholder,
    #story-form .form-group input:not(.form-check-input):-ms-input-placeholder,
    #story-form .view-contact body .control-group:not(.field-spacer) input:not(.form-check-input):-ms-input-placeholder,
    .view-contact body #story-form .control-group:not(.field-spacer) input:not(.form-check-input):-ms-input-placeholder {
      color: #808080; }
    #story-form .form-group custom-file::-ms-input-placeholder, #story-form .view-contact body .control-group:not(.field-spacer) custom-file::-ms-input-placeholder, .view-contact body #story-form .control-group:not(.field-spacer) custom-file::-ms-input-placeholder,
    #story-form .form-group input:not(.form-check-input)::-ms-input-placeholder,
    #story-form .view-contact body .control-group:not(.field-spacer) input:not(.form-check-input)::-ms-input-placeholder,
    .view-contact body #story-form .control-group:not(.field-spacer) input:not(.form-check-input)::-ms-input-placeholder {
      color: #808080; }
    #story-form .form-group custom-file::placeholder, #story-form .view-contact body .control-group:not(.field-spacer) custom-file::placeholder, .view-contact body #story-form .control-group:not(.field-spacer) custom-file::placeholder,
    #story-form .form-group input:not(.form-check-input)::placeholder,
    #story-form .view-contact body .control-group:not(.field-spacer) input:not(.form-check-input)::placeholder,
    .view-contact body #story-form .control-group:not(.field-spacer) input:not(.form-check-input)::placeholder {
      color: #808080; }
  #story-form .form-group.form-check input, #story-form .view-contact body .form-check.control-group:not(.field-spacer) input, .view-contact body #story-form .form-check.control-group:not(.field-spacer) input {
    width: 1.2rem;
    height: 1.2rem; }
  #story-form .form-group.form-check label, #story-form .view-contact body .form-check.control-group:not(.field-spacer) label, .view-contact body #story-form .form-check.control-group:not(.field-spacer) label {
    padding: 5px 10px; }
  #story-form .form-group textarea, #story-form .view-contact body .control-group:not(.field-spacer) textarea, .view-contact body #story-form .control-group:not(.field-spacer) textarea {
    border: 1px solid #808080;
    border-radius: 3px; }
  #story-form .custom-file,
  #story-form .custom-select {
    height: 50px !important;
    color: #808080 !important;
    border: 1px solid #808080;
    border-radius: 3px !important;
    font-size: 20px; }
  #story-form .input-group {
    height: 50px !important;
    line-height: 50px; }
  #story-form .file-preview {
    border-radius: 3px;
    border: 1px solid #808080;
    padding: 5px;
    width: 100%;
    height: 100% !important;
    margin-bottom: 10px; }
  #story-form .file-drop-zone {
    margin: 0 !important;
    border: 1px dashed #808080 !important; }
  #story-form .file-drop-zone-title {
    color: #808080 !important;
    font-size: 24px;
    line-height: 190px;
    cursor: default; }
  #story-form .form-control, #story-form .story-form2 .editor, .story-form2 #story-form .editor {
    display: block;
    width: 100%;
    font-size: 1rem;
    line-height: 50px;
    color: #808080;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #808080;
    border-radius: 3px !important; }
    #story-form .form-control.file-caption, #story-form .story-form2 .file-caption.editor, .story-form2 #story-form .file-caption.editor {
      width: auto;
      height: 50px; }
      #story-form .form-control.file-caption .file-caption-name, #story-form .story-form2 .file-caption.editor .file-caption-name, .story-form2 #story-form .file-caption.editor .file-caption-name {
        width: 100%;
        margin: 0;
        padding: 0;
        box-shadow: none;
        border: none;
        background: none;
        outline: none; }
  #story-form .btn-file input[type=file] {
    background-color: #1592e6 !important; }
  #story-form .btn {
    padding: 0 20px !important;
    cursor: pointer !important; }
  #story-form .btn.btn-primary.btn-file {
    background-color: #1592e6 !important;
    line-height: 50px;
    cursor: pointer !important; }
    #story-form .btn.btn-primary.btn-file .hidden-xs {
      padding: 0;
      margin: 0;
      line-height: 50px;
      cursor: pointer !important; }
  #story-form .fileinput-upload-button {
    display: none !important; }
  #story-form .fileinput-remove-button {
    background-color: red !important; }
  #story-form .button-group {
    margin-top: 30px;
    display: block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    #story-form .button-group.text-center > .primary-button {
      display: inline-block; }
    #story-form .button-group .primary-button {
      background-color: #4169a4;
      border: none;
      border-radius: 3px;
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
      color: #fff;
      cursor: pointer;
      font-weight: 600;
      line-height: 60px;
      height: 60px;
      outline: none;
      transition: all 0.2s ease;
      text-align: center;
      text-decoration: none;
      width: 270px;
      -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease; }
      #story-form .button-group .primary-button:focus {
        outline: none; }
      #story-form .button-group .primary-button:hover {
        opacity: 0.8; }
      #story-form .button-group .primary-button:disabled {
        opacity: 0.7; }
    #story-form .button-group .save-as-draft {
      border: none;
      cursor: pointer;
      margin-left: 50px;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s; }
      #story-form .button-group .save-as-draft:hover {
        opacity: 0.8; }
  #story-form .select2-container .select2-search__field {
    margin-top: 0px !important; }
  #story-form .select2-container--default .select2-selection--multiple {
    border-color: #808080; }
  #story-form .custom-file {
    height: 50px;
    line-height: 50px !important;
    font-size: 1rem; }
    #story-form .custom-file input[type="file"] {
      border: none; }
    #story-form .custom-file input[lang="en"] + label:after {
      content: 'Browse...'; }
    #story-form .custom-file input[lang="fr"] + label:after {
      content: "Feuilleter..."; }
    #story-form .custom-file label.custom-file-label {
      height: 48px;
      border: none !important;
      line-height: 50px !important;
      overflow: hidden;
      padding: 0 0.75rem;
      white-space: nowrap; }
      #story-form .custom-file label.custom-file-label:after {
        align-items: center;
        background-color: #4169A4 !important;
        color: #fff;
        display: flex;
        font-size: initial;
        height: 50px;
        justify-content: center;
        line-height: 50px !important;
        text-align: center;
        width: 117px; }
      #story-form .custom-file label.custom-file-label .filename {
        width: 73%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #777; }
        @media (max-width: 767.98px) {
          #story-form .custom-file label.custom-file-label .filename {
            width: 60%; } }
      #story-form .custom-file label.custom-file-label button {
        position: absolute;
        top: 10px;
        z-index: 500; }
        @media (max-width: 767.98px) {
          #story-form .custom-file label.custom-file-label button {
            right: 31%; } }
        @media (min-width: 576px) {
          #story-form .custom-file label.custom-file-label button {
            right: 22%; } }
        @media (min-width: 768px) {
          #story-form .custom-file label.custom-file-label button {
            right: 30%; } }
        @media (min-width: 992px) {
          #story-form .custom-file label.custom-file-label button {
            right: 25%; } }
        @media (min-width: 1200px) {
          #story-form .custom-file label.custom-file-label button {
            right: 20%; } }

@media (max-width: 575px) {
  .form-group label, .view-contact body .control-group:not(.field-spacer) label {
    font-size: 16px; }
  .form-group input:not(.form-check-input), .view-contact body .control-group:not(.field-spacer) input:not(.form-check-input) {
    font-size: 13px !important;
    height: 35px; }
  .form-group textarea, .view-contact body .control-group:not(.field-spacer) textarea {
    font-size: 13px; }
  .form-group .custom-select, .view-contact body .control-group:not(.field-spacer) .custom-select,
  .form-group .my-tagsinput,
  .view-contact body .control-group:not(.field-spacer) .my-tagsinput {
    height: 35px !important;
    font-size: 13px !important; }
  .form-group .tag, .view-contact body .control-group:not(.field-spacer) .tag {
    margin-right: 5px; }
  .form-group .file-drop-zone-title, .view-contact body .control-group:not(.field-spacer) .file-drop-zone-title {
    font-size: 16px; }
  .form-group .input-group, .view-contact body .control-group:not(.field-spacer) .input-group {
    height: 35px !important;
    line-height: 35px; }
  .form-group .btn.btn-primary.btn-file, .view-contact body .control-group:not(.field-spacer) .btn.btn-primary.btn-file {
    background-color: #1592e6 !important;
    line-height: 35px;
    cursor: pointer !important; }
    .form-group .btn.btn-primary.btn-file .hidden-xs, .view-contact body .control-group:not(.field-spacer) .btn.btn-primary.btn-file .hidden-xs {
      font-size: 13px !important;
      padding: 0;
      margin: 0;
      line-height: 35px;
      cursor: pointer !important; }
  .form-group .button-group, .view-contact body .control-group:not(.field-spacer) .button-group {
    margin-top: 10px; }
    .form-group .button-group .primary-button, .view-contact body .control-group:not(.field-spacer) .button-group .primary-button {
      height: 45px;
      line-height: 45px; } }

#story-detail {
  /* Submit Anonymous */ }
  #story-detail .banner-wrapper {
    margin-left: -30px;
    margin-right: -30px; }
    #story-detail .banner-wrapper .hero {
      background-size: cover;
      background-position: center;
      background-color: #757575;
      padding: 0 3rem;
      height: 528px;
      width: 100%; }
      #story-detail .banner-wrapper .hero h1 {
        padding-top: 300px;
        color: #fff; }
        #story-detail .banner-wrapper .hero h1 span {
          display: block;
          font-size: 16px; }
      #story-detail .banner-wrapper .hero span, #story-detail .banner-wrapper .hero a {
        color: #fff;
        text-decoration: none;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s; }
      #story-detail .banner-wrapper .hero a:hover {
        color: #3E65A6; }
      #story-detail .banner-wrapper .hero .container, #story-detail .banner-wrapper .hero .view-story .pv-three-column, .view-story #story-detail .banner-wrapper .hero .pv-three-column {
        height: auto; }
      #story-detail .banner-wrapper .hero .hero-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
        margin-top: 90px;
        width: 100%; }
        #story-detail .banner-wrapper .hero .hero-nav .content-left span {
          margin-right: 20px; }
        #story-detail .banner-wrapper .hero .hero-nav .content-right {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center; }
      #story-detail .banner-wrapper .hero .clap-button {
        background: transparent;
        color: #fff; }
  #story-detail .hero-video {
    background-size: cover;
    background-position: center;
    padding: 0 15px;
    width: 100%; }
    #story-detail .hero-video h1 {
      color: #000; }
      #story-detail .hero-video h1 span {
        display: block;
        font-size: 16px; }
    #story-detail .hero-video span, #story-detail .hero-video a {
      color: #000;
      text-decoration: none;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s; }
    #story-detail .hero-video a:hover {
      color: #3E65A6; }
    #story-detail .hero-video .container, #story-detail .hero-video .view-story .pv-three-column, .view-story #story-detail .hero-video .pv-three-column {
      height: auto; }
    #story-detail .hero-video .poster-wrapper {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 35px;
      height: 0;
      overflow: hidden; }
      #story-detail .hero-video .poster-wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
      #story-detail .hero-video .poster-wrapper #poster-image {
        background-size: cover !important;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        opacity: 1.0;
        position: absolute;
        z-index: 10;
        height: 100%;
        width: 100%;
        transition: all 0.3s ease-in; }
      #story-detail .hero-video .poster-wrapper .play-btn {
        position: absolute;
        height: 80px;
        top: 50%;
        margin-top: -40px;
        left: 50%;
        margin-left: -40px;
        cursor: pointer;
        opacity: 0.5;
        z-index: 10; }
      #story-detail .hero-video .poster-wrapper .play-btn:hover {
        opacity: 1; }
    #story-detail .hero-video .hero-nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #000;
      margin-top: .5rem;
      width: 100%; }
      #story-detail .hero-video .hero-nav .content-left span {
        margin-right: 20px; }
      #story-detail .hero-video .hero-nav .content-right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
    #story-detail .hero-video .clap-button {
      background: transparent;
      color: #000; }
  #story-detail .img-1,
  #story-detail .img-2,
  #story-detail .img-3,
  #story-detail .img-4,
  #story-detail .img-5,
  #story-detail .img-6,
  #story-detail .img-7 {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 100%; }
  #story-detail .img-1 {
    max-height: 250px;
    margin: .3rem 0; }
  #story-detail .img-2 {
    height: 530px; }
  #story-detail .img-3 {
    height: 454px; }
  #story-detail .img-4 {
    height: 454px; }
  #story-detail .img-5 {
    margin: 2rem 0; }
  #story-detail .img-7 {
    margin: 2rem 0; }
  #story-detail .image-cont {
    justify-content: center; }
    #story-detail .image-cont .image-head {
      min-width: 0;
      margin: 5px; }
  #story-detail .content-1,
  #story-detail .content-2,
  #story-detail .content-3, #story-detail .content-4, #story-detail .content-5, #story-detail .content-6, #story-detail .content-7 {
    width: 100%; }
  #story-detail .content-1 #video {
    margin-top: 50px; }
  #story-detail .content-2 {
    margin: 50px 0; }
  #story-detail .content-3 p {
    margin-top: 2rem; }
  #story-detail .content-5 {
    margin: 2rem 0; }
  #story-detail .clap-button {
    border: 1px solid #fff;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    padding: 7px 20px;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s; }
    #story-detail .clap-button i {
      margin-right: 5px; }
    #story-detail .clap-button span {
      margin-left: 10px;
      margin-right: 10px; }
    #story-detail .clap-button:hover {
      opacity: .7; }
    #story-detail .clap-button:hover i {
      color: red; }
  #story-detail .share-social {
    margin-left: 20px; }
    #story-detail .share-social a {
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s; }
      #story-detail .share-social a i {
        margin-left: 10px;
        font-size: 18px;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s; }
      #story-detail .share-social a:hover i {
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2); }
    #story-detail .share-social #facebook:hover i {
      color: #3b5999; }
    #story-detail .share-social #twitter:hover i {
      color: #55acee; }
    #story-detail .share-social #google-plus:hover i {
      color: #dd4b39; }
    #story-detail .share-social #linkedin:hover i {
      color: #0077B5; }
  #story-detail .story-content {
    padding: 0 0 50px;
    width: 100%; }
  #story-detail .wrapper {
    background-color: #fff;
    padding: 50px 3%; }
  #story-detail .nav-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 50px;
    float: right; }
    #story-detail .nav-bottom .social-bottom {
      color: #000;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      #story-detail .nav-bottom .social-bottom .like-button {
        border: 1px solid #000; }
      #story-detail .nav-bottom .social-bottom .share-social a {
        color: #000; }
    #story-detail .nav-bottom .tags a {
      color: #3E65A6;
      text-decoration: none;
      margin-right: 5px;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s; }
      #story-detail .nav-bottom .tags a:hover {
        opacity: .8; }
  #story-detail .comment-section {
    margin-top: 50px; }
    #story-detail .comment-section h3 {
      font-size: 24px !important;
      font-weight: 600;
      margin-bottom: 10px; }
  #story-detail .comment {
    margin-bottom: 30px;
    clear: both; }
  #story-detail .comment-head, #story-detail .reply-head {
    display: flex;
    justify-content: space-between; }
    #story-detail .comment-head .avatar, #story-detail .reply-head .avatar {
      width: 30px;
      height: 30px;
      margin-right: 10px; }
    #story-detail .comment-head .author, #story-detail .reply-head .author {
      color: #3E65A6;
      font-weight: 600 !important;
      text-decoration: none;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s; }
      #story-detail .comment-head .author:hover, #story-detail .reply-head .author:hover {
        opacity: .8; }
    #story-detail .comment-head .date, #story-detail .reply-head .date {
      margin-left: 10px; }
  #story-detail .comment-body {
    margin-left: 40px; }
    #story-detail .comment-body p {
      margin-bottom: 10px !important;
      max-width: 500px;
      font-weight: 400;
      margin-top: 0 !important; }
  #story-detail .comment-button button {
    border: none;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    margin-bottom: 1.2rem; }
    #story-detail .comment-button button i {
      color: #3E65A6;
      margin-right: 5px; }
    #story-detail .comment-button button:nth-child(2) {
      margin-left: 20px; }
    #story-detail .comment-button button:focus {
      outline: none; }
    #story-detail .comment-button button:hover {
      opacity: .8; }
  #story-detail .comment-button-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1.2rem; }
    #story-detail .comment-button-bottom .submit {
      margin-right: 40px;
      display: block;
      width: 180px;
      height: 50px;
      background-color: #3292d8;
      cursor: pointer;
      color: #fff;
      border-radius: 6px;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s; }
      #story-detail .comment-button-bottom .submit:hover {
        opacity: .8; }
    #story-detail .comment-button-bottom .back {
      border: none;
      cursor: pointer;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s; }
      #story-detail .comment-button-bottom .back i {
        margin-right: 10px; }
      #story-detail .comment-button-bottom .back:focus {
        outline: none; }
      #story-detail .comment-button-bottom .back:hover {
        opacity: .8; }
  #story-detail .span_pseudo, #story-detail .anonymously_cb span:before, #story-detail .anonymously_cb span:after {
    content: "";
    display: inline-block;
    background: #fff;
    width: 0;
    height: 0.2rem;
    position: absolute;
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%; }
  #story-detail .anonymously_cb {
    position: relative;
    height: 20px;
    max-width: 180px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  #story-detail .anonymously_cb input {
    display: none; }
  #story-detail .anonymously_cb input:checked ~ span {
    background: #3E65A6;
    border-color: #3E65A6; }
  #story-detail .anonymously_cb input:checked ~ span::before {
    width: 1rem;
    height: 0.15rem;
    -webkit-transition: width 0.1s;
    -o-transition: width 0.1s;
    transition: width 0.1s;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s; }
  #story-detail .anonymously_cb input:checked ~ span::after {
    width: 0.4rem;
    height: 0.15rem;
    -webkit-transition: width 0.1s;
    -o-transition: width 0.1s;
    transition: width 0.1s;
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s; }
  #story-detail .anonymously_cb input:disabled ~ span {
    background: #ececec;
    border-color: #414141; }
  #story-detail .anonymously_cb input:disabled ~ label {
    color: #dcdcdc; }
  #story-detail .anonymously_cb input:disabled ~ label:hover {
    cursor: default; }
  #story-detail .anonymously_cb label {
    padding-left: 2rem;
    position: relative;
    z-index: 2;
    cursor: pointer;
    margin-bottom: 0; }
  #story-detail .anonymously_cb span {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid #414141;
    position: absolute;
    left: 0;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 1;
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
  #story-detail .anonymously_cb span::before {
    -webkit-transform: rotate(-55deg);
    -ms-transform: rotate(-55deg);
    transform: rotate(-55deg);
    top: 1rem;
    left: 0.37rem; }
  #story-detail .anonymously_cb span::after {
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
    bottom: 0.35rem;
    left: 0.2rem; }
  #story-detail .form-control, #story-detail .story-form2 .editor, .story-form2 #story-detail .editor {
    border: 1px solid #808080;
    color: #808080;
    padding: 20px;
    margin-bottom: 20px; }
  @media (max-width: 991px) {
    #story-detail .hero .hero-nav, #story-detail .hero-video .hero-nav {
      font-size: 13px; }
      #story-detail .hero .hero-nav .content-left span, #story-detail .hero-video .hero-nav .content-left span {
        margin-right: 10px; }
    #story-detail .hero-nav {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
    #story-detail .share-social {
      margin-left: 10px; }
      #story-detail .share-social a i {
        font-size: 16px; }
    #story-detail .wrapper {
      padding: 30px 0; }
    #story-detail .carousel {
      margin-top: 30px; }
    #story-detail .remove-offset {
      margin-left: 0 !important; }
    #story-detail .story-content p {
      margin: 0 30px;
      font-size: 15px;
      line-height: 20px; }
    #story-detail .nav-bottom {
      margin: 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }
      #story-detail .nav-bottom .tags {
        font-size: 13px;
        width: 100%;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start; }
      #story-detail .nav-bottom .social-bottom {
        margin: 20px 0;
        width: 100%;
        font-size: 13px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        #story-detail .nav-bottom .social-bottom .share-social {
          margin: 0; }
          #story-detail .nav-bottom .social-bottom .share-social a i {
            font-size: 16px; }
        #story-detail .nav-bottom .social-bottom .like-button {
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1; }
    #story-detail .comment-section {
      margin: 20px 0; } }
  @media (max-width: 767px) {
    #story-detail .hero .hero-nav, #story-detail .hero-video .hero-nav {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin-top: 40px !important; }
    #story-detail .hero-nav .content-left {
      margin-bottom: 20px; } }
  @media (max-width: 575px) {
    #story-detail .hero {
      height: 100%;
      padding-bottom: 1.5rem; }
      #story-detail .hero h1 {
        padding-top: 80px;
        margin: 0 15px;
        font-size: 30px !important;
        line-height: 30px !important; }
      #story-detail .hero span {
        font-size: 13px;
        display: contents; }
      #story-detail .hero .hero-nav {
        margin-top: 30px; }
        #story-detail .hero .hero-nav .content-right {
          display: inline-flex;
          padding-top: 1.2rem; }
        #story-detail .hero .hero-nav .share-social {
          display: inline-block; }
    #story-detail .comment-section .comment-meta .date {
      display: block;
      margin-left: 3.7rem; }
    #story-detail .comment-section .comment-button-bottom {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start; }
      #story-detail .comment-section .comment-button-bottom .submit {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-top: 20px; }
    #story-detail .inner {
      margin-top: 3.5rem; } }
  #story-detail #single-story ol, #story-detail #single-story ul {
    list-style: none; }
  #story-detail #single-story blockquote, #story-detail #single-story q {
    quotes: none; }
  #story-detail #single-story blockquote:before, #story-detail #single-story blockquote:after,
  #story-detail #single-story q:before, #story-detail #single-story q:after {
    content: none; }
  #story-detail #single-story table {
    border-collapse: collapse;
    border-spacing: 0; }
  #story-detail #single-story .inner {
    padding: 0;
    margin-left: -15px;
    margin-right: -15px;
    position: relative; }
    #story-detail #single-story .inner .card-img-overlay a {
      font-size: initial; }
    #story-detail #single-story .inner h1 {
      font-size: 4rem;
      font-family: 'Lato', sans-serif;
      margin-bottom: 1rem;
      letter-spacing: 0.1rem; }
    #story-detail #single-story .inner h2 {
      font-size: 3rem;
      font-family: 'Lato', sans-serif;
      margin-bottom: 1rem;
      letter-spacing: 0.1rem; }
    #story-detail #single-story .inner h3 {
      font-size: 2rem;
      font-family: 'Lato', sans-serif;
      letter-spacing: 0.1rem; }
    #story-detail #single-story .inner ul,
    #story-detail #single-story .inner ol {
      list-style: initial;
      margin-left: auto;
      margin-right: auto;
      width: 80%; }
      #story-detail #single-story .inner ul li,
      #story-detail #single-story .inner ol li {
        text-align: left; }
    #story-detail #single-story .inner ul li,
    #story-detail #single-story .inner ol li,
    #story-detail #single-story .inner p {
      font-family: 'Raleway', sans-serif;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0.1rem; }
    #story-detail #single-story .inner .smodule-maintitle {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url("/images/stories/placeholders/tag_nurse_1.jpg");
      background-position: center;
      background-size: cover;
      background-attachment: scroll; }
      #story-detail #single-story .inner .smodule-maintitle .smodule-content {
        padding-left: 2.2rem;
        padding-right: 2.2rem;
        z-index: 1; }
        #story-detail #single-story .inner .smodule-maintitle .smodule-content h1 {
          color: #000; }
        #story-detail #single-story .inner .smodule-maintitle .smodule-content h3.tags a {
          font-weight: 400;
          margin-right: 10px; }
          #story-detail #single-story .inner .smodule-maintitle .smodule-content h3.tags a:hover {
            color: #006fCf; }
    #story-detail #single-story .inner .smodule-small {
      display: flex;
      justify-content: center;
      padding: 100px 0 100px 0;
      text-align: center;
      background-image: none !important; }
      #story-detail #single-story .inner .smodule-small + .smodule-small {
        padding-top: 0px; }
      #story-detail #single-story .inner .smodule-small .smodule-content {
        width: 600px;
        max-width: 80%; }
        #story-detail #single-story .inner .smodule-small .smodule-content img {
          width: 100%;
          margin: 50px 0 50px 0; }
    #story-detail #single-story .inner .smodule-medium, #story-detail #single-story .inner .smodule-video {
      display: flex;
      align-items: center;
      text-align: center;
      background-image: url("/images/stories/placeholders/tag_mychart_1.jpg");
      background-position: center;
      background-size: cover;
      background-attachment: scroll; }
      #story-detail #single-story .inner .smodule-medium .smodule-content, #story-detail #single-story .inner .smodule-video .smodule-content {
        width: 550px;
        max-width: 50%;
        margin: 6.7rem 50px 6.7rem auto;
        padding: 3.5rem;
        background-color: rgba(250, 253, 255, 0.95); }
    #story-detail #single-story .inner .smodule-video {
      background-image: none; }
      #story-detail #single-story .inner .smodule-video .smodule-content {
        padding: 0 !important;
        margin: 0 auto !important;
        width: 100%;
        max-width: 100%; }
        #story-detail #single-story .inner .smodule-video .smodule-content .poster-wrapper {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          overflow: hidden; }
          #story-detail #single-story .inner .smodule-video .smodule-content .poster-wrapper iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; }
          #story-detail #single-story .inner .smodule-video .smodule-content .poster-wrapper #poster-image {
            background-size: cover !important;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            opacity: 1.0;
            position: absolute;
            z-index: 10;
            height: 100%;
            width: 100%;
            transition: all 0.3s ease-in; }
          #story-detail #single-story .inner .smodule-video .smodule-content .poster-wrapper .play-btn {
            position: absolute;
            height: 80px;
            top: 50%;
            margin-top: -40px;
            left: 50%;
            margin-left: -40px;
            cursor: pointer;
            opacity: 0.5;
            z-index: 10; }
          #story-detail #single-story .inner .smodule-video .smodule-content .poster-wrapper .play-btn:hover {
            opacity: 1; }
    #story-detail #single-story .inner .smodule-large {
      display: flex;
      align-items: center;
      text-align: center;
      background-image: url("/images/stories/placeholders/tag_doctor_1.jpg");
      background-position: center;
      background-size: cover;
      background-attachment: scroll; }
      #story-detail #single-story .inner .smodule-large .smodule-content {
        width: 550px;
        max-width: 50%;
        margin-left: auto;
        margin-right: 50px;
        padding: 3.5rem;
        background-color: rgba(250, 253, 255, 0.95); }
    #story-detail #single-story .inner .smodule-mbg-wrapper {
      z-index: -10;
      display: grid;
      grid-template-columns: 1fr;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
      #story-detail #single-story .inner .smodule-mbg-wrapper .smodule-mbg {
        background-position: center;
        background-size: cover;
        background-attachment: scroll; }
      #story-detail #single-story .inner .smodule-mbg-wrapper .smodule-mbg + .smodule-mbg {
        background-position: center;
        background-size: cover;
        background-attachment: scroll; }
    #story-detail #single-story .inner .mirrored:not(.smodule-small) {
      /*!*--- Temporary change of bg, please ignore ---*!
    &.smodule-medium {
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
    }*/ }
      #story-detail #single-story .inner .mirrored:not(.smodule-small) > .smodule-content {
        margin-left: 40px;
        margin-right: auto; }
      #story-detail #single-story .inner .mirrored:not(.smodule-small).smodule-large {
        position: relative; }
    @media screen and (max-width: 992px) {
      #story-detail #single-story .inner .smodule-content {
        max-width: 100% !important;
        margin: 65% 0 0 !important;
        width: 100% !important;
        padding: 2rem !important; }
        #story-detail #single-story .inner .smodule-content p {
          font-size: 16px;
          line-height: 1.5; }
      #story-detail #single-story .inner .smodule-maintitle {
        padding: 0 2rem; }
        #story-detail #single-story .inner .smodule-maintitle h1 {
          font-size: 2.5rem; }
      #story-detail #single-story .inner .smodule-small {
        padding: 0 2rem !important; }
        #story-detail #single-story .inner .smodule-small .smodule-content {
          padding: 2rem 0 !important;
          margin: 0 !important; }
        #story-detail #single-story .inner .smodule-small.mirrored .smodule-content {
          margin: 0 !important;
          padding: 0 !important; } }
    @media screen and (max-width: 800px) {
      #story-detail #single-story .inner .smodule-medium, #story-detail #single-story .inner .smodule-video {
        background-repeat: no-repeat;
        background-size: contain;
        background-attachment: unset;
        background-position: unset; } }
    @media (max-width: 575.98px) {
      #story-detail #single-story .inner .smodule-content {
        text-align: left; } }

/*Updated Design*/
.stories-inner.container-fluid, .stories-inner.container-sm, .stories-inner.container-md, .stories-inner.container-lg, .stories-inner.container-xl, .stories-inner.container-xxl {
  margin-top: 5rem; }
  .stories-inner.container-fluid .container, .stories-inner.container-sm .container, .stories-inner.container-md .container, .stories-inner.container-lg .container, .stories-inner.container-xl .container, .stories-inner.container-xxl .container, .stories-inner.container-fluid .view-story .pv-three-column, .view-story .stories-inner.container-fluid .pv-three-column, .stories-inner.container-sm .view-story .pv-three-column, .view-story .stories-inner.container-sm .pv-three-column, .stories-inner.container-md .view-story .pv-three-column, .view-story .stories-inner.container-md .pv-three-column, .stories-inner.container-lg .view-story .pv-three-column, .view-story .stories-inner.container-lg .pv-three-column, .stories-inner.container-xl .view-story .pv-three-column, .view-story .stories-inner.container-xl .pv-three-column, .stories-inner.container-xxl .view-story .pv-three-column, .view-story .stories-inner.container-xxl .pv-three-column {
    padding-top: 2rem;
    padding-bottom: 2rem; }

.stories-inner .story-preview {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white !important;
  transition: all 0.5s ease;
  /*h3{
        min-height: 9vh;
    }*/ }
  .stories-inner .story-preview a.story-title {
    color: inherit !important; }
  .stories-inner .story-preview .story-description {
    max-height: 0;
    overflow: hidden;
    transition: 0.6s; }

.stories-inner .hover-float, .stories-inner .navbar-nav .nav-item span, .navbar-nav .nav-item .stories-inner span,
.stories-inner .navbar-nav .nav-item a, .navbar-nav .nav-item .stories-inner a {
  /*display: flex;*/
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.2s !important;
  transition-property: all !important;
  transition-timing-function: ease-out; }

.stories-inner .hover-float:hover, .stories-inner .navbar-nav .nav-item span:hover, .navbar-nav .nav-item .stories-inner span:hover,
.stories-inner .navbar-nav .nav-item a:hover, .navbar-nav .nav-item .stories-inner a:hover, .stories-inner .hover-float:focus, .stories-inner .navbar-nav .nav-item span:focus, .navbar-nav .nav-item .stories-inner span:focus,
.stories-inner .navbar-nav .nav-item a:focus, .navbar-nav .nav-item .stories-inner a:focus, .stories-inner .hover-float:active, .stories-inner .navbar-nav .nav-item span:active, .navbar-nav .nav-item .stories-inner span:active,
.stories-inner .navbar-nav .nav-item a:active, .navbar-nav .nav-item .stories-inner a:active {
  transform: translateY(-2px);
  /*.story-preview h3{
          min-height: inherit;
      }*/ }

.stories-inner .hover-primary:hover, .stories-inner .navbar-nav .nav-item span:hover, .navbar-nav .nav-item .stories-inner span:hover,
.stories-inner .navbar-nav .nav-item a:hover, .navbar-nav .nav-item .stories-inner a:hover, .stories-inner .hover-primary:focus, .stories-inner .navbar-nav .nav-item span:focus, .navbar-nav .nav-item .stories-inner span:focus,
.stories-inner .navbar-nav .nav-item a:focus, .navbar-nav .nav-item .stories-inner a:focus, .stories-inner .hover-primary:active, .stories-inner .navbar-nav .nav-item span:active, .navbar-nav .nav-item .stories-inner span:active,
.stories-inner .navbar-nav .nav-item a:active, .navbar-nav .nav-item .stories-inner a:active {
  color: #006fCf !important; }

/* "you may also like" pictures don't need to be 500px high */
#my-stories.smaller-pictures .story.card img.card-img-top, .pv-stories-wrapper .section-card #my-stories.smaller-pictures .row .inner .story.card a.vimeo-popup img {
  height: 256px; }

.pv-stories-wrapper .hero {
  text-align: center;
  margin: 2.5rem 0; }

.pv-stories-wrapper .shortby a {
  color: #000000;
  margin-left: 30px; }
  .pv-stories-wrapper .shortby a.active {
    color: #4169A4; }
  .pv-stories-wrapper .shortby a:hover {
    color: #4169A4;
    text-decoration: none; }

.pv-stories-wrapper .section-card {
  margin: 30px 0; }
  .pv-stories-wrapper .section-card .sorting-cont {
    margin-bottom: 1.2rem; }
  .pv-stories-wrapper .section-card #my-stories .row {
    margin-bottom: 0; }
    .pv-stories-wrapper .section-card #my-stories .row .inner {
      margin-bottom: 1.5rem; }
      .pv-stories-wrapper .section-card #my-stories .row .inner .card {
        height: 100%; }
        .pv-stories-wrapper .section-card #my-stories .row .inner .card .card-button-group {
          position: relative; }
          .pv-stories-wrapper .section-card #my-stories .row .inner .card .card-button-group .left {
            position: absolute;
            left: 0;
            bottom: -20px; }
          .pv-stories-wrapper .section-card #my-stories .row .inner .card .card-button-group .right {
            position: absolute;
            bottom: -11px;
            right: 0; }

.pv-stories-wrapper .video {
  position: relative; }
  .pv-stories-wrapper .video i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 60px;
    color: #fff; }

.pv-stories-wrapper .card {
  border: none !important;
  border-radius: 4px !important;
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1); }
  .pv-stories-wrapper .card a .card-img-top, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img {
    position: relative;
    height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
    .pv-stories-wrapper .card a .card-img-top:hover, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img:hover {
      opacity: 0.9; }
  .pv-stories-wrapper .card a .card-title {
    color: #000;
    margin: 0 5px 5px 0;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    min-height: 60px;
    max-height: 65px;
    overflow: hidden; }
    .pv-stories-wrapper .card a .card-title:hover {
      color: #4169A4; }
  .pv-stories-wrapper .card .card-block {
    padding: 30px 20px; }
    .pv-stories-wrapper .card .card-block .card-info-top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: 53px; }
    .pv-stories-wrapper .card .card-block .card-icons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-size: 12px; }
      .pv-stories-wrapper .card .card-block .card-icons .icon:not(:last-child) {
        margin-right: 10px; }
      .pv-stories-wrapper .card .card-block .card-icons .icon {
        display: inherit; }
        .pv-stories-wrapper .card .card-block .card-icons .icon img {
          margin-top: 0; }
        .pv-stories-wrapper .card .card-block .card-icons .icon i:hover {
          color: #4169a4; }
        .pv-stories-wrapper .card .card-block .card-icons .icon span {
          margin-left: 5px;
          margin-top: -3px; }
    .pv-stories-wrapper .card .card-block .card-info {
      font-size: 12px;
      margin: 10px auto; }
      .pv-stories-wrapper .card .card-block .card-info .category {
        color: #4169A4; }
      .pv-stories-wrapper .card .card-block .card-info .author {
        color: #000000;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s; }
        .pv-stories-wrapper .card .card-block .card-info .author:hover {
          color: #4169A4; }
    .pv-stories-wrapper .card .card-block .card-text {
      line-height: 20px;
      height: 80px;
      overflow: hidden; }
    .pv-stories-wrapper .card .card-block .card-button-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      margin-top: 30px; }
      .pv-stories-wrapper .card .card-block .card-button-group .right {
        padding: 0 1.2rem; }
      .pv-stories-wrapper .card .card-block .card-button-group .edit-button {
        color: #000000;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        margin-right: 10px;
        padding: 0 1.2rem; }
        .pv-stories-wrapper .card .card-block .card-button-group .edit-button:hover {
          color: #4169A4; }
      .pv-stories-wrapper .card .card-block .card-button-group .story-abuse-link {
        color: #4169A4; }

@media (max-width: 767.98px) {
  .pv-stories-wrapper .hero {
    margin-top: 30px;
    margin-bottom: 30px; }
  .pv-stories-wrapper .section-card {
    margin: 20px 0; }
  .pv-stories-wrapper .shortby a {
    margin-left: 20px; }
  .pv-stories-wrapper .card a .card-img-top, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img {
    height: 180px; }
  .pv-stories-wrapper .card .card-block {
    padding: 20px 10px; }
    .pv-stories-wrapper .card .card-block .card-button-group {
      margin-top: 20px; }
  .pv-stories-wrapper .b-button {
    width: 100%;
    margin: 1rem auto; }
  .pv-stories-wrapper .left {
    bottom: -12px !important; }
  .pv-stories-wrapper .right {
    bottom: -3px !important; } }

@media (max-width: 1199.98px) {
  .pv-stories-wrapper .row > *:not(:last-child) {
    margin-bottom: 20px; } }

.banner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  width: 100%;
  height: 125px;
  margin-top: 50px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2); }

.banner-img {
  position: absolute;
  display: block;
  top: -20px;
  left: 10px; }

.banner-text {
  max-width: 500px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  font-size: 16px;
  color: #fff;
  margin-right: 20px;
  margin-left: 150px; }

.banner-button {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin-right: 20px; }
  .banner-button a {
    display: block;
    width: 180px;
    height: 50px;
    background-color: #fff;
    border-radius: 100px;
    text-align: center;
    line-height: 50px;
    color: #00529C;
    text-decoration: none;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s; }
    .banner-button a:hover {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-transition: .2s;
      -o-transition: .2s;
      transition: .2s; }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .pv-stories-page #pv-stories.card-columns {
    column-count: 2; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .pv-stories-page #pv-stories.card-columns {
    column-count: 2; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .pv-stories-page #pv-stories.card-columns {
    column-count: 2; } }

@media (min-width: 1200px) and (max-width: 1439.98px) {
  .pv-stories-page #pv-stories.card-columns {
    column-count: 2; } }

.pv-stories-page .sort-by-links a.sort-by {
  display: inline-block; }
  .pv-stories-page .sort-by-links a.sort-by.active {
    text-decoration: underline;
    color: #4169a4; }

.pv-stories-page .card-img-top.video-container, .pv-stories-page .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img.video-container, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup .pv-stories-page img.video-container {
  height: 166px; }
  .pv-stories-page .card-img-top.video-container .vimeo-popup, .pv-stories-page .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img.video-container .vimeo-popup, .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup .pv-stories-page img.video-container .vimeo-popup {
    display: block;
    height: 166px; }

@media (max-width: 575.98px) {
  .story .row {
    margin-bottom: 0 !important; }
    .story .row section {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem; } }

.story .card-title small {
  display: block; }

.story .story-description p:last-child {
  margin-bottom: 0; }

.story-category {
  font-size: 50%; }

/** Form 2 **/
.story-form2 textarea {
  width: 100%; }

.story-form2 .btn-toolbar {
  border: #0B55C4;
  position: absolute;
  top: 1rem;
  right: 2em;
  opacity: 0.7;
  z-index: 1060; }
  .story-form2 .btn-toolbar .btn {
    color: #fff;
    background-color: #0b70cd;
    border: #0D47A1 inset 1px; }
  .story-form2 .btn-toolbar:hover {
    opacity: 1; }

.story-form2 .story-media-col {
  position: relative; }
  .story-form2 .story-media-col img.story-image-preview {
    width: 100%;
    height: 400px;
    position: relative; }
  .story-form2 .story-media-col .vimeo-embed {
    position: absolute;
    top: 0;
    z-index: 0; }
  .story-form2 .story-media-col .progress-container {
    padding: 0 1rem;
    position: absolute;
    top: 150px;
    width: 100%;
    display: none; }

@media (max-width: 991.98px) {
  .banner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 350px; }
  .banner-img {
    position: absolute;
    display: block;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .banner-text {
    text-align: center;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 40px;
    margin-top: 150px; }
  .banner-button {
    margin-right: 0;
    margin-bottom: 30px; }
  .layout-single {
    font-size: 70%; } }

@media (max-width: 575.98px) {
  .banner-text {
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 20px;
    margin-top: 120px; }
  .banner-img {
    top: -43px; }
  .layout-single {
    font-size: 50%; } }

@media screen and (max-width: 400px) {
  .banner {
    height: auto; }
    .banner .banner-text {
      margin-top: 85px; } }

.school-icons {
  display: none; }

.interactive-slider {
  width: 80%;
  margin: 0 auto; }

#slider {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.085) !important;
  height: 7px;
  margin: 3em 0;
  position: relative;
  background: none;
  border: none; }
  #slider .ui-slider-handle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: none;
    background-color: #006fCf;
    border: none;
    top: -7px;
    position: absolute;
    transition: left 0.1s ease-out; }
    #slider .ui-slider-handle:hover {
      cursor: pointer; }

@keyframes bounce-grow-10 {
  0% {
    transform: scale(0.8, 0.8); }
  35% {
    transform: scale(0.95, 0.95); }
  50% {
    transform: scale(0.95, 0.95); }
  70% {
    transform: scale(0.95, 0.95); }
  100% {
    transform: scale(0.95, 0.95); } }

@keyframes bounce-grow-20 {
  0% {
    transform: scale(0.8, 0.8); }
  35% {
    transform: scale(0.95, 0.95); }
  50% {
    transform: scale(0.95, 0.95); }
  70% {
    transform: scale(0.95, 0.95); }
  100% {
    transform: scale(0.95, 0.95); } }

@keyframes bounce-grow-50 {
  0% {
    transform: scale(0.8, 0.8); }
  35% {
    transform: scale(0.95, 0.95); }
  50% {
    transform: scale(0.95, 0.95); }
  70% {
    transform: scale(0.95, 0.95); }
  100% {
    transform: scale(0.95, 0.95); } }

@keyframes bounce-grow-100 {
  0% {
    transform: scale(0.8, 0.8); }
  35% {
    transform: scale(0.95, 0.95); }
  50% {
    transform: scale(0.95, 0.95); }
  70% {
    transform: scale(0.95, 0.95); }
  100% {
    transform: scale(0.95, 0.95); } }

.icon-container {
  color: #fff;
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  font-size: 80px;
  transform: scale(1, 1);
  transition: transform 0.3s ease-in-out;
  transform-origin: center bottom;
  vertical-align: middle; }
  .icon-container img {
    position: relative;
    top: 0;
    display: none;
    width: 80%;
    margin: 0.05rem auto; }
    .icon-container img.active {
      display: inline-block; }
  .icon-container[data-size="10"] {
    animation: bounce-grow-10 0.5s ease-out alternate;
    transform: scale(0.95, 0.95); }
  .icon-container[data-size="20"] {
    animation: bounce-grow-20 0.5s ease-out alternate;
    transform: scale(0.95, 0.95); }
  .icon-container[data-size="50"] {
    animation: bounce-grow-50 0.5s ease-out alternate;
    transform: scale(0.95, 0.95); }
  .icon-container[data-size="100"] {
    animation: bounce-grow-100 0.5s ease-out alternate;
    transform: scale(0.95, 0.95); }

.caption {
  display: none;
  font-size: 1.4em;
  color: #666;
  margin: 1rem auto 0;
  line-height: 1.4em;
  text-align: center;
  vertical-align: middle;
  font-weight: 700 !important; }
  .caption.active {
    display: inline-block; }
  .caption .amount {
    color: #006fCf;
    font-size: 1.3em;
    font-style: normal; }

.presets {
  text-align: center;
  margin-left: auto;
  margin-right: auto; }
  .presets button {
    background-color: rgba(0, 0, 0, 0.085);
    color: black;
    font-size: 1.3em;
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 1em;
    transition: all 0.3s ease-in-out;
    border: 1px solid rgba(0, 0, 0, 0.085); }
    .presets button.active, .presets button.active:hover {
      background-color: #006fCf;
      color: #fff;
      border-color: #006fCf; }
    .presets button:hover {
      cursor: pointer;
      background-color: transparent;
      color: #006fCf;
      border-color: #006fCf; }

@keyframes dash {
  to {
    stroke-dashoffset: 200; } }

@keyframes pulse {
  0% {
    transform: scale(1, 1); }
  100% {
    transform: scale(1.1, 1.1); } }

#effect {
  position: relative; }
  #effect[data-amount="1000"] #confetti {
    opacity: 1;
    visibility: visible; }
    #effect[data-amount="1000"] #confetti line {
      animation: dash 1.8s ease-out; }
  #effect[data-amount="1000"] .icon-container .shape-graduation-cap {
    animation: pulse 0.75s ease-out alternate infinite; }

#confetti {
  position: absolute;
  top: -9.5em;
  left: calc(50% - 9.5em);
  opacity: 0;
  visibility: hidden; }
  #confetti line {
    fill: none;
    stroke: #006fCf !important;
    stroke-width: 0.5em;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 100 400;
    stroke-dashoffset: 550; }

@media (max-width: 768px) {
  .presets button {
    font-size: 13px; } }

.pv-collab {
  /* ========== HEADER TOP ========== */
  /* ========== STICKY NAV ========== */
  /* ========== Footer ========== */ }
  .pv-collab .primary-button {
    display: block;
    width: 180px;
    height: 45px;
    cursor: pointer;
    border: none;
    text-align: center;
    line-height: 45px;
    font-weight: 600;
    background-color: #4169a4;
    color: #fff;
    outline: none;
    border-radius: 3px;
    text-decoration: none;
    -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease; }
    .pv-collab .primary-button:focus {
      outline: none; }
    .pv-collab .primary-button:hover {
      opacity: .8; }
  .pv-collab .button {
    display: block;
    width: 180px;
    height: 45px;
    cursor: pointer;
    border: none;
    text-align: center;
    line-height: 45px;
    font-weight: 600;
    background-color: #4169a4;
    color: #fff;
    outline: none;
    border-radius: 3px;
    text-decoration: none;
    -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease; }
    .pv-collab .button:focus {
      outline: none; }
    .pv-collab .button:hover {
      color: #fff;
      opacity: .8;
      text-decoration: none; }
  .pv-collab h2 {
    font-size: 36px !important;
    color: #414141;
    font-weight: 600 !important;
    line-height: 36px !important; }
  .pv-collab .h3,
  .pv-collab h3 {
    font-size: 24px;
    color: #414141;
    font-weight: 600;
    line-height: 24px; }
  .pv-collab p {
    /*font-size: 16px !important;*/
    /*font-weight: 400 !important;*/
    line-height: 26px;
    letter-spacing: .1px;
    /*margin: 0 !important;*/ }
  .pv-collab .header-top {
    background-image: url("/images/hospital/header-top.png");
    height: 200px;
    background-position: center;
    background-size: cover; }
  .pv-collab .active {
    color: #4169a4; }
  .pv-collab .sticky {
    position: fixed;
    top: 0;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 20px 0px !important; }
  .pv-collab footer {
    position: relative;
    background-image: url("/images/hospital/footer.png");
    height: 200px;
    background-position: center;
    background-size: cover;
    z-index: 99;
    margin-top: 60px; }
  .pv-collab .idea-detail h3 {
    font-size: 1.75rem !important; }

.hero-section {
  /*background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(77, 80, 80, 0.8)), to(rgba(77, 80, 80, 0.8))),
    url('/images/hospital/hero-img.png');
    background-image: -webkit-linear-gradient(rgba(77, 80, 80, 0.8), rgba(77, 80, 80, 0.8)),
    url('/images/hospital/hero-img.png');
    background-image: -o-linear-gradient(rgba(77, 80, 80, 0.8), rgba(77, 80, 80, 0.8)),
    url('/images/hospital/hero-img.png');
    background-image: linear-gradient(rgba(77, 80, 80, 0.8), rgba(77, 80, 80, 0.8)),
    url('/images/hospital/hero-img.png');*/
  width: 100%;
  /*background-size: cover;
    background-position: center;*/
  padding: 70px 0; }

#pv-challenge-wrapper,
#mod_challenge104,
.moduletable.my-4 {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0 !important; }

.shortby-navigation.pv-collab {
  margin: 0;
  padding: 0 0 0 2em;
  height: 3em;
  line-height: 3em; }

.sticky-menu-container {
  z-index: 0; }

.itemid-362 .my-4 {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }
  .itemid-362 .my-4 .hero-section {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important; }

.itemid-362 #ideas-wrapper {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }

.hero-left {
  text-align: center;
  color: #00529c; }
  .hero-left h1 {
    color: #00529c;
    font-size: 48px !important;
    line-height: 1.2 !important; }
    .hero-left h1 span {
      display: block;
      font-size: inherit;
      margin-top: 15px;
      font-weight: 700; }
  .hero-left h3 {
    color: #00529c; }
  .hero-left .challenge-content {
    margin-top: 4.5rem;
    border: 1px solid #00529c;
    display: inline-block;
    padding: 1.2rem;
    background-color: #fff; }
    .hero-left .challenge-content h2 {
      color: #00529c;
      margin: 2.5rem 4.5rem 1rem;
      font-style: italic;
      font-size: 32px !important;
      font-weight: 700 !important; }
    .hero-left .challenge-content img {
      margin-top: 1.2rem;
      margin-bottom: 1.2rem; }
  .hero-left p {
    color: #767676 !important; }

.hero-right {
  color: #fff; }
  .hero-right .box-right {
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 10px 30px; }
    .hero-right .box-right:first-child {
      margin-bottom: 40px; }
  .hero-right .box-timer {
    text-align: center; }
    .hero-right .box-timer span {
      margin-top: 0;
      color: #fff !important;
      font-size: 20px; }
    .hero-right .box-timer #timer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .hero-right .box-timer .time {
      font-size: 30px !important; }
    .hero-right .box-timer #timer > * span {
      display: block;
      font-size: 12px;
      text-transform: uppercase; }
  .hero-right .scoreboard h4 {
    padding-bottom: 10px;
    border-bottom: 1px solid #fff; }
  .hero-right .scoreboard .table {
    display: table;
    width: 100%;
    margin-bottom: 0; }
    .hero-right .scoreboard .table .tableBody {
      max-width: 150px; }
    .hero-right .scoreboard .table .tableRow {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .hero-right .scoreboard .table .tableRow:not(:last-child) {
        margin-bottom: 5px; }
    .hero-right .scoreboard .table .tableCell {
      display: table-cell; }

.shortby-navigation {
  background-color: transparent;
  width: 100%;
  display: block;
  padding: 30px 0;
  z-index: 0; }

.shortby-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.shortby-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .shortby-nav span {
    margin-right: 30px; }
  .shortby-nav a,
  .shortby-nav #tags-nav,
  .shortby-nav #theme-nav {
    text-decoration: none;
    color: #414141;
    margin-right: 30px;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s; }
    .shortby-nav a i,
    .shortby-nav #tags-nav i,
    .shortby-nav #theme-nav i {
      margin-left: 5px; }
    .shortby-nav a:hover,
    .shortby-nav #tags-nav:hover,
    .shortby-nav #theme-nav:hover {
      color: #4169a4;
      text-decoration: none; }
  .shortby-nav .active {
    color: #4169a4; }

.sb-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .sb-right .button {
    width: 120px;
    height: 40px;
    font-size: 13px;
    margin-right: 30px;
    line-height: 40px; }
  .sb-right form .input-group-append button {
    background: none;
    border: none;
    padding: 3px;
    cursor: pointer; }
  .sb-right .form-control, .sb-right .story-form2 .editor, .story-form2 .sb-right .editor {
    border: none;
    border-bottom: 2px solid #767676;
    border-radius: 0;
    width: 200px;
    box-shadow: none; }
    .sb-right .form-control input[type=search], .sb-right .story-form2 .editor input[type=search], .story-form2 .sb-right .editor input[type=search] {
      border-bottom: 2px solid #4dd0e1;
      -webkit-box-shadow: 0 1px 0 0 #4dd0e1;
      box-shadow: 0 1px 0 0 #4dd0e1;
      outline: 0 !important; }
      .sb-right .form-control input[type=search]:focus, .sb-right .story-form2 .editor input[type=search]:focus, .story-form2 .sb-right .editor input[type=search]:focus {
        outline: 0 !important; }

.pv-collab .big-button {
  display: block;
  width: 28%;
  height: 60px;
  margin: 3rem auto;
  font-size: 20px; }
  .pv-collab .big-button:active {
    background-color: #00529c;
    border-color: #00529c; }

.pv-collab .tags-item,
.pv-collab .theme-item {
  margin-top: 20px; }
  .pv-collab .tags-item a,
  .pv-collab .theme-item a {
    font-size: 13px;
    display: inline-block;
    margin: 5px 5px;
    background-color: #767676;
    padding: 5px 15px;
    color: #fff;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s; }
    .pv-collab .tags-item a.active-tag, .pv-collab .tags-item a.active-theme, .pv-collab .tags-item a:hover,
    .pv-collab .theme-item a.active-tag,
    .pv-collab .theme-item a.active-theme,
    .pv-collab .theme-item a:hover {
      background-color: #4169a4;
      text-decoration: none;
      color: #fff; }

.pv-collab #tags-button,
.pv-collab #theme-button {
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s; }
  .pv-collab #tags-button.active-tag, .pv-collab #tags-button.active-theme, .pv-collab #tags-button:hover,
  .pv-collab #theme-button.active-tag,
  .pv-collab #theme-button.active-theme,
  .pv-collab #theme-button:hover {
    color: #4169a4;
    text-decoration: none; }

.card-section .row:nth-of-type(2) {
  margin-top: 32px; }

.pv-collab .card {
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px !important;
  -webkit-box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
  box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
  margin-bottom: 20px; }

.pv-collab .card-image {
  position: relative;
  max-height: 200px;
  display: block;
  z-index: 10;
  overflow: hidden; }
  .pv-collab .card-image:hover {
    opacity: 0.9; }

.pv-collab .card-img {
  border-radius: 5px 5px 0px 0px !important; }

.pv-collab .card-tags,
.pv-collab .card-icons,
.pv-collab .card-score {
  position: absolute; }

.pv-collab .card-tags {
  bottom: 10px;
  left: 10px; }

.pv-collab .card-score {
  color: #fff;
  top: 10px;
  right: 10px;
  font-size: 12px;
  text-transform: uppercase; }
  .pv-collab .card-score span {
    display: block;
    font-size: 24px;
    -webkit-text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
    -moz-text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
    text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6); }

.pv-collab .card-icons {
  bottom: 10px;
  right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  font-size: 13px; }
  .pv-collab .card-icons .icon i {
    margin-right: 5px; }
  .pv-collab .card-icons .icon:not(:last-child) {
    margin-right: 10px; }

.pv-collab .card-tags,
.pv-collab .card-score,
.pv-collab .card-icons {
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.65) !important; }

.pv-collab .card-theme-tag {
  color: #fff;
  font-size: 13px;
  font-style: italic; }
  .pv-collab .card-theme-tag:hover {
    text-decoration: none;
    color: #4169a4; }
  .pv-collab .card-theme-tag:first-child:before {
    content: ""; }
  .pv-collab .card-theme-tag:before {
    content: " | "; }

.pv-collab .card-block {
  padding: 20px;
  z-index: 999;
  height: 380px; }
  .pv-collab .card-block .card-title:hover {
    color: #4169A4; }

.pv-collab .card-subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  margin-top: 10px !important;
  color: #767676; }
  .pv-collab .card-subtitle .card-author a:hover {
    opacity: .8;
    text-decoration: none; }

.pv-collab .card-date {
  margin-left: 10px; }
  .pv-collab .card-date i {
    margin-right: 5px; }

.pv-collab .card-text {
  color: #767676;
  margin-bottom: 20px !important; }

.pv-collab .card-button {
  display: block;
  width: 120px;
  height: 40px;
  cursor: pointer;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  text-align: center;
  line-height: 40px;
  font-weight: 400;
  background-color: #4169a4;
  color: #fff;
  outline: none;
  border-radius: 3px;
  text-decoration: none;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease; }
  .pv-collab .card-button i {
    margin-left: 5px; }
  .pv-collab .card-button:focus {
    outline: none; }
  .pv-collab .card-button:hover {
    color: #fff;
    opacity: .8;
    text-decoration: none; }

#add-idea h3 {
  font-size: 1.75rem;
  line-height: 1.5 !important; }

.idea-donate-cont {
  background: rgba(0, 0, 0, 0.6); }
  .idea-donate-cont .donate-bg {
    background-image: url("/images/demo/donation_bg.svg");
    background-size: 32%;
    background-position: center;
    background-attachment: fixed; }
    .idea-donate-cont .donate-bg .don-text {
      margin: 2.5rem auto; }
      .idea-donate-cont .donate-bg .don-text .btn-light {
        background-color: #fff;
        border-color: #fff; }

.replies-btn.no-replies {
  vertical-align: initial !important; }

.modal-content {
  border: none !important; }

#btn-close-modal {
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  margin-top: 30px;
  cursor: pointer; }
  #btn-close-modal .closebt {
    -webkit-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
    width: 50px;
    height: 50px; }
    #btn-close-modal .closebt:hover {
      -webkit-transform: rotateZ(90deg);
      -ms-transform: rotate(90deg);
      transform: rotateZ(90deg); }

.modal-heading {
  margin-top: 30px;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: #4169a4 !important; }

.pv-collab {
  /* Submit Anonymous */ }
  .pv-collab.modal-content {
    box-shadow: none !important;
    background-color: #fafdff; }
    .pv-collab.modal-content .card {
      background-color: transparent !important; }
  .pv-collab .span_pseudo, .pv-collab .anonymously_cb span:before, .pv-collab .anonymously_cb span:after {
    content: "";
    display: inline-block;
    background: #fff;
    width: 0;
    height: 0.2rem;
    position: absolute;
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%; }
  .pv-collab .anonymously_cb {
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .pv-collab .anonymously_cb input {
      display: inline-block;
      width: 1.2rem;
      height: 1.2rem;
      border: 2px solid #414141;
      /*position: absolute;*/
      left: 0;
      /*-webkit-transition: all 0.2s;*/
      /*-o-transition: all 0.2s;*/
      /*transition: all 0.2s;*/
      z-index: 1;
      -webkit-box-sizing: content-box;
      box-sizing: content-box; }
      .pv-collab .anonymously_cb input:checked ~ span {
        background: #4169a4;
        border-color: #4169a4; }
      .pv-collab .anonymously_cb input:checked ~ span::before {
        width: 1rem;
        height: 0.15rem;
        -webkit-transition: width 0.1s;
        -o-transition: width 0.1s;
        transition: width 0.1s;
        -webkit-transition-delay: 0.3s;
        -o-transition-delay: 0.3s;
        transition-delay: 0.3s; }
      .pv-collab .anonymously_cb input:checked ~ span::after {
        width: 0.4rem;
        height: 0.15rem;
        -webkit-transition: width 0.1s;
        -o-transition: width 0.1s;
        transition: width 0.1s;
        -webkit-transition-delay: 0.2s;
        -o-transition-delay: 0.2s;
        transition-delay: 0.2s; }
      .pv-collab .anonymously_cb input:disabled ~ span {
        background: #ececec;
        border-color: #414141; }
      .pv-collab .anonymously_cb input:disabled ~ label {
        color: #dcdcdc; }
      .pv-collab .anonymously_cb input:disabled ~ label:hover {
        cursor: default; }
    .pv-collab .anonymously_cb .control-label {
      font-size: 16px; }
    .pv-collab .anonymously_cb label {
      padding-left: 0.5rem;
      position: relative;
      z-index: 2;
      margin-bottom: 0; }
    .pv-collab .anonymously_cb span {
      display: inline-block;
      width: 1.2rem;
      height: 1.2rem;
      border: 2px solid #414141;
      position: absolute;
      left: 0;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
      z-index: 1;
      -webkit-box-sizing: content-box;
      box-sizing: content-box; }
      .pv-collab .anonymously_cb span::before {
        -webkit-transform: rotate(-55deg);
        -ms-transform: rotate(-55deg);
        transform: rotate(-55deg);
        top: 1rem;
        left: 0.37rem; }
      .pv-collab .anonymously_cb span::after {
        -webkit-transform: rotate(35deg);
        -ms-transform: rotate(35deg);
        transform: rotate(35deg);
        bottom: 0.35rem;
        left: 0.2rem; }
  .pv-collab .form-group label, .pv-collab .view-contact body .control-group:not(.field-spacer) label, .view-contact body .pv-collab .control-group:not(.field-spacer) label {
    color: #414141 !important;
    font-size: 20px;
    line-height: 1.5; }
    .pv-collab .form-group label span, .pv-collab .view-contact body .control-group:not(.field-spacer) label span, .view-contact body .pv-collab .control-group:not(.field-spacer) label span {
      color: #4169a4; }
  .pv-collab .form-group input, .pv-collab .view-contact body .control-group:not(.field-spacer) input, .view-contact body .pv-collab .control-group:not(.field-spacer) input {
    width: 100%;
    height: 50px;
    border: 1px solid #767676;
    border-radius: 3px;
    color: #767676;
    font-weight: 400 !important; }
    .pv-collab .form-group input::-webkit-input-placeholder, .pv-collab .view-contact body .control-group:not(.field-spacer) input::-webkit-input-placeholder, .view-contact body .pv-collab .control-group:not(.field-spacer) input::-webkit-input-placeholder {
      color: #767676; }
    .pv-collab .form-group input:-ms-input-placeholder, .pv-collab .view-contact body .control-group:not(.field-spacer) input:-ms-input-placeholder, .view-contact body .pv-collab .control-group:not(.field-spacer) input:-ms-input-placeholder {
      color: #767676; }
    .pv-collab .form-group input::-ms-input-placeholder, .pv-collab .view-contact body .control-group:not(.field-spacer) input::-ms-input-placeholder, .view-contact body .pv-collab .control-group:not(.field-spacer) input::-ms-input-placeholder {
      color: #767676; }
    .pv-collab .form-group input::placeholder, .pv-collab .view-contact body .control-group:not(.field-spacer) input::placeholder, .view-contact body .pv-collab .control-group:not(.field-spacer) input::placeholder {
      color: #767676; }
  .pv-collab .form-group textarea, .pv-collab .view-contact body .control-group:not(.field-spacer) textarea, .view-contact body .pv-collab .control-group:not(.field-spacer) textarea {
    border: 1px solid #767676;
    border-radius: 3px; }
  .pv-collab .form-group .my-tagsinput, .pv-collab .view-contact body .control-group:not(.field-spacer) .my-tagsinput, .view-contact body .pv-collab .control-group:not(.field-spacer) .my-tagsinput {
    display: inline-block;
    margin: 0px;
    width: 100%;
    height: 50px;
    border: 1px solid #767676 !important;
    border-radius: 3px !important;
    padding: 0px 20px;
    font-family: 'Source Sans Pro', sans-serif !important;
    overflow: hidden; }
    .pv-collab .form-group .my-tagsinput input, .pv-collab .view-contact body .control-group:not(.field-spacer) .my-tagsinput input, .view-contact body .pv-collab .control-group:not(.field-spacer) .my-tagsinput input {
      border: none;
      font-size: 13px; }
    .pv-collab .form-group .my-tagsinput .tag, .pv-collab .view-contact body .control-group:not(.field-spacer) .my-tagsinput .tag, .view-contact body .pv-collab .control-group:not(.field-spacer) .my-tagsinput .tag {
      background-color: #4169a4;
      padding: 5px 15px;
      font-size: 13px !important; }
  .pv-collab p {
    /*margin-bottom: 0 !important;*/
    /*font-weight: 600;*/
    margin-top: 20px !important; }
  .pv-collab .custom-select {
    height: 50px !important;
    color: #767676 !important;
    border: 1px solid #767676 !important;
    border-radius: 3px !important; }
  .pv-collab .cb-up-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .pv-collab .nav-button {
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .pv-collab .nav-button .cancel {
      width: 80px;
      background-color: transparent;
      margin-left: 30px;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #414141 !important; }
    .pv-collab .nav-button .draft {
      width: 100px;
      background-color: transparent;
      margin-left: 30px;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #414141 !important; }
  .pv-collab input[type=file] {
    display: block !important;
    right: 1px;
    top: 1px;
    height: 40px;
    opacity: 0;
    width: 100%;
    background: none;
    position: absolute;
    overflow: hidden;
    z-index: 2; }
  .pv-collab .control-fileupload {
    display: block;
    background: #FFF;
    border-radius: 4px;
    width: 55%;
    height: 40px;
    overflow: hidden;
    position: relative;
    /* File upload button */ }
    .pv-collab .control-fileupload:before, .pv-collab .control-fileupload input, .pv-collab .control-fileupload label {
      cursor: pointer !important; }
    .pv-collab .control-fileupload:before {
      display: block;
      height: 40px;
      width: 125px;
      font-size: 16px;
      line-height: 40px !important;
      color: #4169a4;
      text-align: center;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #4169a4;
      border-radius: 3px;
      -webkit-transition: color 0.2s ease;
      -o-transition: color 0.2s ease;
      transition: color 0.2s ease;
      /* add more custom styles*/
      content: 'Browse';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0px;
      right: 0px;
      line-height: 40px;
      text-align: center; }
    .pv-collab .control-fileupload:hover:before, .pv-collab .control-fileupload:focus:before {
      color: #4169a4;
      background-color: #fff;
      text-decoration: none;
      cursor: pointer; }
    .pv-collab .control-fileupload label {
      line-height: 40px;
      color: #414141;
      width: 140px;
      font-size: 16px;
      font-weight: normal;
      white-space: nowrap;
      position: relative;
      z-index: 1;
      cursor: pointer; }

.pv-collab .big-single-image {
  border-radius: 5px 5px 0px 0px;
  margin-top: 30px;
  width: 100%; }

.pv-collab .s-heading {
  margin-top: 30px; }

.pv-collab .s-paragraph {
  margin-top: 0 !important; }

.pv-collab .heading-bar {
  margin-top: 5px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline; }
  .pv-collab .heading-bar .theme-tag {
    color: #006fCf !important;
    text-decoration: none;
    font-weight: 600;
    font-style: italic;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    font-size: 16px !important;
    margin-right: 20px; }
    .pv-collab .heading-bar .theme-tag:hover {
      opacity: .8; }
  .pv-collab .heading-bar .author {
    color: #767676;
    font-style: italic;
    font-size: 14px !important;
    margin-right: 20px; }
    .pv-collab .heading-bar .author .name {
      text-decoration: none;
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s; }
      .pv-collab .heading-bar .author .name:hover {
        opacity: .8; }
  .pv-collab .heading-bar .date {
    color: #767676;
    font-size: 14px !important; }
    .pv-collab .heading-bar .date i {
      margin-right: 5px; }

.pv-collab .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px; }
  .pv-collab .rating p {
    margin-bottom: 0;
    margin-top: 0px !important; }
  .pv-collab .rating .stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    margin-left: 20px;
    margin-right: 20px; }
    .pv-collab .rating .stars label i {
      font-size: 20px;
      margin-right: 5px; }
  .pv-collab .rating .star-empty {
    color: #767676; }
  .pv-collab .rating .star-checked {
    color: #feca57; }
  .pv-collab .rating input {
    position: absolute;
    opacity: 0;
    pointer-events: none; }
  .pv-collab .rating input[type=radio] + label {
    cursor: pointer; }
  .pv-collab .rating input[type=radio] + label i.star-empty,
  .pv-collab .rating input[type=radio]:checked + label i.star-checked,
  .pv-collab .rating input[type=radio]:checked ~ input[type=radio] + label i.star-checked {
    display: inline-block; }
  .pv-collab .rating input[type=radio] + label i.star-checked,
  .pv-collab .rating input[type=radio]:checked + label i.star-empty,
  .pv-collab .rating input[type=radio]:checked ~ input[type=radio] + label i.star-empty {
    display: none; }
  .pv-collab .rating label {
    margin-bottom: 0; }
  .pv-collab .rating button {
    width: 60px;
    height: 20px;
    line-height: 20px;
    border: none;
    color: #767676;
    cursor: pointer;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s; }
    .pv-collab .rating button i {
      margin-right: 5px; }
    .pv-collab .rating button:hover {
      color: #414141; }
    .pv-collab .rating button:focus {
      display: none; }

.pv-collab .nav-single-page {
  margin-top: 20px; }
  .pv-collab .nav-single-page button, .pv-collab .nav-single-page a, .pv-collab .nav-single-page .btn {
    border: none;
    border-radius: 3px;
    background-color: #767676;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 400 !important;
    transition: all .2s;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    vertical-align: top;
    width: 95px;
    padding: 0 .75rem;
    margin-right: .5rem;
    margin-bottom: .5rem; }
    .pv-collab .nav-single-page button:nth-child(5), .pv-collab .nav-single-page a:nth-child(5), .pv-collab .nav-single-page .btn:nth-child(5) {
      background-color: transparent;
      color: #767676 !important;
      border: 1px solid #767676 !important;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s; }
    .pv-collab .nav-single-page button:hover, .pv-collab .nav-single-page a:hover, .pv-collab .nav-single-page .btn:hover {
      background: #4169a4; }
      .pv-collab .nav-single-page button:hover:nth-child(5), .pv-collab .nav-single-page a:hover:nth-child(5), .pv-collab .nav-single-page .btn:hover:nth-child(5) {
        color: #fff !important;
        border: 1px solid #4169a4 !important; }
    .pv-collab .nav-single-page button.active, .pv-collab .nav-single-page a.active, .pv-collab .nav-single-page .btn.active {
      background-color: #4169a4 !important;
      color: #fff !important;
      box-shadow: none !important; }

.pv-collab button.selected {
  background-color: #4169a4; }

.pv-collab .evaluate-section {
  margin-top: 20px; }
  .pv-collab .evaluate-section p {
    color: #414141 !important; }
    .pv-collab .evaluate-section p span {
      font-size: 20px;
      font-weight: 600; }
  .pv-collab .evaluate-section .evaluate-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px; }
    .pv-collab .evaluate-section .evaluate-slide .slide-box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 180px;
      height: auto; }
      .pv-collab .evaluate-section .evaluate-slide .slide-box span {
        margin-bottom: 20px;
        color: #414141;
        font-weight: 600; }

.pv-collab .evaluate-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 40px; }
  .pv-collab .evaluate-nav .button {
    margin-right: 20px; }
  .pv-collab .evaluate-nav .evaluate-cancel {
    border: none;
    cursor: pointer;
    width: 80px;
    color: #414141; }
    .pv-collab .evaluate-nav .evaluate-cancel:focus {
      outline: none; }
    .pv-collab .evaluate-nav .evaluate-cancel:hover {
      opacity: .8; }

.pv-collab .comment-section {
  margin: 40px 0px;
  width: 100%;
  /*.form-check{
            .form-check-input{
                position: absolute;
                margin-top: 1.3rem !important;
                margin-left: -1.25rem;
            }
        }*/ }
  .pv-collab .comment-section h3 {
    margin-bottom: 10px;
    font-size: 24px !important;
    font-weight: 600;
    color: #414141; }
  .pv-collab .comment-section .form-group, .pv-collab .comment-section .view-contact body .control-group:not(.field-spacer), .view-contact body .pv-collab .comment-section .control-group:not(.field-spacer) {
    margin-bottom: 30px; }
  .pv-collab .comment-section .comment-button,
  .pv-collab .comment-section .comment-body,
  .pv-collab .comment-section .form-edit-improvement {
    margin-left: 40px; }
  .pv-collab .comment-section .comment-body p {
    margin-bottom: 10px !important;
    max-width: 500px;
    font-weight: 400;
    margin-top: 0 !important; }
  .pv-collab .comment-section .btn:not(.btn-primary) {
    background-color: transparent !important; }

.pv-collab .comment:not(:last-child) {
  margin-bottom: 30px; }

.pv-collab .comment .comment-button button {
  border: none;
  cursor: pointer;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  background-color: transparent; }
  .pv-collab .comment .comment-button button i {
    color: #4169a4;
    margin-right: 5px; }
  .pv-collab .comment .comment-button button:nth-child(2) {
    margin-left: 20px; }
  .pv-collab .comment .comment-button button:focus {
    outline: none; }
  .pv-collab .comment .comment-button button:hover {
    opacity: .8; }

.pv-collab .comment-head, .pv-collab .report-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .pv-collab .comment-head .avatar, .pv-collab .report-head .avatar {
    width: 30px;
    height: 30px;
    margin-right: 10px; }
  .pv-collab .comment-head .author, .pv-collab .report-head .author {
    font-weight: 600 !important;
    text-decoration: none;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s; }
    .pv-collab .comment-head .author:hover, .pv-collab .report-head .author:hover {
      opacity: .8; }
  .pv-collab .comment-head .date, .pv-collab .report-head .date {
    color: #767676;
    margin-left: 10px; }
  .pv-collab .comment-head .comment-report a, .pv-collab .comment-head .reply-report a, .pv-collab .report-head .comment-report a, .pv-collab .report-head .reply-report a {
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s; }
    .pv-collab .comment-head .comment-report a:hover, .pv-collab .comment-head .reply-report a:hover, .pv-collab .report-head .comment-report a:hover, .pv-collab .report-head .reply-report a:hover {
      opacity: .8; }

.pv-collab .comment-replay {
  margin-top: 20px; }
  .pv-collab .comment-replay p {
    max-width: 470px; }

.pv-collab .comment-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .pv-collab .comment-button .submit {
    margin-right: 40px; }
  .pv-collab .comment-button .back {
    border: none;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s; }
    .pv-collab .comment-button .back i {
      margin-right: 10px; }
    .pv-collab .comment-button .back:focus {
      outline: none; }
    .pv-collab .comment-button .back:hover {
      opacity: .8; }

.pv-collab .side-bar {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end; }
  .pv-collab .side-bar .score {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 1.5rem; }
    .pv-collab .side-bar .score p {
      text-transform: uppercase;
      margin-right: 10px !important; }
    .pv-collab .side-bar .score .num {
      padding: 10px;
      font-size: 30px;
      background-color: #767676;
      color: #fff;
      text-align: center; }
  .pv-collab .side-bar .popular-tags,
  .pv-collab .side-bar .popular-theme {
    width: 180px; }
    .pv-collab .side-bar .popular-tags .tags a,
    .pv-collab .side-bar .popular-tags .theme a,
    .pv-collab .side-bar .popular-theme .tags a,
    .pv-collab .side-bar .popular-theme .theme a {
      margin: 10px 5px 0px 0px;
      display: inline-block;
      text-decoration: none;
      color: #4169a4;
      padding: 5px 15px;
      font-size: 13px;
      border: 1px solid #4169a4;
      border-radius: 3px;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s; }
      .pv-collab .side-bar .popular-tags .tags a:hover,
      .pv-collab .side-bar .popular-tags .theme a:hover,
      .pv-collab .side-bar .popular-theme .tags a:hover,
      .pv-collab .side-bar .popular-theme .theme a:hover {
        background-color: #4169a4;
        color: #fff; }
  .pv-collab .side-bar .primary-button {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none; }
    .pv-collab .side-bar .primary-button:hover {
      color: #fff; }

.pv-collab .popular-tags {
  margin-top: 30px; }

.pv-collab .popular-theme {
  margin: 30px 0; }

.pv-collab #links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 10px;
  height: 50px;
  width: 170px;
  margin-top: 5px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15); }
  .pv-collab #links a {
    display: block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    border-radius: 3px; }
    .pv-collab #links a i {
      color: #fff; }
    .pv-collab #links a:hover {
      opacity: .8; }
    .pv-collab #links a:not(:last-child) {
      margin-bottom: 10px; }
  .pv-collab #links .fb {
    background-color: #3B5998;
    margin-right: 10px; }
  .pv-collab #links .tw {
    background-color: #15B6E6;
    margin-right: 10px; }
  .pv-collab #links .in {
    background-color: #1592E6;
    margin-right: 10px; }
  .pv-collab #links .email {
    background-color: #F2CC1A; }

.pv-collab #links.hidden {
  display: none;
  overflow: hidden;
  width: 0%; }

@media (max-width: 991px) {
  .pv-collab .primary-button {
    width: 160px;
    height: 45px; }
  .pv-collab #btn-close-modal {
    margin-top: 20px; }
  .pv-collab .big-single-image {
    margin-top: 20px; }
  .pv-collab .side-bar {
    display: none; } }

@media (width: 768px) {
  .pv-collab h1 {
    font-size: 34px !important; } }

@media (max-width: 767px) {
  .pv-collab {
    /* MAIN PAGE */ }
    .pv-collab h2 {
      font-size: 30px !important;
      line-height: 30px !important; }
    .pv-collab .view-detail h3 {
      font-size: 20px !important; }
    .pv-collab p {
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 24px;
      letter-spacing: 0;
      margin: 0 !important; }
    .pv-collab .hero-section {
      padding: 40px 0; }
    .pv-collab .hero-left .challenge-content h2 {
      margin: 2.5rem 2.5rem 1rem; }
    .pv-collab .hero-right {
      display: none; }
    .pv-collab .big-button {
      display: block;
      width: 100% !important;
      margin-top: 30px; }
    .pv-collab .shortby-section {
      width: 100%; }
      .pv-collab .shortby-section .sb-right {
        display: none; }
      .pv-collab .shortby-section .shortby-nav {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%; }
    .pv-collab .card-section .row:nth-of-type(2) {
      margin-top: 0px; }
    .pv-collab .card {
      margin-bottom: 20px; }
    .pv-collab .heading-bar {
      margin-top: 0; }
    .pv-collab .s-heading {
      margin-top: 10px; }
    .pv-collab .idea-detail .container .card-body, .pv-collab .idea-detail .view-story .pv-three-column .card-body, .view-story .pv-collab .idea-detail .pv-three-column .card-body, .pv-collab .idea-detail .container .card-header, .pv-collab .idea-detail .view-story .pv-three-column .card-header, .view-story .pv-collab .idea-detail .pv-three-column .card-header {
      background-color: transparent !important; } }

@media (max-width: 575px) {
  .pv-collab {
    /* ADDIDEA */
    /* ADDIDEA */
    /* Single Page */ }
    .pv-collab .heading-bar {
      font-size: 12px;
      flex-wrap: wrap-reverse; }
    .pv-collab #ideas-page-body h1 {
      font-size: 30px !important; }
    .pv-collab #ideas-page-body h2 {
      font-size: 28px !important;
      line-height: 32px !important; }
    .pv-collab #ideas-page-body h3 {
      font-size: 20px !important; }
    .pv-collab #ideas-page-body p {
      line-height: 24px !important; }
    .pv-collab h1.challenge-title {
      font-size: 32px !important; }
    .pv-collab #btn-close-modal {
      width: 30px;
      height: 30px; }
      .pv-collab #btn-close-modal .closebt {
        width: 30px;
        height: 30px; }
    .pv-collab .button {
      width: 145px;
      height: 40px;
      line-height: 40px;
      font-weight: 400; }
    .pv-collab .form-group label, .pv-collab .view-contact body .control-group:not(.field-spacer) label, .view-contact body .pv-collab .control-group:not(.field-spacer) label {
      font-size: 16px; }
    .pv-collab .form-group input, .pv-collab .view-contact body .control-group:not(.field-spacer) input, .view-contact body .pv-collab .control-group:not(.field-spacer) input,
    .pv-collab .custom-select {
      height: 35px !important;
      font-size: 13px; }
    .pv-collab .form-group textarea, .pv-collab .view-contact body .control-group:not(.field-spacer) textarea, .view-contact body .pv-collab .control-group:not(.field-spacer) textarea {
      font-size: 13px; }
    .pv-collab .my-tagsinput {
      height: 35px !important;
      font-size: 13px;
      padding: 0px 10px; }
      .pv-collab .my-tagsinput .tag {
        padding: 3px 10px !important; }
    .pv-collab .cb-up-group {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start; }
    .pv-collab .anonymously_cb {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
    .pv-collab .control-fileupload {
      width: 100%;
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
      margin-bottom: 20px; }
    .pv-collab .nav-button {
      margin-top: 30px; }
    .pv-collab .heading-bar .theme-tag {
      font-size: 13px !important; }
    .pv-collab .heading-bar .author {
      font-size: 13px !important; }
    .pv-collab .heading-bar .date {
      font-size: 13px !important; }
    .pv-collab .nav-single-page button {
      margin-bottom: 10px; }
    .pv-collab .comment-section {
      margin: 20px 0; }
      .pv-collab .comment-section h3 {
        font-size: 20px !important; }
      .pv-collab .comment-section .mce-label {
        display: none; }
    .pv-collab .comment-head .author,
    .pv-collab .comment-head .date,
    .pv-collab .comment-head .comment-report {
      font-size: 13px; }
    .pv-collab .comment-body {
      margin-left: 40px; }
      .pv-collab .comment-body p {
        font-size: 13px !important;
        line-height: 20px !important; }
      .pv-collab .comment-body .comment-button {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .pv-collab .comment-body .comment-button button {
          border: none;
          cursor: pointer;
          -webkit-transition: all .2s;
          -o-transition: all .2s;
          transition: all .2s; }
          .pv-collab .comment-body .comment-button button i {
            color: #4169a4;
            margin-right: 5px; }
          .pv-collab .comment-body .comment-button button:nth-child(2) {
            margin-left: 20px; }
          .pv-collab .comment-body .comment-button button:focus {
            outline: none; }
          .pv-collab .comment-body .comment-button button:hover {
            opacity: .8; }
    .pv-collab .comment-button {
      font-size: 14px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start; }
      .pv-collab .comment-button .anonymously_cb {
        display: block;
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
        width: 100% !important;
        margin-bottom: 20px; }
      .pv-collab .comment-button .submit {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1; }
      .pv-collab .comment-button .back {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        margin-top: -30px; }
    .pv-collab .challenge-content {
      font-size: 16px !important; }
    .pv-collab #add-idea h3 {
      font-size: 1rem !important; } }

#system-message-container {
  margin: 1.5rem 1.5rem 1.5rem; }
  @media (max-width: 991.98px) {
    #system-message-container {
      margin: 10px 1.5rem 1.5rem; } }
  @media (max-width: 767.98px) {
    #system-message-container {
      margin: 0.5rem; } }

#system-message {
  text-align: center; }
  #system-message .alert {
    text-align: left;
    margin: 1.0rem 30%; }
    #system-message .alert-heading {
      color: #0b172a; }
    #system-message .alert-message {
      color: #201ec4;
      font-size: 16px;
      background: #f0f9ff; }
    #system-message .alert-error, #system-message .alert-warning {
      color: red; }
    @media (max-width: 991.98px) {
      #system-message .alert {
        margin: 1rem 1.5rem; } }
    @media (max-width: 767.98px) {
      #system-message .alert {
        margin: 1rem; } }

.shr-pg .bitly:before, .shr-pg .blogger:before, .shr-pg .delicious:before, .shr-pg .digg:before, .shr-pg .diigo:before, .shr-pg .facebook:before, .shr-pg .feed:before, .shr-pg .gmail:before, .shr-pg .googleplus:before, .shr-pg .linkedin:before, .shr-pg .myspace:before, .shr-pg .pinterest:before, .shr-pg .reddit:before, .shr-pg .stumbleupon:before, .shr-pg .tumblr:before, .shr-pg .twitter:before, .shr-pg .yahoomail:before {
  background: url("../images/sprites_share.png") no-repeat; }

.shr-pg .bitly:before {
  background-position: 0 0; }

.shr-pg .blogger:before {
  background-position: 0 -32px; }

.shr-pg .delicious:before {
  background-position: 0 -64px; }

.shr-pg .digg:before {
  background-position: 0 -96px; }

.shr-pg .diigo:before {
  background-position: 0 -128px; }

.shr-pg .facebook:before {
  background-position: 0 -160px; }

.shr-pg .feed:before {
  background-position: 0 -192px; }

.shr-pg .gmail:before {
  background-position: 0 -224px; }

.shr-pg .googleplus:before {
  background-position: 0 -256px; }

.shr-pg .linkedin:before {
  background-position: 0 -288px; }

.shr-pg .myspace:before {
  background-position: 0 -320px; }

.shr-pg .pinterest:before {
  background-position: 0 -352px; }

.shr-pg .reddit:before {
  background-position: 0 -384px; }

.shr-pg .stumbleupon:before {
  background-position: 0 -416px; }

.shr-pg .tumblr:before {
  background-position: 0 -448px; }

.shr-pg .twitter:before {
  background-position: 0 -480px; }

.shr-pg .yahoomail:before {
  background-position: 0 -512px; }

.wb-share {
  display: inline-block; }

.wb-inv {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute;
  width: 1px; }

.shr-pg .email::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0e0" !important;
  font-size: 32px;
  margin-right: .3em; }

.shr-pg .shr-lnk {
  font-size: 115%;
  line-height: 32px;
  margin-bottom: 8px;
  min-height: 32px;
  text-align: left;
  text-decoration: none;
  width: 100%; }
  .shr-pg .shr-lnk:before {
    content: " ";
    display: inline-block;
    height: 32px;
    margin-right: .6em;
    vertical-align: middle;
    width: 32px; }
  .shr-pg .shr-lnk .facebook:before {
    background-image: url(../../wet-boew/assets/sprites_share.png);
    background-position: -64px 0;
    width: 32px;
    height: 32px; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 21-Feb-2018, 2:52:35 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
/*.com_publivateideamodule.view-ideas.itemid-341{ // Ideas page

	.ph-home-container{

	  max-width: 1380px;
	  margin: 0 auto;
	}

	#ideas-page-body{
	  max-width: 100%;
	  padding: 0;
	}



}*/
/*
.ideas {
	background-color: gray('100');


	>[class^="col-"]{
		padding-left: 5px;
		padding-right: 5px;
	}
	.idea-detail{
		background-color: #fff;
		border-color: $pv-blue;
		@extend .border;
		@extend .clearfix;

		.idea-participant-row .btn-participant{
			border-radius: 0;
		}

	}// End idea-detail
}

.idea-detail {

	.btn{
		background-color: transparent !important;
	}
	header.idea-header {
		@extend .ml-3;
	}

	.idea-tags, .idea-description , .idea-stats-abuse, .idea-buttons, .improvements {
		@extend .mx-3;
	}

	>.row, .idea-buttons, .improvements{
		@extend .mb-3;
	}

	.idea-score {
		color: $white;
		text-align: center;
		padding: 0 0 1rem 0.5rem;

		.idea-score-inner {
			background-color: $idea-score-inner-bg;
		}
		.idea-score-text{
			font-weight: 700;
			font-size: $idea-score-font-size;
			padding-top: 0.5rem;
		}

		.idea-score-value{
			font-size: $idea-score-value-font-size;
		}
	}

	.idea-rating-ajax{
		display: none;
		@extend .pl-3;
	}
	.row.rating-form, .improvement-replies {
		@extend .my-3;
	}

	.improvements{
		.comment {
			@extend .mb-3;
		}
	}
}
*/
.mod-ofg-challenge.hero-section {
  background: none !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3); }
  .mod-ofg-challenge.hero-section h1 {
    color: #000; }

.ofg .card-block {
  height: initial; }
  .ofg .card-block .card-button {
    position: static; }

article.item-page ul, article.item-page ol {
  margin-left: 2rem; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 16-Feb-2018, 10:14:00 AM
    Author     : James Hansen <jhansen@publivate.com>
*/
#pv-header {
  background-color: transparent; }

#registrationTable {
  margin-top: 58px; }

/* ========== HEADER TOP ========== */
.header-top {
  background-image: url("../img/header-top.png");
  height: 200px;
  background-position: center;
  background-size: cover; }

nav.navbar {
  background-color: #fafdff !important;
  transition: 0.3s; }

/* ========== HEADER PAGES ========== */
.pages {
  display: flex;
  flex-direction: row;
  margin-top: 20px; }
  .pages .content-left h1 {
    margin-bottom: 20px; }
  .pages .content-left img {
    margin-bottom: 20px; }
  .pages .content-left .h-paragraph {
    display: block;
    max-width: 500px; }
    .pages .content-left .h-paragraph #text {
      display: none; }
  .pages .content-left .m-paragraph {
    display: none;
    position: relative; }
    .pages .content-left .m-paragraph #m-text {
      display: none; }
    .pages .content-left .m-paragraph a {
      display: inline-block;
      color: #000;
      font-size: 20px;
      margin-left: 5px;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      transition: all .3s; }
      .pages .content-left .m-paragraph a i:hover {
        text-decoration: none;
        color: #4169a4; }
  .pages .content-left .content-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px; }
    .pages .content-left .content-btn .dr-jack {
      font-style: italic; }
  .pages .content-right {
    border-left: 1px solid rgba(0, 0, 0, 0.2); }
    .pages .content-right h1 {
      margin-bottom: 20px; }
    .pages .content-right p {
      margin-bottom: 10px; }
    .pages .content-right h5 {
      font-size: 20px !important;
      font-weight: 600;
      margin-bottom: 30px;
      line-height: 20px; }
    .pages .content-right .survey {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .pages .content-right .survey .survey-heading {
        display: block;
        width: 240px;
        font-size: 24px !important; }
      .pages .content-right .survey .task-survey ul {
        list-style: none; }
        .pages .content-right .survey .task-survey ul li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          width: 220px;
          height: 40px;
          line-height: 40px;
          border-radius: 100px;
          -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2); }
          .pages .content-right .survey .task-survey ul li:not(:last-child) {
            margin-bottom: 10px; }
          .pages .content-right .survey .task-survey ul li input[type=radio] {
            position: absolute;
            visibility: hidden; }
          .pages .content-right .survey .task-survey ul li label {
            display: block;
            position: relative;
            margin-left: 20px;
            height: 40px;
            width: 100%;
            line-height: 50px;
            cursor: pointer;
            z-index: 9;
            -webkit-transition: all 0.25s linear;
            -moz-transition: all 0.25s linear;
            transition: all 0.25s linear; }
          .pages .content-right .survey .task-survey ul li:hover label {
            color: #4169a4; }
          .pages .content-right .survey .task-survey ul li .check {
            display: block;
            position: absolute;
            border: 2px solid #7f8c8d;
            border-radius: 100%;
            height: 20px;
            width: 20px;
            right: 20px;
            -webkit-transition: border 0.25s linear;
            -moz-transition: border 0.25s linear;
            transition: border 0.25s linear; }
            .pages .content-right .survey .task-survey ul li .check::before {
              display: block;
              position: absolute;
              content: '';
              border-radius: 100%;
              height: 10px;
              width: 10px;
              margin: auto;
              top: 2px;
              left: 2px;
              -webkit-transition: background 0.25s linear;
              -moz-transition: background 0.25s linear;
              transition: background 0.25s linear; }
          .pages .content-right .survey .task-survey ul li:hover .check {
            border: 3px solid #4169a4; }
          .pages .content-right .survey .task-survey ul li input[type=radio]:checked ~ .check {
            border: 3px solid #4169a4; }
          .pages .content-right .survey .task-survey ul li input[type=radio]:checked ~ label {
            color: #4169a4; }
          .pages .content-right .survey .task-survey ul li input[type=radio]:checked ~ .check::before {
            background: #4169a4; }
      .pages .content-right .survey .task-survey .survey-btn {
        outline: none;
        cursor: pointer;
        float: right;
        border: none;
        color: #4169a4;
        transition: all .3s;
        font-size: 18px;
        margin-top: 10px; }
        .pages .content-right .survey .task-survey .survey-btn i {
          font-size: 20px;
          -webkit-transition: all .3s;
          -moz-transition: all .3s;
          transition: all .3s; }
          .pages .content-right .survey .task-survey .survey-btn i:hover {
            margin-left: 5px; }

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .pages .content-left img {
    width: 100%; }
  .pages .content-left .h-paragraph {
    max-width: 100%; } }

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .header-top {
    background-image: url("../img/t-head-small.png");
    height: 90px;
    background-position: center;
    background-size: cover; }
  .pages .content-left,
  .pages .content-right {
    margin-bottom: 15px;
    margin-top: 15px; }
  .pages .content-left h1 {
    margin-bottom: 10px; }
  .pages .content-left img {
    display: none; }
  .pages .content-left .h-paragraph {
    display: none; }
  .pages .content-left .m-paragraph {
    display: block; }
  .pages .content-left .primary-btn {
    display: none; }
  .pages .content-left .content-btn {
    justify-content: flex-end; }
  .pages .content-right .survey-heading {
    width: 100%;
    margin-bottom: 10px; }
  .pages .content-right .survey {
    align-items: flex-start;
    flex-direction: column; }
    .pages .content-right .survey .task-survey ul li {
      display: inline-block;
      width: 100%;
      height: 40px; }
      .pages .content-right .survey .task-survey ul li label {
        line-height: 40px; }
      .pages .content-right .survey .task-survey ul li .check {
        line-height: 40px;
        top: 10px; }
    .pages .content-right .survey .task-survey .survey-btn {
      display: block;
      float: none;
      margin: 0 auto; } }

@media only screen and (max-width: 575px) {
  .pages .content-left .content-btn {
    margin-top: 10px; }
  .pages .content-right {
    padding: 20px 15px; }
    .pages .content-right h5 {
      font-size: 16px !important;
      font-weight: 600 !important;
      margin-bottom: 20px;
      line-height: 16px; }
    .pages .content-right .survey-heading {
      font-size: 20px !important;
      font-weight: bold;
      margin-bottom: 20px; }
    .pages .content-right label {
      font-size: 14px !important; } }

/* Extra Small Devices, Phones */
.navbar-nav .nav-item.active a {
  color: #006fCf; }

/* Styling for all menu items can be added here  */
/* ========== MAIN NAV ========== */
.main-nav,
.menu {
  display: flex;
  list-style: none; }
  .main-nav li.active.current a, .main-nav li.active.parent a,
  .menu li.active.current a,
  .menu li.active.parent a {
    color: white;
    padding: 1rem;
    border-top: 2px solid #00529C;
    border-bottom: 2px solid #00529C; }

/* Likely temporary fix until a challenge such as in the link can be a child menu item: https://hospital.publivate.ca/hip-arthroplasty */
html.itemid-362 #navTop .item-376,
html.itemid-361 #navTop .item-376 {
  border-top: 2px solid #0064b4;
  border-bottom: 2px solid #0064b4; }

.com_publivateideamodule.view-ideas.layout-ofg.j39.no-touch #navTop .item-378,
.com_publivateideamodule.view-detail.layout-ofg.j39.no-touch #navTop .item-378 {
  border-top: 2px solid #0064b4;
  border-bottom: 2px solid #0064b4; }

.main-nav {
  position: relative;
  z-index: 5; }
  .main-nav li {
    margin-right: 20px; }
    .main-nav li a {
      font-size: 18px;
      color: white;
      line-height: 45px;
      font-weight: 600;
      white-space: nowrap;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      transition: all .3s; }
      .main-nav li a:hover {
        text-decoration: none;
        border-bottom: 3px solid #ccc; }
  .main-nav .login-button {
    color: #4169a4;
    margin-right: 30px;
    line-height: 45px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s; }
    .main-nav .login-button:hover {
      text-decoration: none; }
  .main-nav .register-button {
    display: inline-block;
    color: #4169a4;
    width: 150px;
    height: 45px;
    border-radius: 100px;
    border: 2px solid #4169a4;
    text-align: center;
    line-height: 41px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s; }
    .main-nav .register-button:hover {
      background-color: #4169a4;
      color: #fff !important;
      text-decoration: none; }

/* ========== MOBILE HAMBURGER ICON ========== */
.hamburger {
  display: none;
  float: left;
  cursor: pointer;
  margin-right: 30px; }

.hamburger .h-icon {
  display: block;
  width: 35px;
  height: 5px;
  background-color: #4169a4;
  margin: 6px 0; }

/* ========== MOBILE NAV ========== */
.side-menu-overlay {
  /* overlay */
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  opacity: 0;
  transition: opacity 1s; }

.side-menu-wrapper {
  overflow: hidden;
  background: #4169A4;
  padding: 40px 0 0 40px;
  position: fixed;
  top: 0px;
  height: 100%;
  width: 250px;
  right: -290px;
  z-index: 1000;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s; }
  .side-menu-wrapper ul {
    padding: 0;
    margin: 0;
    overflow-y: auto;
    width: 500px;
    height: 95%; }
    .side-menu-wrapper ul li {
      padding: 10px 0; }
      .side-menu-wrapper ul li a {
        color: #fff !important;
        display: block;
        padding: 6px 4px 6px 4px;
        text-decoration: none;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s; }
        .side-menu-wrapper ul li a:hover {
          color: #000 !important; }

.side-menu-wrapper > a.menu-close {
  /* close button */
  padding: 8px 0 4px 23px;
  color: #fff;
  display: block;
  margin: -30px 0 -10px -20px;
  font-size: 35px;
  text-decoration: none;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s; }
  .side-menu-wrapper > a.menu-close:hover {
    opacity: .8; }

@media (max-width: 991px) {
  .itemid-341 #pv-header #navTop a,
  .itemid-341 #pv-header #navPublivateContent a {
    color: black !important; } }

/* Custom, iPhone Retina */
@media (max-width: 767.98px) {
  .hamburger {
    margin-right: 20px; }
  .main-nav .login-button {
    display: none; }
  .main-nav .register-button {
    display: none; }
  .main-nav .menu {
    margin-top: 10px; }
    .main-nav .menu li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      width: 100%;
      text-align: center; } }

@media (max-width: 575.98px) {
  /*
	.header-logo {
		img {
			display: block!important;
		}
	}
	*/ }

/* Patient Hub version 2 stylings */
.com_publivate.view-story.layout-single.itemid-343.j39.no-touch #pv-header,
.com_publivateideamodule.view-detail.layout-49.itemid-341.j39.no-touch #pv-header,
.com_publivateideamodule.view-detail.layout-48.itemid-341.j39.no-touch #pv-header,
.com_publivateideamodule.view-detail.itemid-341.j39.no-touch #pv-header,
.com_publivate.view-home.itemid-334.j39.no-touch #pv-header {
  transition: 0.3s; }

.com_publivate.view-story.layout-single.itemid-343.j39.no-touch .main-nav,
.com_publivate.view-story.layout-single.itemid-343.j39.no-touch .menu,
.com_publivateideamodule.view-detail.layout-49.itemid-341.j39.no-touch .main-nav,
.com_publivateideamodule.view-detail.layout-49.itemid-341.j39.no-touch .menu,
.com_publivateideamodule.view-detail.layout-48.itemid-341.j39.no-touch .main-nav,
.com_publivateideamodule.view-detail.layout-48.itemid-341.j39.no-touch .menu,
.com_publivateideamodule.view-detail.itemid-341.j39.no-touch .main-nav,
.com_publivateideamodule.view-detail.itemid-341.j39.no-touch .menu,
.com_publivate.view-home.itemid-334.j39.no-touch .main-nav,
.com_publivate.view-home.itemid-334.j39.no-touch .menu {
  display: flex;
  list-style: none; }
  .com_publivate.view-story.layout-single.itemid-343.j39.no-touch .main-nav li.active.current a, .com_publivate.view-story.layout-single.itemid-343.j39.no-touch .main-nav li.active.parent a,
  .com_publivate.view-story.layout-single.itemid-343.j39.no-touch .menu li.active.current a,
  .com_publivate.view-story.layout-single.itemid-343.j39.no-touch .menu li.active.parent a,
  .com_publivateideamodule.view-detail.layout-49.itemid-341.j39.no-touch .main-nav li.active.current a,
  .com_publivateideamodule.view-detail.layout-49.itemid-341.j39.no-touch .main-nav li.active.parent a,
  .com_publivateideamodule.view-detail.layout-49.itemid-341.j39.no-touch .menu li.active.current a,
  .com_publivateideamodule.view-detail.layout-49.itemid-341.j39.no-touch .menu li.active.parent a,
  .com_publivateideamodule.view-detail.layout-48.itemid-341.j39.no-touch .main-nav li.active.current a,
  .com_publivateideamodule.view-detail.layout-48.itemid-341.j39.no-touch .main-nav li.active.parent a,
  .com_publivateideamodule.view-detail.layout-48.itemid-341.j39.no-touch .menu li.active.current a,
  .com_publivateideamodule.view-detail.layout-48.itemid-341.j39.no-touch .menu li.active.parent a,
  .com_publivateideamodule.view-detail.itemid-341.j39.no-touch .main-nav li.active.current a,
  .com_publivateideamodule.view-detail.itemid-341.j39.no-touch .main-nav li.active.parent a,
  .com_publivateideamodule.view-detail.itemid-341.j39.no-touch .menu li.active.current a,
  .com_publivateideamodule.view-detail.itemid-341.j39.no-touch .menu li.active.parent a,
  .com_publivate.view-home.itemid-334.j39.no-touch .main-nav li.active.current a,
  .com_publivate.view-home.itemid-334.j39.no-touch .main-nav li.active.parent a,
  .com_publivate.view-home.itemid-334.j39.no-touch .menu li.active.current a,
  .com_publivate.view-home.itemid-334.j39.no-touch .menu li.active.parent a {
    color: black; }

.com_publivate.view-story.layout-single.itemid-343.j39.no-touch .main-nav li a,
.com_publivateideamodule.view-detail.layout-49.itemid-341.j39.no-touch .main-nav li a,
.com_publivateideamodule.view-detail.layout-48.itemid-341.j39.no-touch .main-nav li a,
.com_publivateideamodule.view-detail.itemid-341.j39.no-touch .main-nav li a,
.com_publivate.view-home.itemid-334.j39.no-touch .main-nav li a {
  color: black; }
  .com_publivate.view-story.layout-single.itemid-343.j39.no-touch .main-nav li a:hover,
  .com_publivateideamodule.view-detail.layout-49.itemid-341.j39.no-touch .main-nav li a:hover,
  .com_publivateideamodule.view-detail.layout-48.itemid-341.j39.no-touch .main-nav li a:hover,
  .com_publivateideamodule.view-detail.itemid-341.j39.no-touch .main-nav li a:hover,
  .com_publivate.view-home.itemid-334.j39.no-touch .main-nav li a:hover {
    text-decoration: none;
    border-bottom: 3px solid #ccc; }


.card-img-top,
.pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img {
  object-fit: cover; }

.card-header .h1, .card-header .h2, .card-header .h3, .card-header .h4, .card-header .normal-list, .card-header .h5, .card-header .h6,
.card-header h1, .card-header h2, .card-header h3, .card-header h4, .card-header h5, .card-header h6 {
  margin: 0; }

.card-header p {
  margin-bottom: 0; }

.card-header .col .h3.action,
.card-header .col h3.action {
  font-weight: 700 !important; }

.card {
  overflow: hidden;
  border-radius: 5px !important;
  box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
  transition: .3s;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .card .card {
    box-shadow: 0 0 0 rgba(203, 198, 195, 0); }
  .card .order-before {
    order: -1; }

.card:hover {
  box-shadow: 5px 5px 25px rgba(203, 198, 195, 0.5); }
  .card:hover .card {
    box-shadow: 0 0 0 rgba(203, 198, 195, 0); }
  .card:hover .story-description {
    max-height: 800px; }

.card, .card-header, .card-body, .card-footer {
  background-color: #FFF !important;
  border-width: 0 !important; }
  .card .embed-survey, .card-header .embed-survey, .card-body .embed-survey, .card-footer .embed-survey {
    width: 100%;
    padding: 0em; }
    .card .embed-survey iframe, .card-header .embed-survey iframe, .card-body .embed-survey iframe, .card-footer .embed-survey iframe {
      width: 100%; }

.com_publivateideamodule.view-detail .card-img-overlay {
  width: 11em !important;
  height: 5.5em !important; }

/*footer{
	position: relative;
	height: auto;
	background-position: center;
	background-size: cover;
	z-index: 99;
	

}

!* Small Devices, Tablets *!
@media only screen and (max-width : 768px){
	footer{
		background-position: center;
		background-size: cover;
		height: 90px;
	}

}*/
footer .container, footer .view-story .pv-three-column, .view-story footer .pv-three-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 1rem; }

footer {
  position: relative;
  font-size: 1.3rem;
  background-color: #707070;
  padding: 3rem 0;
  color: #fff; }
  footer:before {
    display: block;
    position: absolute;
    top: 0;
    content: '';
    width: 100%;
    height: 1rem; }
  footer .nav .nav-item:not(:last-child) {
    margin-right: 2rem; }
  footer .nav .nav-item .nav-link, footer .nav .navbar-nav .nav-item span, .navbar-nav footer .nav .nav-item span,
  footer .nav .navbar-nav .nav-item a, .navbar-nav footer .nav .nav-item a {
    color: #fff;
    padding: 0 !important;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
    footer .nav .nav-item .nav-link:hover, footer .nav .navbar-nav .nav-item span:hover, .navbar-nav footer .nav .nav-item span:hover,
    footer .nav .navbar-nav .nav-item a:hover, .navbar-nav footer .nav .nav-item a:hover {
      color: #28a745; }

@media (max-width: 991px) {
  footer .container, footer .view-story .pv-three-column, .view-story footer .pv-three-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  footer {
    width: 100%;
    height: auto;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem 0; }
    footer .copyright {
      margin-top: 1rem; } }

@media (max-width: 576px) {
  footer .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    footer .nav .nav-item:not(:last-child) {
      margin-right: 10px; } }

.view-contact body {
  background-color: #53CEFC !important; }
  .view-contact body .page-header {
    display: none; }
  .view-contact body .control-group:not(.field-spacer) {
    display: inline-grid; }
    .view-contact body .control-group:not(.field-spacer) .controls, .view-contact body .control-group:not(.field-spacer) .control-label {
      display: inline-block; }
    .view-contact body .control-group:not(.field-spacer) .control-label {
      text-align: left; }
    .view-contact body .control-group:not(.field-spacer):last-of-type {
      display: block;
      margin: 1.25rem auto; }
      .view-contact body .control-group:not(.field-spacer):last-of-type .control-label {
        display: block; }
      @media (min-width: 1100px) {
        .view-contact body .control-group:not(.field-spacer):last-of-type:last-of-type .control-label {
          white-space: nowrap; } }
  @media (max-width: 575.98px) {
    .view-contact body .contact-form .submit-form {
      width: 100% !important; } }
  .view-contact body .contact-form img {
    max-width: 22vw;
    display: block;
    margin: 2.25rem auto; }
  .view-contact body .contact-form span.fa {
    color: #fff; }
  .view-contact body .contact-form #mycalendar-style-override-target .control-group {
    max-width: 100%; }
    .view-contact body .contact-form #mycalendar-style-override-target .control-group .control-label {
      text-align: center; }

/* Components and Pages */
/* Styling for all modules in the template can be added here*/
.main-top .moduletable {
  width: 100%; }
  .main-top .moduletable .mod-challenge {
    padding: 3rem 0 0; }
    .main-top .moduletable .mod-challenge .time-remaining {
      margin: 0 auto; }
    .main-top .moduletable .mod-challenge .countdown_row {
      margin: 0 auto; }

.moduletable-rnd.moduletable-rnd, .moduletable-rnd.rounded-border,
.module.moduletable-rnd,
.module.rounded-border,
.moduletable.moduletable-rnd,
.moduletable.rounded-border {
  border: 2px solid #55acee;
  border-radius: 10px; }

div.moduletable {
  padding-bottom: 15px; }

div.moduletable.rounded {
  border: 1px solid #0011a2; }

.moduletable-rnd,
div.rounded-border {
  border: 2px solid #55acee;
  border-radius: 10px; }

div.icon {
  padding-right: 0;
  padding-left: 5px; }

div.mod_ccstream ul#ticker {
  height: 400px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  list-style: none; }

div.mod_ccstream ul#ticker li {
  padding: 20px;
  display: block;
  background: #EDF6FD;
  color: #333;
  border-bottom: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  font-size: 13px; }

div.mod_ccstream .add-idea, div.mod_ccstream .add-idea:hover {
  color: var(--white);
  text-decoration: none; }

div.mod_ccstream .add-idea:hover {
  border-bottom: 1px solid var(--white); }

div.mod-publivate-story .stream-button {
  color: var(--white);
  text-decoration: none; }

div.mod-publivate-story .mod-stories-author {
  color: var(--red); }

ul.totemticker {
  height: 400px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  list-style: none; }
  ul.totemticker li {
    padding: 20px;
    display: block;
    background: #EDF6FD;
    color: #333;
    border-bottom: 1px solid #ddd;
    border-radius: 10px;
    text-align: left;
    font-size: 13px; }

.stream-title, .stream-button {
  text-align: center;
  padding: 10px 0 10px;
  color: var(--orange);
  font-weight: bold;
  font-size: 20px; }

.stream-button {
  border-top: 3px solid #55acee;
  margin-bottom: 0;
  background-color: #55acee; }

div.mod-challenge {
  padding: 20px; }

.mod-challenge .countdown_section .countdown_amount {
  color: #707070 !important; }

.normal-idea .card-header h5.text-secondary {
  visibility: hidden; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 15-Feb-2018, 10:10:18 AM
    Author     : James Hansen <jhansen@publivate.com>
*/
.cbLoginForm {
  line-height: 1;
  max-width: 400px;
  margin: 0 auto; }
  .cbLoginForm .btn {
    width: 100%; }
  .cbLoginForm #form-login-remember input {
    width: auto;
    display: inline-block; }
  .cbLoginForm #form-login-remember label {
    float: left;
    margin-right: 1rem; }
  .cbLoginForm .cbLogoutButtonSpan {
    display: block; }
  .cbLoginForm ul.unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 30px; }
  .cbLoginForm ul.unstyled li:first-child {
    margin-bottom: 5rem; }
  .cbLoginForm ul.unstyled a.btn {
    text-decoration: underline; }
  .cbLoginForm ul.unstyled a.btn:hover {
    text-decoration: none; }
  .cbLoginForm #form-login-forgot {
    float: left; }
  .cbLoginForm #form-login-register {
    float: right; }

.cbLoginForm .cbLoginForgotButtonSpan {
  margin-top: 0; }

.cbLoginForm-mobile.cbLoginForm .cbLoginButtonSpan {
  display: inline-block !important;
  margin-left: 2rem; }

/** LOGIN PAGE **/
.com_comprofiler #cb_cb_comp_login .h1 {
  font-size: 2.3rem; }

.com_comprofiler .cb_comp_inner {
  text-align: center; }

.com_comprofiler .cbLoginForm {
  margin: 0 auto !important; }

/* Cb Registration and Profile */
.cbIconsTop {
  text-align: center;
  width: 70%; }

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 16-Feb-2018, 2:27:58 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
#my-tools {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  #my-tools .center,
  #my-tools .table td.center,
  #my-tools .table th.center {
    text-align: center !important; }
  #my-tools .table th.right,
  #my-tools .table td.right {
    text-align: right; }
  #my-tools .table th.left,
  #my-tools .table td.left {
    text-align: left; }
  #my-tools #my-tools-nav {
    background: #fff;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
    flex: 0 0 18.75%;
    max-width: 18.75%; }
  #my-tools .angular-view-container {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
    flex: 0 0 81.25%;
    max-width: 81.25%; }
  #my-tools #my-tools-nav .navbar {
    padding: 0; }
  #my-tools #my-tools-nav ul.sm,
  #my-tools #my-tools-nav .navbar {
    background: #fff;
    border: none; }
  #my-tools #my-tools-nav .navbar-header {
    float: none; }
  #my-tools #my-tools-nav .navbar-collapse {
    padding: 0; }
  #my-tools #my-tools-nav .navbar-toggle {
    background: #f8f8f8; }
  #my-tools #my-tools-nav .navbar-toggle .icon-bar {
    background: black; }
  #my-tools #my-tools-nav.sidebar-nav button.btn-link:active,
  #my-tools #my-tools-nav.sidebar-nav button.btn-link:focus,
  #my-tools #my-tools-nav.sidebar-nav button.btn-link:hover {
    color: #888; }

/*
    Created on : 19-Apr-2018, 2:41:42 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
.stories-home-page h1 {
  margin-bottom: 30px; }

.stories-home-page .card {
  height: 439px;
  position: relative;
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px; }
  .stories-home-page .card .card-img-top, .stories-home-page .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img, .pv-stories-wrapper .section-card #my-stories .row .inner .stories-home-page .card a.vimeo-popup img {
    height: 149px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .stories-home-page .card .card-img-top:hover, .stories-home-page .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img:hover, .pv-stories-wrapper .section-card #my-stories .row .inner .stories-home-page .card a.vimeo-popup img:hover {
      opacity: 0.9; }
    .stories-home-page .card .card-img-top img.thumbnail, .stories-home-page .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img img.thumbnail, .pv-stories-wrapper .section-card #my-stories .row .inner .stories-home-page .card a.vimeo-popup img img.thumbnail {
      height: 149px;
      width: 100%; }
    .stories-home-page .card .card-img-top img.play-btn, .stories-home-page .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img img.play-btn, .pv-stories-wrapper .section-card #my-stories .row .inner .stories-home-page .card a.vimeo-popup img img.play-btn {
      position: absolute;
      height: 4vh;
      top: 15%;
      left: 45%;
      cursor: pointer; }
  .stories-home-page .card .card-body {
    padding: 10px !important; }
  .stories-home-page .card .card-text {
    height: 150px;
    overflow: hidden; }
  .stories-home-page .card .card-admin {
    font-size: 11px;
    min-height: 33px; }
    .stories-home-page .card .card-admin a {
      color: #4169a4;
      font-weight: 600; }
      .stories-home-page .card .card-admin a:hover {
        text-decoration: none;
        opacity: 0.8; }
  .stories-home-page .card .card-title {
    font-size: 24px;
    margin-top: 5px;
    min-height: 60px;
    max-height: 65px;
    overflow: hidden;
    position: relative;
    color: #000; }
    .stories-home-page .card .card-title:hover {
      color: #4169a4; }
  .stories-home-page .card .card-btn {
    display: block;
    position: absolute;
    width: 120px;
    height: 35px;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid #4169a4;
    border-radius: 100px;
    cursor: pointer;
    text-align: center;
    line-height: 31px;
    color: #4169a4 !important;
    font-size: 13px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s; }
    .stories-home-page .card .card-btn:hover {
      text-decoration: none;
      background-color: #4169a4 !important;
      color: #fff !important; }
  .stories-home-page .card .modal {
    z-index: 99999; }
    .stories-home-page .card .modal .modal-body .card-admin {
      margin-bottom: 10px;
      font-size: 14px; }
    .stories-home-page .card .modal .modal-footer .btn {
      color: #000; }
      .stories-home-page .card .modal .modal-footer .btn:hover {
        color: #4169a4; }

.stories-home-page .story-btn {
  display: block;
  width: 200px;
  height: 50px;
  background-color: #4169A4;
  color: #fff;
  border-radius: 100px;
  text-align: center;
  line-height: 50px;
  margin-top: 30px;
  margin: 0 auto;
  z-index: 999;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s; }
  .stories-home-page .story-btn:hover {
    color: #fff !important;
    text-decoration: none;
    opacity: .9; }

/* Medium Devices, Desktops */
/* Small Devices, Tablets */
@media only screen and (max-width: 575px) {
  .stories,
  .stories-home-page {
    margin-top: 40px; }
    .stories .card,
    .stories-home-page .card {
      height: 380px; }
      .stories .card .card-img-top, .stories .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img, .pv-stories-wrapper .section-card #my-stories .row .inner .stories .card a.vimeo-popup img, .stories-home-page .card .card-img-top, .stories-home-page .pv-stories-wrapper .section-card #my-stories .row .inner .card a.vimeo-popup img, .pv-stories-wrapper .section-card #my-stories .row .inner .stories-home-page .card a.vimeo-popup img {
        height: 200px; }
      .stories .card img.thumbnail,
      .stories-home-page .card img.thumbnail {
        height: 200px;
        width: auto;
        object-fit: cover; }
      .stories .card .card-body,
      .stories-home-page .card .card-body {
        text-align: center; }
        .stories .card .card-body p,
        .stories-home-page .card .card-body p {
          display: none; } }

.employee-hub .stories-home-page .card .card-btn:hover {
  background-color: #925A3D !important; }

/* Extra Small Devices, Phones */
@media (max-width: 575.98px) {
  .stories-home-page .stories-homepage-card .row:nth-child(2) {
    display: none; } }

/* iPhone Retina */
.bottom-article {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 60px;
  background-color: #F3F3F3; }
  .bottom-article h1 {
    margin-top: 80px;
    margin-bottom: 20px; }
  .bottom-article img {
    height: 240px;
    top: -100px; }
  .bottom-article p:last-child {
    margin-bottom: 80px; }
  .bottom-article .container, .bottom-article .view-story .pv-three-column, .view-story .bottom-article .pv-three-column {
    background-color: inherit; }

/* Small Devices, Tablets */
@media (max-width: 767.98px) {
  .bottom-article {
    margin-top: 20px; }
    .bottom-article h1 {
      margin-top: 40px; }
    .bottom-article p:last-child {
      margin-top: 40px; } }

@media (max-width: 575.98px) {
  .bottom-article img {
    display: none; } }

.pfac .row:not(:last-child) {
  margin-bottom: 3rem !important; }

.pfac .pfac-menus h5 {
  margin-bottom: 2.1rem;
  font-weight: 600; }

.pfac .pfac-menus div {
  padding: 5px 10px;
  -webkit-box-shadow: 0 0 5px rgba(63, 63, 63, 0.1);
  box-shadow: 0 0 5px rgba(63, 63, 63, 0.1);
  transition: all .3s ease; }
  .pfac .pfac-menus div:hover {
    transform: scale(1.03); }

@media (max-width: 991.98px) {
  .pfac .pfac-menus {
    margin-bottom: 1.2rem; } }

.pfac .borders {
  padding: 5px 10px;
  -webkit-box-shadow: 0 0 5px rgba(63, 63, 63, 0.1);
  box-shadow: 0 0 5px rgba(63, 63, 63, 0.1);
  transition: all .3s ease; }

.pfac .pfac-challenges {
  margin-top: 2.5rem; }
  .pfac .pfac-challenges .challenge-item {
    padding: 3rem;
    height: 300px; }

.pfac .pfac-icons {
  max-height: 12vh;
  margin: 0 auto;
  display: block; }

.pfac img.icon-image {
  max-height: 12vh; }

.pfac .eg-ht {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox; }

.itemid-332 .welcome-section h1 {
  line-height: 120% !important;
  font-size: 2em !important; }

/** Commponent Template component.php **/
.component body {
  /* background: $white; */
  background: #ddd; }

.ajax-response {
  display: none; }

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto; }

.mfp-content .white-popup-block {
  background: #FFF;
  padding: 20px 30px;
  max-width: 80%;
  text-align: initial;
  margin: 40px auto;
  position: relative; }
  @media (max-width: 991.98px) {
    .mfp-content .white-popup-block {
      max-width: 90%; } }
  @media (max-width: 767.98px) {
    .mfp-content .white-popup-block {
      max-width: 90%; } }
  @media (max-width: 575.98px) {
    .mfp-content .white-popup-block {
      max-width: 100%; } }
