/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 16-Feb-2018, 2:27:58 PM
    Author     : James Hansen <jhansen@publivate.com>
*/

$grid-gutter-width: 10px !default;

#my-tools{
	@include make-row();

	// Tables
	.center,
	.table td.center,
	.table th.center {
		text-align: center !important;
	}

	.table th.right,
	.table td.right {
		text-align: right;
	}

	.table th.left,
	.table td.left {
		text-align: left;
	}

	#my-tools-nav{
		background: #fff;
		@include make-my-col-ready(10px);
		@include make-col(2.25);
	}

	.angular-view-container {
		@include make-my-col-ready(10px);
		@include make-col(9.75);
		@extend .pr-3;
	}

	#my-tools-nav .navbar{
		padding: 0;
	}

	#my-tools-nav ul.sm,
	#my-tools-nav .navbar{
		background: #fff;
		border: none;
	}
	#my-tools-nav .navbar-header{
		float: none;
	}

	#my-tools-nav .navbar-collapse{
		padding: 0;
	}

	#my-tools-nav .navbar-toggle{
		background: #f8f8f8;
	}

	#my-tools-nav .navbar-toggle .icon-bar{
		background: black;
	}

	#my-tools-nav.sidebar-nav button.btn-link:active,
		#my-tools-nav.sidebar-nav button.btn-link:focus,
		#my-tools-nav.sidebar-nav button.btn-link:hover {
		color: #888;
	}

	#opine {
		.welcome-area--new-folder{
		}
	}

}

