*,
*::before,
*::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Updated Design

body {
  background-color: $light !important;
  font-size: 1rem;
  font-weight: 400;
  color: #000 !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.5; }

h1, .h1 {
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  @media (max-width: 766px) {
    &.site-title{
      font-size: 1.9rem;
    }
  }
}

h2, .h2 {
  font-size: 2rem;
  letter-spacing: 0.05rem;
}

h3, .h3 {
  font-size: 1.75rem;
  font-weight: $font-weight-light !important;
  letter-spacing: 0.05rem;
}

h4, .h4 {
  font-size: 1.5rem;
  font-weight: $font-weight-light !important;
  letter-spacing: 0.05rem;
}

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

h1, h2, h3, h4, h5, h6 {
  &:last-child {
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(xs){

  h1,.h1{
    font-size: 2.5rem;
  }

  h2, .h2{
    font-size: 2rem;
  }

  h3, .h3{
    font-size: 1.5rem;
  }

  h4, .h4{
    font-size: 1rem;
  }

}

a {
  color: $primary;
  text-decoration: none;
  background-color: transparent;
  &:hover{
    text-decoration: none !important;
  }
  &.action{
    font-size: 1.4em;
  }
}

img{ object-fit: cover;}

.text{
  &-success{
    color: $success !important;
  }
  &-dark{
    color: $dark !important;
  }
  &-secondary{
    color: $secondary !important;
  }
  &-light{
    color: $light !important;
  }
  &-primary {
    color: $primary !important;
  }
}

a.text-light:hover{
  color: $light !important;
}

.badge-secondary {
  color: $dark;
  background-color: $secondary;
}

.hub-text {
  margin: 2rem 0;
}

.mce-container {
  p, label {
    font-size: 16px;
  }
}

.video-container .play-btn {
  position: absolute;
  height:80px;
  top: 40%;
  margin-top: -40px;
  left: 50%;
  margin-left: -40px;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }
}

.no-box-shadow {
  box-shadow: none !important;
}

.text-black {
  color: black;
}

#pv-content {
  .container-fluid {
    &:first-of-type {
      margin-top: 5rem;
    }
  }
  .container {
    padding-top: 2rem;
    padding-bottom: 2rem; }
}

.view-login{
  #pv-content{
    margin-top: 5rem;
  }
}

.btn {
  &-primary{
    border-color: $primary;
    background-color: $primary;
    color: #fff;
    &:hover{
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
  &-outline-primary{
    color: $primary;
    border-color: $primary;
    &:hover{
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
  &-outline-light:hover{
    border-color: $skyblue;
    color:$skyblue !important;
  }

  &-lg{
    font-size: $h3-font-size !important;
    font-weight: $font-weight-light !important;
    &.action{
      height: 2em;
      line-height: 1em;
    }
  }
  &-danger{
    background-color: $danger;
    border-color: $danger;
    &:hover{
      background-color : #f04369;
      border-color: #f04369;
      color: #fff !important;
    }
  }
  &-success{
    background-color: $success !important;
    border-color: $success !important;
  }
  &-block{
    width: 100%;
  }
  &-secondary{
    background-color: $secondary;
    border-color: $secondary;
    &:hover{
      background-color: #e76044;
      border-color : #e76044;
    }
  }
  &-link,&-link:focus{
    color: $primary;
  }
}

.navbar {
  .nav-header,
  .nav-link{
    font-size: $navbar-navlink-font-size;
    font-weight: $navbar-navlink-font-weight;
  }
}

.h-32px {
  height: 32px;
}

.h-48px {
  height: 48px;
}

.h-60px {
  height: 60px;
}

.h-64px {
  height: 64px;
}

.h-128px {
  height: 128px;
}

.h-256px {
  height: 256px;
}

.h-500px {
  height: 500px;
}

.h-700px {
  height: 700px;
}

@media (min-width: 767px) {
  .h-md-100{
    height: 100%;
  }
}



.w-128px {
  width: 128px;
}

.card-header {
  p {
    margin-bottom: 0;
  }
}

.pt-8 {
  padding-top: 8rem !important;
}

.mb-down{
  margin-bottom: 2rem !important;
}

.max-vh-100 {
  max-height: 100vh;
}

// On the Ideas page

.pv-container-header{
	
	h3{
		line-height: 1.5em !important;
	}
}

.pv-pagination{
	.paging{
    a,
    span{
			padding: 0.8rem;	
		}
	}
}

.pv-collab .no-rows {
  margin-bottom: 1rem;
}

@media (max-width: 767px) { // The point in which the menu passes from normal to "hamburger" menu in PH v2

  #pv-content {
    .container-fluid {
      &:first-of-type {
        margin-top: 0 !important;
      }
    }
  }

}

.hidden-mod{ // For modules which needs to be hidden by class

  display: none;

}

.normal-list{
  @extend .h4;
  margin-left: 1em;
}

.normal-list li{
  margin-bottom: 0.8em;
}


.bg-temp-img-1 {
  background: $img-bg-overlay-color, url("/images/patient-hub/placeholder1.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.bg-temp-img-2 {
  background: $img-bg-overlay-color, url("/images/patient-hub/placeholder7.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
