/* Styling for all modules in the template can be added here*/

// Moudle top position
.main-top .moduletable {
	width: 100%;

	.mod-challenge {
		padding: 3rem 0 0;

		.time-remaining {
			margin: 0 auto;
		}
		.countdown_row{
			margin: 0 auto;
		}
	}


}

.moduletable-rnd,
.module,
.moduletable {
	@extend .px-2;
	@extend .py-4;
	@extend .mb-3;

	&.moduletable-rnd,
	&.rounded-border {
		border: 2px solid $pv-light-blue;
		border-radius: 10px;

	}
}

div.moduletable{
	padding-bottom: 15px;
}

div.moduletable.rounded{
	border: 1px solid $pv-theme;
}

.moduletable-rnd,
div.rounded-border{
	border: 2px solid $pv-light-blue;
	border-radius: 10px;
}

div.icon{
	padding-right: 0;
	padding-left : 5px;
}

// CStream Module

div.mod_ccstream{

	ul#ticker{
		height: 400px;
		overflow: hidden;
		margin: 0;
		padding: 0;
		-webkit-box-shadow: 0 1px 3px rgba(0,0,0, .4);
		list-style: none;
	}

	ul#ticker li {
		padding: 20px;
		display: block;
		background: #EDF6FD;
		color: #333;
		border-bottom: 1px solid #ddd;
		border-radius: 10px;
		text-align: center;
		font-size: 13px;
	}
	.add-idea{
		color: var(--white);
		text-decoration: none;
	}

	.add-idea:hover{
		@extend .add-idea;
		border-bottom: 1px solid var(--white);
	}
}

// Stpries Module
div.mod-publivate-story {
	.stream-button {
		color: var(--white);
		text-decoration: none;
	}

	.mod-stories-author {
		color: var(--red);
	}
}


// Totem Ticker
ul.totemticker {
	height: 400px;
	overflow: hidden;
	margin: 0;
	padding: 0;
	-webkit-box-shadow: 0 1px 3px rgba(0,0,0, .4);
	list-style: none;

	li {
		padding: 20px;
		display: block;
		background: #EDF6FD;
		color: #333;
		border-bottom: 1px solid #ddd;
		border-radius: 10px;
		text-align: left;
		font-size: 13px;
	}
}



//Custom module chrome

.stream-title{
	text-align: center;
	padding: 10px 0 10px;
	color: var(--orange);
	font-weight: bold;
	font-size: 20px;
}

.stream-button{
	@extend .stream-title;
	border-top: 3px solid $pv-light-blue;
	margin-bottom: 0;
	background-color: $pv-light-blue;
}

// Challenges Module
div.mod-challenge{
	padding : 20px;

}
.mod-challenge .countdown_section .countdown_amount{
	color: $dark !important;
}

// Idea module

.normal-idea{

	.card-header{

		h5.text-secondary{
			visibility: hidden;
		}
	}
}