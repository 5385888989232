.stories-home-page{
	//margin-top: 60px; // Causing an issue of excess empty space in Patient-Hub home page
	h1{
		margin-bottom: 30px;
	}

	.card{
		height: 439px;
		position: relative;
		-webkit-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.1);
		-moz-box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.1);
		box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.1);
		margin-bottom: 30px;

		.card-img-top {
			height: 149px;
			background-size: cover;
			background-repeat: no-repeat;
            background-position: center;

			&:hover {
				opacity: 0.9;
			}
			img.thumbnail{
				height: 149px;
				width: 100%;
			}
			img.play-btn{
				position: absolute;
				height: 4vh;
				top: 15%;
				left: 45%;
				cursor: pointer;
			}
		}

		.card-body{
			padding: 10px !important;
		}
		.card-text {
			height: 150px;
			overflow: hidden;
		}

		.card-admin{
			font-size: 11px;
			min-height: 33px;

			a{
				color: $blue;
				font-weight: 600;
				&:hover{
					text-decoration: none;
					opacity: 0.8;
				}
			}
		}

		.card-title{
			font-size: 24px;
			//margin-bottom: 0px;
			margin-top: 5px;
			min-height: 60px;
			max-height: 65px;
			overflow: hidden;
			position: relative;
			color: #000;
			&:hover {
				color: $blue;
			}
		}

		.card-btn{
			display: block;
			position: absolute;
			width: 120px;
			height: 35px;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			border: 2px solid $blue;
			border-radius: 100px;
			cursor: pointer;
			text-align: center;
			line-height: 31px;
			color: $blue !important;
			font-size: 13px;

			@include transition;
			&:hover{
				text-decoration: none;
				background-color: $blue !important;
				color: #fff !important;
			}
		}
		.modal{
			z-index: 99999;
			.modal-body{
				.card-admin{
					margin-bottom: 10px;
					font-size: 14px;
				}
			}
			.modal-footer{
				.btn{
					color: #000;
					&:hover{
						color: $blue;
					}
				}
			}
		}
	}
	.story-btn{
		display:block;
		width: 200px;
		height: 50px;
		background-color: #4169A4;
		color: #fff;
		border-radius: 100px;
		text-align: center;
		line-height: 50px;
		margin-top:30px;
		margin: 0 auto;
		z-index: 999;
		@include transition;
		&:hover{
			color: #fff !important;
			text-decoration: none;
			opacity: .9;
		}
	}
}



/* Medium Devices, Desktops */
@include media-breakpoint-down(md) {
//@media only screen and (max-width : 992px){

}
/* Small Devices, Tablets */
//@media only screen and (max-width : 768px){
@include media-breakpoint-down(md) {

}

@media only screen and (max-width : 575px){
	.stories,
	.stories-home-page{
		margin-top: 40px;
		.card{
			height: 380px;
			
			.card-img-top{
				height: 200px;
			}

			img{
				&.thumbnail{
					height: 200px;
					width: auto;
					object-fit: cover;
				}
			}

			.card-body{
				text-align: center;
				p{
					display: none;
				}
			}
		}
	}


}

.employee-hub .stories-home-page .card .card-btn:hover{
	background-color: $pv-brown !important;
}

/* Extra Small Devices, Phones */
@include media-breakpoint-down(xs) {
//@media only screen and (max-width : 480px){
	.stories-home-page {
		.stories-homepage-card {
			.row:nth-child(2) {
				display: none;
			}
		}
	}

}
/* iPhone Retina */
@media only screen and (max-width : 320px){

}
