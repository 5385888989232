/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 20-Feb-2018, 9:31:24 AM
    Author     : James Hansen <jhansen@publivate.com>
*/

.hide {
	display : none !important;
}