/*
    Created on : 22-Feb-2018, 3:10:36 PM
    Author     : James Hansen <jhansen@publivate.com>
*/

// PV Help
.pv-help{
	@include media-breakpoint-down(sm) {
		width: 100%;
		a{
			float: right;
		}
		.pv-help-inner{
			font-size: 85%;
		}
	}
	&.pv-help-wide .pv-help-inner{
		width: 600px;
		@include media-breakpoint-down(sm) {
			min-width: 100%;
			width: 100% !important;
		}
	}
}

/** HELP **/
.pv-help{
	position: relative;
}

.pv-help.inline{
	display:inline-block;
	padding: 0 1em;
}

.no-touch .pv-help a:focus .pv-help-inner,
.no-touch .pv-help a:hover .pv-help-inner{
	display: block;
	width: inherit;
}

.pv-help-inner{
	background: none repeat scroll 0 0 rgb(0, 0, 0); /* Fallback for IE8 */
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.70);
	border-radius: 5px 5px 5px 5px;
	box-shadow: 0 0 8px #888888;
	color: #FFFFFF;
	display: none;
	min-width: 320px;
	opacity: 1;
	padding: 10px !important;
	position: absolute;
	text-align: left;
	text-shadow: 0 0px 1px #ffffff;
	width: inherit;
	z-index: 20;
}

.pv-help-inner *{
	color: #EEE;
}

.pv-help-inner ul{
	margin: 0;
	padding: 0 0 0 1em;
}

/* PLACE OTHER HELP ICON CONTENT POSITIONS HERE */
/** For Help Icons on the right side of the page **/
.pv-help.pull-right,
.pv-help.pv-help-right{
	float: right;
}
.pv-help.pull-right .pv-help-inner,
.pv-help.pv-help-right .pv-help-inner{
	right: 0;
}

/** For Help Icons stacked left **/
.pv-help.pv-help-left {
	float:left;
}

label .pv-help{
	display: inline-block;
}