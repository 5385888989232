/*footer{
	position: relative;
	height: auto;
	background-position: center;
	background-size: cover;
	z-index: 99;
	

}

!* Small Devices, Tablets *!
@media only screen and (max-width : 768px){
	footer{
		background-position: center;
		background-size: cover;
		height: 90px;
	}

}*/

footer .container{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-top: 1rem;
}
footer{

	position: relative;
	font-size: 1.3rem;
	background-color: $dark;
	padding: 3rem 0;

	color: #fff;
	&:before{
		display: block;
		position: absolute;
		top: 0;
		content: '';
		width: 100%;
		height: 1rem;
		//background-color: $green;
	}

	.nav {
		.nav-item {
			&:not(:last-child) {
				margin-right: 2rem;
			}
			.nav-link {
				color: #fff;
				padding: 0 !important;
				-webkit-transition: all 0.3s;
				-o-transition: all 0.3s;
				transition: all 0.3s;

				&:hover {
					color: $green;
				}
			}
		}
	}
}

// Large devices (desktops, 992px and up)
@media (max-width: 991px) {
	footer .container{
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	footer{
		width: 100%;
		height: auto;
		//text-align: center;
		-webkit-box-pack: center !important;
		-ms-flex-pack: center !important;
		justify-content: center !important;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 2rem 0;

		.copyright{
			margin-top: 1rem;
		}
	}
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
	footer {
		.nav {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			.nav-item {
				&:not(:last-child) {
					margin-right: 10px;
				}
			}
		}
	}
}

