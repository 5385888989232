.contributions-page {

	.contributions .ui-icon {
		display: inline-block;
	}

	.contributions-content {
		display: none;
		padding-left: 20px;
	}
}
