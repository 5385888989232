// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {}
// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
  .form-group {
    label {
      font-size: 16px;
    }

    input:not(.form-check-input) {
      font-size: 13px !important;
      height: 35px;
    }

    textarea {
      font-size: 13px;
    }

    .custom-select,
    .my-tagsinput {
      height: 35px !important;
      font-size: 13px !important;
    }

    .tag {
      margin-right: 5px;
    }

    .file-drop-zone-title {
      font-size: 16px;
    }

    .input-group {
      height: 35px !important;
      line-height: 35px;
    }

    .btn.btn-primary.btn-file {
      background-color: $blue !important;
      line-height: 35px;
      cursor: pointer !important;

      .hidden-xs {
        font-size: 13px !important;
        padding: 0;
        margin: 0;
        line-height: 35px;
        cursor: pointer !important;
      }
    }

    .button-group {
      margin-top: 10px;

      .primary-button {
        height: 45px;
        line-height: 45px;
      }
    }
  }
}
