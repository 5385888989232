/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 10-Apr-2018, 3:50:23 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
@import "stories/form";

#story-detail {
  @import "story/single-story";
  @import "story/single-story-media";
  #single-story{
    ol, ul {
      list-style: none;
    }
    blockquote, q {
      quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    @import "story/single-stories";
  }
}

/*Updated Design*/
.stories-inner{

  &.container-fluid{
    margin-top: 5rem;
    .container{
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .add-story{
    //margin-top: 1rem;
    //height: 2em;
    //line-height: 1.3;
    //font-weight: 500 !important;
  }
  .story-preview {
    position: absolute;
    bottom: 0; width: 100%;
    background-color: white !important;
    transition: all 0.5s ease;
    /*h3{
        min-height: 9vh;
    }*/
    a.story-title{
      color: inherit !important;
    }
    .story-description {
      max-height: 0;
      overflow: hidden;
      transition: 0.6s;
    }
  }

  .hover-float {
    /*display: flex;*/
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.2s !important;
    transition-property: all !important;
    transition-timing-function: ease-out;
  }
  .hover-float {
    &:hover, &:focus, &:active {
      transform: translateY(-2px);
      /*.story-preview h3{
          min-height: inherit;
      }*/
    }
  }

  .hover-primary {
    &:hover, &:focus, &:active {
      color: $primary !important;
    }
  }
}

/* "you may also like" pictures don't need to be 500px high */
#my-stories.smaller-pictures .story.card img.card-img-top {
  height: 256px;
}

.pv-stories-wrapper{
  .hero {
    text-align: center;
    margin: 2.5rem 0;
  }

  .shortby {
    a {
      color: #000000;
      margin-left: 30px;
      &.active{
        color: #4169A4;
      }

      &:hover {
        color: #4169A4;
        text-decoration: none;
      }
    }
  }
  .section-card {
    margin: 30px 0;
    .sorting-cont{
      margin-bottom: 1.2rem;
    }
    #my-stories{
      .row{
        margin-bottom: 0;

        .inner{
          margin-bottom: 1.5rem;
          .card{
            height: 100%;
            a.vimeo-popup img{
              @extend .card-img-top;
            }

            .card-button-group{
              position: relative;
              .left{
                position: absolute;
                left: 0;
                bottom: -20px;
              }
              .right{
                position: absolute;
                bottom: -11px;
                right: 0;
              }
            }
          }
        }
      }
    }



  }
  .video{
    position:relative;

    i{
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 60px;
      color: #fff;
    }
  }
  .card {
    border: none !important;
    border-radius: 4px !important;
    -webkit-box-shadow: 0 3px 20px 0 rgba(0,0,0,0.1);
    box-shadow: 0 3px 20px 0 rgba(0,0,0,0.1);

    a {
      .card-img-top {
        position: relative;
        height: 250px;
        -o-object-fit: cover;
        object-fit: cover;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;

        &:hover {
          opacity: 0.9;
        }
      }


      .card-title {
        color: #000;
        margin: 0 5px 5px 0;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        position: relative;
        min-height: 60px;
        max-height: 65px;
        overflow: hidden;

        &:hover {
          color: #4169A4;
        }
      }
    }


    .card-block {
      padding: 30px 20px;

      .card-info-top{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 53px;
      }

      .card-icons{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 12px;

        .icon:not(:last-child){
          margin-right: 10px;
        }

        .icon{
          display: inherit;
          img{
            margin-top: 0;
          }
          i:hover{
            color: #4169a4;
          }

          span{
            margin-left: 5px;
            margin-top: -3px;
          }

        }
      }

      .card-info {
        font-size: 12px;
        margin: 10px auto;

        .category {
          color: #4169A4;
        }

        .author {
          color: #000000;
          -webkit-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;

          &:hover {
            color: #4169A4;
          }
        }
      }

      .card-text{
        line-height: 20px;
        height: 80px;
        overflow: hidden;
      }

      .card-button-group{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
        margin-top: 30px;


        .right{
          padding: 0 1.2rem;
        }

        .edit-button {
          color: #000000;
          -webkit-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          margin-right: 10px;
          padding: 0 1.2rem ;

          &:hover{
            color: #4169A4;
          }
        }
        .story-abuse-link{
          color: #4169A4;
        }
      }
    }
  }

  @include media-breakpoint-down(sm){
    .hero {

      margin-top: 30px;
      margin-bottom: 30px;
    }
    .section-card {
      margin: 20px 0;
    }
    .shortby {
      a {margin-left: 20px;}
    }
    .card {
      a {
        .card-img-top {
          height: 180px;
        }

      }

      .card-block {
        padding: 20px 10px;

        .card-button-group{
          margin-top: 20px;
        }

      }


    }

    .b-button{
      width: 100%;
      margin: 1rem auto;
    }

    .left{
      bottom: -12px !important;;
    }
    .right{
      bottom: -3px !important;;
    }
  }

  @include media-breakpoint-down(lg){
    .row > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

}

.banner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  width: 100%;
  height: 125px;
  margin-top: 50px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
}

.banner-img {
  position: absolute;
  display: block;
  top: -20px;
  left: 10px;
}

.banner-text {
  max-width: 500px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  font-size: 16px;
  color: #fff;
  margin-right: 20px;
  margin-left: 150px;
}

.banner-button {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin-right: 20px;
  a {
    display: block;
    width: 180px;
    height: 50px;
    background-color: #fff;
    border-radius: 100px;
    text-align: center;
    line-height: 50px;
    color: #00529C;
    text-decoration: none;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    &:hover {
      -webkit-animation: pulse 1s infinite;
      animation: pulse 1s infinite;
      -webkit-transition: .2s;
      -o-transition: .2s;
      transition: .2s;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(.9);
    transform: scale(.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


.pv-stories-page {

  #pv-stories.card-columns {
    @include media-breakpoint-only(sm) {
      column-count: 2;
    }
    @include media-breakpoint-only(md) {
      column-count: 2;
    }
    @include media-breakpoint-only(lg) {
      column-count: 2;
    }
    @include media-breakpoint-only(xl) {
      column-count: 2;
    }
  }

  #add-story-form-btn {
    @extend .p-3;
  }

  #pv-stories {
    @extend .mt-3;
    .story {

    }
  }

  .sort-by-links {
    a.sort-by {
      display: inline-block;
      @extend .mx-1;

      &.active {
        text-decoration: underline;
        color: #4169a4;
      }
    }
  }
  .card-img-top.video-container {
    height: 166px;
    .vimeo-popup {
      display: block;
      height: 166px;
    }
  }
}

.story {
  header {
    @extend .mb-1;
  }

  &-tags {
    @extend .my-1;
  }

  .row {
    @extend .my-2;

    @include media-breakpoint-down(xs) {
      margin-bottom: 0 !important;
      section {
        margin-bottom: $spacer * .5;
        margin-top: $spacer * .5;
      }
    }

  }

  .card-title {
    small {
      display: block;
      @extend .my-1;
    }
  }
  .story-description {
    .inner {

    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.story-category {
  font-size: 50%;
}

/** Form 2 **/
.story-form2 {
  @extend .mb-2;

  .row {
    @extend .mb-3;
  }

  textarea {
    width: 100%;
  }

  .btn-toolbar {
    border: #0B55C4;
    position: absolute;
    top: 1rem;
    right: 2em;
    opacity: 0.7;
    z-index: $zindex-popover;

    .btn {
      color: #fff;
      background-color: #0b70cd;
      border: #0D47A1 inset 1px;
    }
    &:hover {
      opacity: 1;
    }
  }

  .editor {
    @extend .form-control;
  }

  .story-media-col {
    position: relative;
    @extend .mb-3;

    img.story-image-preview {
      width: 100%;
      height: 400px;
      position: relative;
    }

    .vimeo-embed {
      position: absolute;
      top: 0;
      z-index: 0;
    }

    iframe {
      //position: absolute;
      //width: 100% !important;
      //height: 400px;
    }

    iframe.vimeo {

    }
    .progress-container {
      padding: 0 1rem;
      position: absolute;
      top: 150px;
      width: 100%;
      display: none;
    }

  }
}


@include media-breakpoint-down(md){
  .banner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 350px;
  }

  .banner-img {
    position: absolute;
    display: block;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .banner-text {
    text-align: center;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 40px;
    margin-top: 150px;
  }

  .banner-button {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .layout-single{
    font-size: 70%;
  }
}

@include media-breakpoint-down(xs) {
  .banner-text {
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 20px;
    margin-top: 120px;
  }
  .banner-img{
    top: -43px;
  }
  .layout-single {
    font-size: 50%;
  }
}

@media screen and (max-width: 400px){
  .banner{
    height: auto;
    .banner-text{
      margin-top: 85px;
    }
  }
}