.modal-content {
    border: none !important;
}

#btn-close-modal {
    display: block;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    margin-top: 30px;
    cursor: pointer;

    .closebt {
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;
        width: 50px;
        height: 50px;

        &:hover {
            -webkit-transform: rotateZ(90deg);
            -ms-transform: rotate(90deg);
            transform: rotateZ(90deg);
        }
    }
}

.modal-heading {
    margin-top: 30px;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: $blue !important;
}

.pv-collab {

    &.modal-content {
        box-shadow: none !important;
        background-color: #fafdff;
        .card{
            background-color: transparent !important;
        }
    }
    /* Submit Anonymous */

    .span_pseudo, .anonymously_cb span:before, .anonymously_cb span:after {
        content: "";
        display: inline-block;
        background: #fff;
        width: 0;
        height: 0.2rem;
        position: absolute;
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
    }

    .anonymously_cb {
        //position: relative;
        height: 20px;
        //max-width: 180px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        input {
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
            border: 2px solid #414141;
            /*position: absolute;*/
            left: 0;
            /*-webkit-transition: all 0.2s;*/
            /*-o-transition: all 0.2s;*/
            /*transition: all 0.2s;*/
            z-index: 1;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;

            &:checked ~ span {
                background: $blue;
                border-color: $blue;
            }
            &:checked ~ span::before {
                width: 1rem;
                height: 0.15rem;
                -webkit-transition: width 0.1s;
                -o-transition: width 0.1s;
                transition: width 0.1s;
                -webkit-transition-delay: 0.3s;
                -o-transition-delay: 0.3s;
                transition-delay: 0.3s;
            }
            &:checked ~ span::after {
                width: 0.4rem;
                height: 0.15rem;
                -webkit-transition: width 0.1s;
                -o-transition: width 0.1s;
                transition: width 0.1s;
                -webkit-transition-delay: 0.2s;
                -o-transition-delay: 0.2s;
                transition-delay: 0.2s;
            }
            &:disabled ~ span {
                background: #ececec;
                border-color: $dark-gray;
            }
            &:disabled ~ label {
                color: #dcdcdc;
            }
            &:disabled ~ label:hover {
                cursor: default;
            }
        }
        .control-label {
            font-size: 16px;
        }
        label {
            padding-left: 0.5rem;
            position: relative;
            z-index: 2;
            //cursor: pointer;
            margin-bottom: 0;

        }
        span {
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
            border: 2px solid $dark-gray;
            position: absolute;
            left: 0;
            -webkit-transition: all 0.2s;
            -o-transition: all 0.2s;
            transition: all 0.2s;
            z-index: 1;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;

            &::before {
                -webkit-transform: rotate(-55deg);
                -ms-transform: rotate(-55deg);
                transform: rotate(-55deg);
                top: 1rem;
                left: 0.37rem;
            }
            &::after {
                -webkit-transform: rotate(35deg);
                -ms-transform: rotate(35deg);
                transform: rotate(35deg);
                bottom: 0.35rem;
                left: 0.2rem;
            }
        }
    }

    .form-group {
        label {
            color: $dark-gray !important;
            font-size: 20px;
            line-height: 1.5;
            span {
                color: $blue;
            }
        }
        input {
            width: 100%;
            height: 50px;
            border: 1px solid $light-gray;
            border-radius: 3px;
            color: $light-gray;
            font-weight: 400 !important;
            &::-webkit-input-placeholder {
                color: $light-gray;
            }
            &:-ms-input-placeholder {
                color: $light-gray;
            }
            &::-ms-input-placeholder {
                color: $light-gray;
            }
            &::placeholder {
                color: $light-gray;
            }
        }
        textarea {
            border: 1px solid $light-gray;
            border-radius: 3px;
        }
        .my-tagsinput {
            display: inline-block;
            margin: 0px;
            width: 100%;
            height: 50px;
            border: 1px solid $light-gray !important;
            border-radius: 3px !important;
            padding: 0px 20px;
            font-family: 'Source Sans Pro', sans-serif !important;
            overflow: hidden;
            input {
                border: none;
                font-size: 13px;
            }
            .tag {
                background-color: $blue;
                padding: 5px 15px;
                font-size: 13px !important;
            }
        }
    }

    p {
        /*margin-bottom: 0 !important;*/
        /*font-weight: 600;*/
        margin-top: 20px !important;
    }

    .custom-select {
        height: 50px !important;
        color: $light-gray !important;
        border: 1px solid $light-gray !important;
        border-radius: 3px !important;
    }

    .cb-up-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

    }

    .nav-button {
        margin-top: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .cancel {
            width: 80px;
            background-color: transparent;
            margin-left: 30px;
            -webkit-box-shadow: none;
            box-shadow: none;
            color: $dark-gray !important;
        }
        .draft {
            width: 100px;
            background-color: transparent;
            margin-left: 30px;
            -webkit-box-shadow: none;
            box-shadow: none;
            color: $dark-gray !important;
        }
    }

    input[type=file] {
        display: block !important;
        right: 1px;
        top: 1px;
        height: 40px;
        opacity: 0;
        width: 100%;
        background: none;
        position: absolute;
        overflow: hidden;
        z-index: 2;
    }

    .control-fileupload {
        display: block;
        background: #FFF;
        border-radius: 4px;
        width: 55%;
        height: 40px;
        overflow: hidden;
        position: relative;

        &:before, input, label {
            cursor: pointer !important;
        }
        /* File upload button */
        &:before {
            display: block;
            height: 40px;
            width: 125px;
            font-size: 16px;
            line-height: 40px !important;
            color: $blue;
            text-align: center;
            cursor: pointer;
            background-color: #fff;
            border: 1px solid $blue;
            border-radius: 3px;
            -webkit-transition: color 0.2s ease;
            -o-transition: color 0.2s ease;
            transition: color 0.2s ease;

            /* add more custom styles*/
            content: 'Browse';
            display: block;
            position: absolute;
            z-index: 1;
            top: 0px;
            right: 0px;
            line-height: 40px;
            text-align: center;
        }
        &:hover, &:focus {
            &:before {
                color: $blue;
                background-color: #fff;
                text-decoration: none;
                cursor: pointer;
            }
        }

        label {
            line-height: 40px;
            color: $dark-gray;
            width: 140px;
            font-size: 16px;
            font-weight: normal;
            white-space: nowrap;
            position: relative;
            z-index: 1;
            cursor: pointer;
        }
    }
}