.pagination-toolbar {
  display: flex;

  .limit {
    padding: $pagination-padding-y $pagination-padding-x;
  }

  .pagination {
    @extend .ml-3;
  }
}