.mod-ofg-challenge.hero-section {
    background: none !important;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.3);
    h1 {
        color: #000;
    }
}
.ofg {
    .card-block{
        height: initial;
        .card-button{
            position: static;    
        }
    }
}