 .pfac{

     .row:not(:last-child) {
         margin-bottom: 3rem !important;
     }


     .pfac-menus{
       h5{
         margin-bottom: 2.1rem;
         font-weight: 600;
       }
       div{
         padding :  5px 10px;
         -webkit-box-shadow: 0 0 5px rgba(63,63,63,.1);
         box-shadow: 0 0 5px rgba(63,63,63,.1);
         transition : all .3s ease;
         &:hover{
           transform: scale(1.03);
         }
       }
       @include media-breakpoint-down(md){
         margin-bottom: 1.2rem;
       }
     }

     .borders{
         padding: 5px 10px;
         -webkit-box-shadow: 0 0 5px rgba(63,63,63,.1);
         box-shadow: 0 0 5px rgba(63,63,63,.1);
         transition: all .3s ease;
     }

     .pfac-challenges{
         margin-top: 2.5rem;
         .challenge-item {
             padding: 3rem;
             height: 300px;
         }
     }

     .pfac-icons{
         max-height :  12vh;
         margin : 0 auto;
         display : block;
     }

     img.icon-image{
       max-height : 12vh;
     }

   .eg-ht{
     display: -webkit-box;
     display: -webkit-flex;
     display: -ms-flexbox;
   }

 }

.itemid-332{ // The PFAC home page

  .welcome-section{
    
    h1{

      line-height: 120% !important;
      font-size: 2em !important;
    
    }
  
  }

}

