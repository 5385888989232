form {
	.missing-fields {
		display: none;
		@extend .my-2;
	}

	&.invalid-form {
		.missing-fields {
			display: block;
		}
	}

	.placeholder {
		color: #777;
	}

	.form-group {

		.select2-container--default .select2-selection--multiple {
			.select2-selection__choice {
				background-color: #00529c;
				color: #fff;
				font-size: 13px !important;
				padding: 5px 15px 5px 10px;
				margin-right: 10px;
			}

			.select2-selection__choice__remove {
				color: #fff;
				margin-right: 5px;
			}
			.select2-search__field {
				border: none !important;
				color: #707070 !important;
				margin-top: 0px;
			}
		}
	}
}
