//@import "share/base";
.shr-pg .bitly:before, .shr-pg .blogger:before, .shr-pg .delicious:before, .shr-pg .digg:before, .shr-pg .diigo:before, .shr-pg .facebook:before, .shr-pg .feed:before, .shr-pg .gmail:before, .shr-pg .googleplus:before, .shr-pg .linkedin:before, .shr-pg .myspace:before, .shr-pg .pinterest:before, .shr-pg .reddit:before, .shr-pg .stumbleupon:before, .shr-pg .tumblr:before, .shr-pg .twitter:before, .shr-pg .yahoomail:before {
  background: url("../images/sprites_share.png") no-repeat; }

.shr-pg .bitly:before {
  background-position: 0 0; }

.shr-pg .blogger:before {
  background-position: 0 -32px; }

.shr-pg .delicious:before {
  background-position: 0 -64px; }

.shr-pg .digg:before {
  background-position: 0 -96px; }

.shr-pg .diigo:before {
  background-position: 0 -128px; }

.shr-pg .facebook:before {
  background-position: 0 -160px; }

.shr-pg .feed:before {
  background-position: 0 -192px; }

.shr-pg .gmail:before {
  background-position: 0 -224px; }

.shr-pg .googleplus:before {
  background-position: 0 -256px; }

.shr-pg .linkedin:before {
  background-position: 0 -288px; }

.shr-pg .myspace:before {
  background-position: 0 -320px; }

.shr-pg .pinterest:before {
  background-position: 0 -352px; }

.shr-pg .reddit:before {
  background-position: 0 -384px; }

.shr-pg .stumbleupon:before {
  background-position: 0 -416px; }

.shr-pg .tumblr:before {
  background-position: 0 -448px; }

.shr-pg .twitter:before {
  background-position: 0 -480px; }

.shr-pg .yahoomail:before {
  background-position: 0 -512px; }

.wb-share {
  display:inline-block;
}

.wb-inv {
  clip: rect(1px,1px,1px,1px);
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.shr-pg {
    .email{
        &::before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f0e0" !important;
          font-size: 32px;
          margin-right: .3em
        }
    }

    .shr-lnk {
      font-size: 115%;
      line-height: 32px;
      margin-bottom: 8px;
      min-height: 32px;
      text-align: left;
      text-decoration: none;
      width: 100%;
        &:before{
          content: " ";
          display: inline-block;
          height: 32px;
          margin-right: .6em;
          vertical-align: middle;
          width: 32px
        }
        .facebook:before {
          background-image: url(../../wet-boew/assets/sprites_share.png);
          background-position: -64px 0;
          width: 32px;
          height: 32px
        }
    }
}