//Variables
$img-overlay-color: linear-gradient(
                rgba(255, 255, 255, 0.6),
                rgba(255, 255, 255, 0.6)
);

$medium-text-bg-color: rgba(250, 253, 255, 0.95);


.inner{
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  .card-img-overlay {
    a{ font-size: initial;}
  }

  h1 {
    font-size: 4rem;
    font-family: 'Lato', sans-serif;
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
    //line-height: 1 !important;
  }

  h2 {
    font-size: 3rem;
    font-family: 'Lato', sans-serif;
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
    //line-height: 1 !important;
  }

  h3 {
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.1rem;
    //line-height: 1 !important;
  }

  ul ,
  ol {
    list-style: initial;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    li {
      text-align: left;
    }
  }

  ul li,
  ol li,
  p {
    font-family: 'Raleway', sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.1rem;
  }

  .smodule-maintitle {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $img-overlay-color, url('/images/stories/placeholders/tag_nurse_1.jpg');
    background-position: center;
    background-size: cover;
    //background-attachment: fixed;
    background-attachment: scroll;
    .smodule-content{
      padding-left: 2.2rem;
      padding-right: 2.2rem;
      z-index: 1;
      h1{
        color: #000;
      }
      h3.tags{
        a{
          font-weight: 400;
          margin-right: 10px;
          &:hover{
            color: $primary;
          }
        }
      }
    }
  }

  .smodule-small {
    display: flex;
    justify-content: center;
    padding: 100px 0 100px 0;
    text-align: center;
    background-image: none !important;

    + .smodule-small {
      padding-top: 0px;
    }

    .smodule-content {
      width: 600px;
      max-width: 80%;

      img {
        width: 100%;
        margin: 50px 0 50px 0;
      }
    }
  }

  .smodule-medium {
    display: flex;
    align-items: center;
    text-align: center;
    background-image: url('/images/stories/placeholders/tag_mychart_1.jpg');
    background-position: center;
    background-size: cover;
    //background-attachment: fixed;
    background-attachment: scroll;
    //height: 100vh;

    .smodule-content {
      width: 550px;
      max-width: 50%;
      margin: 6.7rem 50px 6.7rem auto;
      padding: 3.5rem;
      background-color: $medium-text-bg-color;
    }
  }

  .smodule-video{
    @extend .smodule-medium;
    background-image: none;
    .smodule-content{
      padding: 0 !important;
      margin: 0 auto !important;
      width: 100%;
      max-width: 100%;
      .poster-wrapper{
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        iframe{
          position: absolute;
          top:0;left: 0;
          width: 100%;height: 100%;
        }
        #poster-image{
          background-size: cover !important;
          bottom: 0;left: 0;right: 0;top: 0;
          opacity: 1.0;
          position: absolute;
          z-index: 10;
          height: 100%;width: 100%;
          transition: all 0.3s ease-in;
        }
        .play-btn{
          position: absolute;
          height:80px;
          top: 50%;
          margin-top: -40px;
          left: 50%;
          margin-left: -40px;
          cursor: pointer;
          opacity: 0.5;
          z-index: 10;
        }
        .play-btn:hover {
          opacity: 1;
        }
      }
    }
  }

  .smodule-large {
    display: flex;
    align-items: center;
    text-align: center;
    background-image: url('/images/stories/placeholders/tag_doctor_1.jpg');
    background-position: center;
    background-size: cover;
    //background-attachment: fixed;
    background-attachment: scroll;

    .smodule-content {
      width: 550px;
      max-width: 50%;
      margin-left: auto;
      margin-right: 50px;
      padding: 3.5rem;
      background-color: $medium-text-bg-color;
    }
  }

  .smodule-mbg-wrapper {
    z-index: -10;
    display: grid;
    grid-template-columns: 1fr;
    //grid-template-rows: fr;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .smodule-mbg {
      background-position: center;
      background-size: cover;
      //background-attachment: fixed;
      background-attachment: scroll;
    }

    .smodule-mbg + .smodule-mbg {
      background-position: center;
      background-size: cover;
      //background-attachment: fixed;
      background-attachment: scroll;
    }
  }

  .mirrored:not(.smodule-small) {

    > .smodule-content {
      margin-left: 40px;
      margin-right: auto;
    }
    /*!*--- Temporary change of bg, please ignore ---*!
    &.smodule-medium {
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
    }*/
    &.smodule-large {
      position: relative;
    }
  }


  @media screen and (max-width: 992px) {

    .smodule-content {
      max-width: 100% !important;
      margin: 65% 0 0 !important;
      width: 100% !important;
      padding: 2rem !important;


      p{
        font-size: 16px;
        line-height: 1.5;
      }
    }

    .smodule-maintitle {
      padding: 0 2rem;
      //align-items: flex-start;
      h1{
        font-size: 2.5rem;
      }
    }

    .smodule-small
    {
      padding: 0 2rem !important;

      .smodule-content{
        padding: 2rem 0 !important;
        margin: 0 !important;
      }

      &.mirrored .smodule-content{
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }

  @media screen and (max-width: 800px){
    .smodule-medium{
      background-repeat: no-repeat;
      background-size: contain;
      background-attachment: unset;
      background-position: unset;
    }
  }

  @include media-breakpoint-down(xs){
    .smodule-content{
      text-align: left;
    }
  }
}







