// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
    // GLOBAL

    .primary-button {
        width: 160px;
        height: 45px;
    }

    // SINGLE PAGE
    #btn-close-modal {
        margin-top: 20px;
    }
    .big-single-image {
        margin-top: 20px;
    }
    .side-bar {
        display: none;
    }

}

@media (width: 768px) {
    h1 {
        font-size: 34px !important;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
    // GLOBAL:
    h2 {
        font-size: 30px !important;
        line-height: 30px !important;
    }
    .view-detail {
        h3 {
            font-size: 20px !important;
        }
    }

    p {
        font-size: 16px !important;
        //color: $black !important;
        font-weight: 400 !important;
        line-height: 24px;
        letter-spacing: 0;
        margin: 0 !important;
    }
    /* MAIN PAGE */
    .hero-section {
        padding: 40px 0;
    }

    .hero-left .challenge-content h2{
        margin: 2.5rem 2.5rem 1rem;
    }

    .hero-right {
        display: none;
    }

    .big-button {
        display: block;
        width: 100% !important;
        margin-top: 30px;
    }
    .shortby-section {
        width: 100%;
        .sb-right {
            display: none;
        }
        .shortby-nav {
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
        }
    }
    .card-section .row:nth-of-type(2) {
        margin-top: 0px;
    }
    .card {
        margin-bottom: 20px;
    }
    // SINGLE PAGE
    .heading-bar {
        margin-top: 0;
    }
    .s-heading {
        margin-top: 10px;
    }

    .idea-detail .container {
        .card-body,.card-header{
            background-color: transparent !important;
        }
    }


}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
    .heading-bar {
        font-size: 12px;
        flex-wrap: wrap-reverse;
    }
    #ideas-page-body{
        h1 {
            font-size: 30px !important;
        }
        h2 {
            font-size: 28px !important;
            line-height: 32px !important;
        }
        h3 {
            font-size: 20px !important;
        }
        p {
            line-height: 24px !important;
        }
    }
    h1.challenge-title {
        font-size: 32px !important;
    }

    #btn-close-modal {
        width: 30px;
        height: 30px;
        .closebt {
            width: 30px;
            height: 30px;
        }
    }
    .button {
        width: 145px;
        height: 40px;
        line-height: 40px;
        font-weight: 400;
    }
    /* ADDIDEA */
    .form-group label {
        font-size: 16px;
    }
    .form-group input,
    .custom-select {
        height: 35px !important;
        font-size: 13px;
    }
    .form-group textarea {
        font-size: 13px;
    }
    .my-tagsinput {
        height: 35px !important;
        font-size: 13px;
        padding: 0px 10px;
        .tag {
            padding: 3px 10px !important;

        }
    }
    .cb-up-group {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .anonymously_cb {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;

    }
    .control-fileupload {
        width: 100%;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-bottom: 20px;
    }
    .nav-button {
        margin-top: 30px;
    }
    /* ADDIDEA */

    /* Single Page */

    .heading-bar {
        .theme-tag {
            font-size: 13px !important;
        }
        .author {
            font-size: 13px !important;
        }
        .date {
            font-size: 13px !important;
        }
    }
    .nav-single-page button {
        margin-bottom: 10px;
    }
    .comment-section {
        margin: 20px 0;
        h3 {
            font-size: 20px !important;
        }
        .mce-label {
            display: none;
        }
    }
    .comment-head {

        .author,
        .date,
        .comment-report {
            font-size: 13px;

        }
    }

    .comment-body {
        margin-left: 40px;
        p {
            font-size: 13px !important;
            line-height: 20px !important;
        }
        .comment-button {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            button {
                border: none;
                cursor: pointer;
                -webkit-transition: all .2s;
                -o-transition: all .2s;
                transition: all .2s;
                i {
                    color: $blue;
                    margin-right: 5px;
                }
                &:nth-child(2) {
                    margin-left: 20px;
                }
                &:focus {
                    outline: none;
                }
                &:hover {
                    opacity: .8;
                }
            }
        }

    }

    .comment-button {
        font-size: 14px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;

        .anonymously_cb {
            display: block;
            -webkit-box-ordinal-group: 1;
            -ms-flex-order: 0;
            order: 0;
            width: 100% !important;
            margin-bottom: 20px;
        }
        .submit {

            -webkit-box-ordinal-group: 2;

            -ms-flex-order: 1;

            order: 1;

        }
        .back {
            -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
            order: 3;
            margin-top: -30px;
        }
    }

    .challenge-content{
        font-size: 16px !important;
    }

    #add-idea h3{
        font-size: 1rem !important;
    }

}
