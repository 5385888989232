/*

 Fade element bottom, add an element to the bottom of the parent element ie: <div>sdfsdfs<div class="fade-bottom"></div></div>

 */
.fade-bottom{
    background-image: linear-gradient(
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.7) 100%
    );

    bottom: 0;
    left: 0;
    height: 1rem;
    text-align: center;
    margin: 0;
    padding: 30px 0;
    position: absolute;
    width: 100%;
}

.hover-float {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.2s !important;
    transition-property: all !important;
    transition-timing-function: ease-out;
}
.hover-float {
    &:hover, &:focus, &:active {
        transform: translateY(-2px);
    }
}
.hover-primary {
    &:hover, &:focus, &:active {
        color: $primary !important;
    }
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.bottom-100 {
    bottom: 100%;
}

.opacity0 {
    opacity: 0 !important;
}

.opacity20 {
    opacity: 0.2;
}

.opacity1 {
    opacity: 1 !important;
}

.to-top {
    position: fixed;
    bottom: 2rem;
    right: 0;
    background-color: black;
    opacity: 0.5;
    padding : 1.5rem 2rem;

    a span {
        color: white;
    }
}