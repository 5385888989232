.card-img-top {
  object-fit: cover;
}

.card-header {
  .h1, .h2, .h3, .h4, .h5, .h6 ,
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  p {
    margin-bottom: 0;
  }
  .col {
    .h3.action,
    h3.action{
      font-weight: 700 !important;
    }
  }
}

.card {
  overflow: hidden;
  border-radius: 5px !important;
  box-shadow: 2px 2px 10px rgba(203, 198, 195, 0.8);
  transition: .3s;

  margin-top: 1rem;
  margin-bottom: 1rem;

  .card {
    box-shadow: 0 0 0 rgba(203, 198, 195, 0);
  }

  .order-before {
    order: -1;
  }
}

.card:hover {
  box-shadow: 5px 5px 25px rgba(203, 198, 195, 0.5);

  .card {
    box-shadow: 0 0 0 rgba(203, 198, 195, 0);
  }

  .story-description {
    max-height: 800px;
  }
}

.card, .card-header, .card-body, .card-footer {
  background-color: #FFF !important;
  border-width: 0 !important;
  .embed-survey{
    width: 100%;
    padding: 0em;
    iframe{
      width: 100%;
    }
  }
}

.com_publivateideamodule.view-detail{ // The Ideas page
  .card-img-overlay{
    width: 11em !important;
    height: 5.5em !important;
  }
}