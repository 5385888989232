/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 24-Apr-2018, 1:45:06 PM
    Author     : James Hansen <jhansen@publivate.com>
*/
#idea-saved-success {

	#share-icons ul{
		display: inline-block;
		padding-left: 5px;
	}

	#share-icons ul li{
		display: inline-block;
	}

	#idea-saved-success{
		background-color:#f8f8f8;
		border-radius: 10px;
		padding: 10px 0;
	}

	span{
		font-size: 8em;
	}

	#share-icons a::before {
		content: " ";
		display: inline-block;
		height: 32px;
		margin-right: .6em;
		vertical-align: middle;
		width: 32px;
		background: url(/media/wet-boew/assets/sprites_share.png) no-repeat;
	}

	#share-icons a.facebook::before{
		background-position: 0 -160px !important;
	}

	#share-icons a.twitter::before{
		background-position: 0 -480px !important;
	}

	#share-icons a.linkedin::before{
		background-position: 0 -288px !important;
	}

	#share-icons a.email::before{
		content: "\2709";
		display: inline-block;
		font-family: "Glyphicons Halflings";
		font-size: 32px;
		margin-right: .3em;
		background: none;
		margin-top: -12px;
	}

	#success-msg{
		padding: 20px 0px;
		font-weight: 700;
		font-size: 20px;
	}

	#share-icons{
		font-weight: 600;
		padding: 20px 0;
	}

	.fa-check-circle{
		color: #43AF5D;
	}

	.close-pupup{
		float: right;
		padding-right: 20px;
	}

	.close-pupup button.idea-success-msg-close-btn{
		border: 1px solid #B9B9B9;
		border-radius: 5px;
		background: #FFFFFF;
		padding: 2px 10px;
		font-size: 15px;
	}

	#share-icons a{
		text-decoration:none!important;
	}

}