/*
    Created on : 12-Feb-2018, 10:49:26 AM
    Author     : James Hansen <jhansen@publivate.com>
*/

//@import 'themes/associations/main';
@import 'utilities';
@import 'global';
@import 'extern_css';
@import 'pagination';

@import 'forms';
@import 'mixins/mixins';
@import 'mixins/rem';
@import 'bootstrap3'; // Older bootstrap 3 items.
@import 'components/pvhelp';
@import 'components/countdown';
@import 'components/mydashboard';
@import 'components/scoreboard';
@import 'components/share_dialog';
@import '../../../layouts/publivate/story/scss/stories';
@import 'components/donation';
@import 'components/idea';
@import 'components/system_message';
@import 'components/share';
@import 'ideas';
@import 'components/ofg';
@import 'components/com_content/article';
@import 'accessibility';
@import 'header';
@import 'menu';
@import 'cards';
@import 'footer';
@import 'contactform';

/* Components and Pages */
@import 'module';
@import 'login_registration';
@import 'mytools';
@import 'video';

// Home page
@import 'home/home';
@import 'home/home-media';
@import 'home/improve-us';
@import 'home/services';
@import 'home/stories';
@import 'home/bottom_article';
@import 'home/employee';

// PFAC
@import 'pages/pfac';


.component.window body {
	@extend .p-3;
}

#pv-content, #pv-left, #pv-right {
}


/** Commponent Template component.php **/
.component body{
	/* background: $white; */
	background: #ddd;
}

.no-padding {
	@extend .p-0;
}

.ajax-response{
	display: none;
}

// modal popup element. used on the follow email automation configuration.
.white-popup {
	position: relative;
	background: #FFF;
	padding: 20px;
	width: auto;
	max-width: 500px;
	margin: 20px auto;
}


// Magnificpopover
.mfp-content .white-popup-block{
    background: #FFF;
    padding: 20px 30px;
    max-width: 80%;
	text-align: initial;
    margin: 40px auto;
    position: relative;

	@include media-breakpoint-down(md) {
		max-width: 90%;
	}

	@include media-breakpoint-down(sm) {
		max-width: 90%;
	}

	@include media-breakpoint-down(xs) {
		max-width: 100%;
	}

}
