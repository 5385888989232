@media (max-width: 1200px) {
}

@media (max-width: 991px) {

  .hero,.hero-video {
    .hero-nav {
      font-size: 13px;
      .content-left span {
        margin-right: 10px;
      }
    }
  }

  //.hero .hero-nav .content-left span {
  //	margin-right: 10px;
  //}

  .hero-nav {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .share-social {
    margin-left: 10px;

    a {
      i {
        font-size: 16px;
      }
    }
  }

  .wrapper {
    padding: 30px 0;
  }

  .carousel {
    margin-top: 30px;
  }

  .remove-offset {
    margin-left: 0 !important;
  }

  .story-content p {
    margin: 0 30px;
    font-size: 15px;
    line-height: 20px;
  }

  .nav-bottom {
    margin: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;

    .tags {
      font-size: 13px;
      width: 100%;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    .social-bottom {
      margin: 20px 0;
      width: 100%;
      font-size: 13px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      .share-social {
        margin: 0;

        a {
          i {
            font-size: 16px;
          }
        }
      }

      .like-button {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
      }
    }
  }

  .comment-section {
    margin: 20px 0;
  }
}

@media (max-width: 767px) {
  .hero .hero-nav, .hero-video .hero-nav {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 40px !important;
  }

  .hero-nav {
    .content-left {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 575px) {
  .hero {
    height: 100%;
    padding-bottom: 1.5rem;

    h1 {
      padding-top: 80px;
      margin: 0 15px;
      font-size: 30px !important;
      line-height: 30px !important;
    }

    span {
      font-size: 13px;
      display: contents;
    }

    .hero-nav {
      margin-top: 30px;
      .content-right{
        display: inline-flex;
        padding-top: 1.2rem;
      }
      .share-social{
        display: inline-block;
      }
    }
  }
  .comment-section{
    .comment-meta{
      .date{
        display: block;
        margin-left: 3.7rem;
      }
    }
    .comment-button-bottom {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;

      .submit {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-top: 20px;
      }
    }
  }
  .inner{
    margin-top: 3.5rem;
  }
}
