/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 1-May-2018, 10:45:30 AM
    Author     : James Hansen <jhansen@publivate.com>
*/

.scoreboard-row {
    border-bottom: 2px solid #EEE;
    height: 40px;
    line-height: 38px;
    width: 100%;
}