/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 15-Feb-2018, 10:10:18 AM
    Author     : James Hansen <jhansen@publivate.com>
*/

.cbLoginForm {
	line-height: 1;
	max-width: 400px;
	margin: 0 auto;

	.btn{
		width: 100%;
	}

	#form-login-remember input{
		width: auto;
		display: inline-block;
	}

	#form-login-remember label{
		float: left;
		margin-right: 1rem;
	}

	.cbLogoutButtonSpan{
		display: block;
	}

	ul.unstyled{
		list-style: none;
		margin: 0;
		padding: 0;
		height: 30px;
	}

	ul.unstyled li:first-child{
		margin-bottom: 5rem;
	}

	ul.unstyled a.btn{
		text-decoration: underline;
	}

	ul.unstyled a.btn:hover{
		text-decoration: none;
	}

	#form-login-forgot{
		float: left;
	}

	#form-login-register{
		float: right;
	}
}

.cbLoginForm .cbLoginForgotButtonSpan{
	margin-top: 0;
}

.cbLoginForm-mobile.cbLoginForm .cbLoginButtonSpan{
	display: inline-block !important;
	margin-left: 2rem;
}


/** LOGIN PAGE **/
.com_comprofiler #cb_cb_comp_login .h1{
	font-size: 2.3rem;
}

.com_comprofiler .cb_comp_inner{
	text-align: center;
}

.com_comprofiler .cbLoginForm{
	margin: 0 auto !important;
}

/* Cb Registration and Profile */
.cbIconsTop {
	text-align: center;
	width: 70%;
}

