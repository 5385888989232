.banner-wrapper{
  margin-left: -30px;
  margin-right: -30px;
  .hero {
    background-size: cover;
    background-position: center;
    background-color: #757575;

    padding: 0 3rem;

    height: 528px;
    width: 100%;

    h1 {
      padding-top: 300px;
      color: #fff;
      span {
        display: block;
        font-size: 16px;
      }
    }
    span, a {
      color: #fff;
      text-decoration: none;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
    }

    a:hover {
      color: #3E65A6;
    }

    .container {
      height: auto;
    }

    .hero-nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #fff;
      margin-top: 90px;
      width: 100%;

      .content-left {
        span {
          margin-right: 20px;
        }
      }
      .content-right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }

    }

    .clap-button {
      background: transparent;
      color: #fff;
    }
  }
}

.hero-video {
  background-size: cover;
  background-position: center;
  padding: 0 15px;
  width: 100%;

  h1 {
    color: #000;
    span {
      display: block;
      font-size: 16px;
    }
  }
  span, a {
    color: #000;
    text-decoration: none;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  }

  a:hover {
    color: #3E65A6;
  }

  .container {
    height: auto;
  }

  .poster-wrapper{
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
    iframe{
      position: absolute;
      top:0;left: 0;
      width: 100%;height: 100%;
    }
    #poster-image{
      background-size: cover !important;
      bottom: 0;left: 0;right: 0;top: 0;
      opacity: 1.0;
      position: absolute;
      z-index: 10;
      height: 100%;width: 100%;
      transition: all 0.3s ease-in;
    }
    .play-btn{
      position: absolute;
      height:80px;
      top: 50%;
      margin-top: -40px;
      left: 50%;
      margin-left: -40px;
      cursor: pointer;
      opacity: 0.5;
      z-index: 10;
    }
    .play-btn:hover {
      opacity: 1;
    }
  }

  .hero-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    margin-top: .5rem;
    width: 100%;

    .content-left {
      span {
        margin-right: 20px;
      }
    }
    .content-right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

  }

  .clap-button {
    background: transparent;
    color: #000;
  }
}

.img-1,
.img-2,
.img-3,
.img-4,
.img-5,
.img-6,
.img-7 {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}

.img-1 {
  max-height: 250px;
  margin: .3rem 0;
}

.img-2 {
  height: 530px;
}

.img-3 {
  height: 454px;
}

.img-4 {
  height: 454px;
}

.img-5 {
  margin: 2rem 0;
}

.img-6 {
}

.img-7 {
  margin: 2rem 0;
}

// LOng Story description

.image-cont{
  justify-content: center;

  .image-head{
    min-width: 0;
    margin: 5px;
  }
}
.content-1,
.content-2,
.content-3, .content-4, .content-5, .content-6 , .content-7{
  width: 100%;
}

.content-1 {
  #video{
    margin-top: 50px;
  }
}

.content-2 {
  margin: 50px 0;

}

.content-3 p {
  margin-top: 2rem;
}

.content-5 {
  margin: 2rem 0;
}

.clap-button {
  border: 1px solid #fff;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  padding: 7px 20px;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;

  i {
    margin-right: 5px;
  }
  span {
    margin-left: 10px;
    margin-right: 10px;
  }
  &:hover {
    opacity: .7;
  }
  &:hover i {
    color: red;
  }
}

.share-social {
  margin-left: 20px;
  a {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    i {
      margin-left: 10px;
      font-size: 18px;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
    }
    &:hover i {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
  #facebook {
    &:hover i {
      color: #3b5999;
    }
  }

  #twitter {
    &:hover i {
      color: #55acee;
    }
  }
  #google-plus {
    &:hover i {
      color: #dd4b39;
    }
  }
  #linkedin {
    &:hover i {
      color: #0077B5;
    }
  }
}

.story-content {
  padding: 0 0 50px;
  width: 100%;
}

.wrapper {
  background-color: #fff;
  padding: 50px 3%;
}



.nav-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 50px;
  float: right;

  .social-bottom {
    color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .like-button {
      border: 1px solid #000;
    }
    .share-social {
      a {
        color: #000;
      }
    }
  }
  .tags {
    a {
      color: #3E65A6;
      text-decoration: none;
      margin-right: 5px;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
      &:hover {
        opacity: .8;
      }
    }
  }
}

.comment-section {
  margin-top: 50px;
  h3 {
    font-size: 24px !important;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.comment {
  margin-bottom: 30px;
  clear: both;
}

.comment-head,.reply-head {
  display: flex;
  justify-content: space-between;
  .avatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .author {
    color: #3E65A6;
    font-weight: 600 !important;
    text-decoration: none;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    &:hover {
      opacity: .8;
    }
  }
  .date {
    margin-left: 10px;
  }
}

.comment-body {
  margin-left: 40px;
  p {
    margin-bottom: 10px !important;
    max-width: 500px;
    font-weight: 400;
    margin-top: 0 !important;
  }
}

.comment-button {
  button {
    border: none;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    margin-bottom: 1.2rem;
    i {
      color: #3E65A6;
      margin-right: 5px;
    }
    &:nth-child(2) {
      margin-left: 20px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      opacity: .8;
    }
  }
}

.comment-button-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1.2rem;

  .submit {
    margin-right: 40px;
    display: block;
    width: 180px;
    height: 50px;
    background-color: #3292d8;
    cursor: pointer;
    color: #fff;
    border-radius: 6px;

    -webkit-transition: all .2s;

    -o-transition: all .2s;

    transition: all .2s;
    &:hover {
      opacity: .8;
    }
  }
  .back {
    border: none;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    i {
      margin-right: 10px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      opacity: .8;
    }
  }
}

/* Submit Anonymous */
.span_pseudo, .anonymously_cb span:before, .anonymously_cb span:after {
  content: "";
  display: inline-block;
  background: #fff;
  width: 0;
  height: 0.2rem;
  position: absolute;
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.anonymously_cb {
  position: relative;
  height: 20px;
  max-width: 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}

.anonymously_cb input {
  display: none;
}

.anonymously_cb input:checked ~ span {
  background: #3E65A6;
  border-color: #3E65A6;
}

.anonymously_cb input:checked ~ span::before {
  width: 1rem;
  height: 0.15rem;
  -webkit-transition: width 0.1s;
  -o-transition: width 0.1s;
  transition: width 0.1s;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.anonymously_cb input:checked ~ span::after {
  width: 0.4rem;
  height: 0.15rem;
  -webkit-transition: width 0.1s;
  -o-transition: width 0.1s;
  transition: width 0.1s;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.anonymously_cb input:disabled ~ span {
  background: #ececec;
  border-color: #414141;
}

.anonymously_cb input:disabled ~ label {
  color: #dcdcdc;
}

.anonymously_cb input:disabled ~ label:hover {
  cursor: default;
}

.anonymously_cb label {
  padding-left: 2rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-bottom: 0;
}

.anonymously_cb span {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #414141;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 1;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.anonymously_cb span::before {
  -webkit-transform: rotate(-55deg);
  -ms-transform: rotate(-55deg);
  transform: rotate(-55deg);
  top: 1rem;
  left: 0.37rem;
}

.anonymously_cb span::after {
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
  bottom: 0.35rem;
  left: 0.2rem;
}

.form-control {
  border: 1px solid #808080;
  color: #808080;
  padding: 20px;
  margin-bottom: 20px;
}

