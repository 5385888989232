/*
    Created on : 20-Feb-2018, 1:49:36 PM
    Author     : James Hansen <jhansen@publivate.com>
*/

.mod-challenge-countdown {
	.countdown_row {
		@extend .no-gutters;
	}
}

.countdown_row{
	@include make-row();

	.countdown_section {
		margin-left: 0;
		margin-right: 0;
		padding: 0 0.25rem;
		font-size: 85%;
		font-family: $headings-font-family;
		font-weight: 700;
		line-height: 1.5;
		text-align: center;
		width: 33%;
	}

	.countdown_amount {
		border: 2px;
		display: inline-block;
		font-size: 200% !important;
		padding-top: 1rem;
		padding-bottom: 1rem;
		color: $primary;
		font-family: $headings-font-family;
		width: 100%;
		@extend .align-middle;

		@include media-breakpoint-down(sm) {
			border-width: 1px;
		}
	}
}
