.view-contact{
	body{
		background-color: $skyblue !important;
		@extend .text-light;
		.page-header{
			display: none;
		}
		.control-group:not(.field-spacer){
			@extend .form-group;
			@extend .col-sm-4;
			display: inline-grid;
			.controls,.control-label{
				display: inline-block;
			}
			.control-label{
				text-align: left;
			}
			&:last-of-type{
				display: block;
				margin: 1.25rem auto;
				.control-label{
					display: block;
				}
				@media (min-width: 1100px){
					&:last-of-type{
						.control-label{
							white-space: nowrap;
						}
					}
				}
			}
		}
		.contact-form{
			@include media-breakpoint-down(xs){
				.submit-form{
					width: 100% !important;
				}
			}
			img{
				max-width: 22vw;
				display: block;
				margin: 2.25rem auto;
			}
			span.fa{
				color: #fff;
			}
			#mycalendar-style-override-target {
				.control-group {
					max-width: 100%;
					.control-label {
						text-align: center;
					}
				}
			}
		}
	}
}