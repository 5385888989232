//// SINGLE PAGE
.pv-collab {
    .big-single-image {
        border-radius: 5px 5px 0px 0px;
        margin-top: 30px;
        width: 100%;
    }

    .s-heading {
        margin-top: 30px;
    }

    .s-paragraph {
        margin-top: 0 !important;
    }

    .heading-bar {
        margin-top: 5px;
        margin-bottom: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
        .theme-tag {
            color: $link-color !important;
            text-decoration: none;
            font-weight: 600;
            font-style: italic;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            font-size: 16px !important;
            margin-right: 20px;
            &:hover {
                opacity: .8;
            }
        }
        .author {
            color: $light-gray;
            font-style: italic;
            font-size: 14px !important;
            margin-right: 20px;
            .name {
                //color: $blue !important;
                text-decoration: none;
                -webkit-transition: all .3s;
                -o-transition: all .3s;
                transition: all .3s;
                &:hover {
                    opacity: .8;
                }
            }
        }
        .date {
            color: $light-gray;
            font-size: 14px !important;
            i {
                margin-right: 5px;
            }
        }
    }

    .rating-content {
        //display: none;
    }

    .rating {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 20px;
        p {
            margin-bottom: 0;
            margin-top: 0px !important;
        }
        .stars {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
            margin-left: 20px;
            margin-right: 20px;
            label {
                i {
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
        }
        .star-empty {
            color: $light-gray;
        }
        .star-checked {
            color: #feca57;
        }

        input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }
        input[type=radio] + label {
            cursor: pointer;
        }
        input[type=radio] + label i.star-empty,
        input[type=radio]:checked + label i.star-checked,
        input[type=radio]:checked ~ input[type=radio] + label i.star-checked {
            display: inline-block;
        }

        input[type=radio] + label i.star-checked,
        input[type=radio]:checked + label i.star-empty,
        input[type=radio]:checked ~ input[type=radio] + label i.star-empty {
            display: none;
        }
        label {
            margin-bottom: 0;
        }

        button {
            width: 60px;
            height: 20px;
            line-height: 20px;
            border: none;
            color: $light-gray;
            cursor: pointer;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            i {
                margin-right: 5px;
            }
            &:hover {
                color: $dark-gray;
            }
            &:focus {
                display: none;
            }
        }
    }

    .nav-single-page {
        margin-top: 20px;
        button, a, .btn {
            border: none;
            border-radius: 3px;
            background-color: $light-gray;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            font-weight: 400 !important;
            //line-height: 2.5rem;
            transition: all .2s;
            -webkit-transition: all .2s;
            -o-transition: all .2s;
            vertical-align: top;
            width: 95px;
            padding: 0 .75rem;
            margin-right: .5rem;
            margin-bottom: .5rem;

            &:nth-child(5) {
                background-color: transparent;
                color: $light-gray !important;
                border: 1px solid $light-gray !important;
                -webkit-transition: all .2s;
                -o-transition: all .2s;
                transition: all .2s;
            }
            &:hover {
                background: $blue;
                &:nth-child(5) {
                    color: #fff !important;
                    border: 1px solid $blue !important;
                }
            }
            &.active {
                background-color: $blue !important;
                color: #fff !important;
                box-shadow: none !important;
            }

        }

    }

    button.selected {
        background-color: $blue;

    }

    .evaluate-section {
        //display: none;
        margin-top: 20px;
        //height: 370px;
        p {
            color: $dark-gray !important;
            span {
                font-size: 20px;
                font-weight: 600;
            }
        }
        .evaluate-slide {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-top: 20px;

            .slide-box {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                width: 180px;
                height: auto;
                span {
                    margin-bottom: 20px;
                    color: $dark-gray;
                    font-weight: 600;

                }
            }
        }
    }

    .evaluate-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-top: 40px;
        .button {
            margin-right: 20px;
        }
        .evaluate-cancel {
            border: none;
            cursor: pointer;
            width: 80px;
            color: $dark-gray;
            &:focus {
                outline: none;
            }
            &:hover {
                opacity: .8;
            }
        }
    }

    //// comment-section

    .comment-section {
        margin: 40px 0px;
        width: 100%;

        h3 {
            margin-bottom: 10px;
            font-size: 24px !important;
            font-weight: 600;
            color: $dark-gray;
        }

        .form-group {
            margin-bottom: 30px;
        }

        .comment-button,
        .comment-body ,
        .form-edit-improvement{
            margin-left: 40px;
        }

        .comment-body {
            p {
                margin-bottom: 10px !important;
                max-width: 500px;
                font-weight: 400;
                margin-top: 0 !important;
            }

        }

        .btn:not(.btn-primary){
            background-color: transparent !important;
        }

        /*.form-check{
            .form-check-input{
                position: absolute;
                margin-top: 1.3rem !important;
                margin-left: -1.25rem;
            }
        }*/
    }


    .comment {
        &:not(:last-child) {
            margin-bottom: 30px;
        }

        .comment-button {
            button {
                border: none;
                cursor: pointer;
                -webkit-transition: all .2s;
                -o-transition: all .2s;
                transition: all .2s;
                background-color: transparent;
                i {
                    color: $blue;
                    margin-right: 5px;
                }
                &:nth-child(2) {
                    margin-left: 20px;
                }
                &:focus {
                    outline: none;
                }
                &:hover {
                    opacity: .8;
                }
            }
        }
    }

    .comment-head, .report-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .avatar {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
        .author {
            //color: $blue;
            font-weight: 600 !important;
            text-decoration: none;
            -webkit-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;
            &:hover {
                opacity: .8;
            }
        }
        .date {
            color: $light-gray;
            margin-left: 10px;
        }
        .comment-report, .reply-report {
            a {
                font-weight: 600;
                text-decoration: none;
                -webkit-transition: all .2s;
                -o-transition: all .2s;
                transition: all .2s;
                &:hover {
                    opacity: .8;
                }
            }
        }
    }

    .comment-replay {
        margin-top: 20px;
        p {
            max-width: 470px;
        }
    }

    .comment-button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .submit {
            margin-right: 40px;
        }
        .back {
            border: none;
            cursor: pointer;
            -webkit-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;
            i {
                margin-right: 10px;
            }
            &:focus {
                outline: none;
            }
            &:hover {
                opacity: .8;
            }
        }
    }

    /// side-bar

    .side-bar {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;

        .score {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-line-pack: center;
            align-content: center;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            margin-top: 1.5rem;
            p {
                text-transform: uppercase;
                margin-right: 10px !important;
            }
            .num {
                padding: 10px;
                font-size: 30px;
                background-color: $light-gray;
                color: #fff;
                text-align: center;
            }
        }
        .popular-tags,
        .popular-theme {
            width: 180px;
            .tags,
            .theme {
                a {
                    margin: 10px 5px 0px 0px;
                    display: inline-block;
                    text-decoration: none;
                    color: $blue;
                    padding: 5px 15px;
                    font-size: 13px;
                    border: 1px solid $blue;
                    border-radius: 3px;
                    -webkit-transition: all .2s;
                    -o-transition: all .2s;
                    transition: all .2s;
                    &:hover {
                        background-color: $blue;
                        color: #fff;
                    }
                }
            }
        }
        .primary-button {
            -webkit-box-shadow: none;
            box-shadow: none;
            text-decoration: none;
            &:hover {
                color: #fff;
            }
        }
    }

    .popular-tags {
        margin-top: 30px;
    }

    .popular-theme {
        margin: 30px 0;

    }

    /// SHARE LINKS
    #links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 10px;
        height: 50px;
        width: 170px;
        margin-top: 5px;
        border-radius: 3px;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);

        a {
            display: block;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            -webkit-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;
            border-radius: 3px;
            i {
                color: #fff;
            }
            &:hover {
                opacity: .8;
            }
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        .fb {
            background-color: #3B5998;
            margin-right: 10px;
        }
        .tw {
            background-color: #15B6E6;
            margin-right: 10px;
        }
        .in {
            background-color: #1592E6;
            margin-right: 10px;
        }
        .email {
            background-color: #F2CC1A;
        }
    }

    #links.hidden {
        display: none;
        overflow: hidden;
        width: 0%;
    }
}
.pv-collab{

}